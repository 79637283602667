import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";

// file imports
import Profilepage from "./Profilepage.jsx";
import Errorpage from "../Pages/errorPage";
import LoadingScreenPage from "../LoadingScreenPage.jsx";
// Queries and mutations
import EmployeeprofileQuery from "../../graphql/employeeprofileQuery.js";

//Retrieve all employee profile details into our application
const EmployeeProfile = ({ username = localStorage.getItem("username") }) => (
  <Query query={EmployeeprofileQuery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        // loading until getting response from the backend
        return <LoadingScreenPage />;
      }
      // if any error occur in backend it will redirect to error page
      if (error) {
        return (
          <div>
            <Errorpage />
          </div>
        );
      }
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        //redirecting to profile page
        <div>
          <Profilepage user={data.getEmployee} />
        </div>
      );
    }}
  </Query>
);

class ProfileList extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <EmployeeProfile history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default ProfileList;
