import { ApolloConsumer } from "react-apollo";
import React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";

// File imports
import DataNotFound from "../DataNotFound";
import Errorpage from "../Pages/errorPage";
import History from "./History.jsx";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

// queries and mutation
import HistoryEmployeeQuery from "../../graphql/employeeQuery_history";

// history query
const Historyquery = ({ username = localStorage.getItem("username") }) => (
  <Query query={HistoryEmployeeQuery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        // loading until getting response from the backend
        return <LoadingScreenPage />;
      }
      // if any error occur in backend it will redirect to error page
      if (error) {
        return (
          <div>
            <Errorpage />
          </div>
        );
      }

      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        // if no document is approved by authority it will redirect to data not found page
        <div>
          {data.getEmployeeHistory.history === null ? (
            <DataNotFound
              title={"No History Found"}
              message={
                "You will get this data if You Request your Employee to share their Employment Data with you"
              }
              button={true}
              link={"/document"}
              buttontext={"Check your Document"}
            />
          ) : (
            // it will redirect to history page to display approved documents
            <History
              key={data.getEmployeeHistory.employee_username}
              user={data.getEmployeeHistory}
            />
          )}
        </div>
      );
    }}
  </Query>
);

class HistoryList extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <Historyquery history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default HistoryList;
