import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import badgeStyle from "assets/jss/material-dashboard-pro-react/components/badgeStyle.jsx";

function Badge({ ...props }) {
	const { classes, color, children, tooltip, tooltipContent } = props;
	return tooltip ? (
		<Tooltip
			TransitionComponent={Fade}
			TransitionProps={{ timeout: 600 }}
			title={tooltipContent}
			placement="bottom"
		>
			<span className={classes.badge + " " + classes[color]}>{children}</span>
		</Tooltip>
	) : (
		<span className={classes.badge + " " + classes[color]}>{children}</span>
	);
}

Badge.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		"primary",
		"warning",
		"danger",
		"success",
		"info",
		"rose",
		"gray"
	])
};

export default withStyles(badgeStyle)(Badge);
