import React from "react";
import { connect } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Link from "@material-ui/icons/Link";
import Home from "@material-ui/icons/Home";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//custom components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
//styles
import "./phonestyle.css";
//custom style
import step1style from "./step1style.jsx";
//validations
import validation from "../../Validation/ProfileValidation.jsx";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactinfo: {
        employee_phonenumber: "",
        employee_mailId: "",
        employee_address: ""
      },
      employee_phonenumberState: "",
      employee_mailIdState: "",
      employee_addressState: "",
      employee_addressErrMsg: "",
      employee_mailIdErrMsg: ""
    };
  }
  sendState() {
    return this.state;
  }
  // onchange function for all input fields
  register_step2_change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email_register":
        var email_register_validation = validation.verifyEmail(
          event.target.value.trim(),
          stateName
        );
        if (!email_register_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: email_register_validation[stateName]
          });
        }
        break;
      case "address_register":
        var address_register_validation = validation.verifyLength(
          event.target.value.trim(),
          stateName
        );
        if (!address_register_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: address_register_validation[stateName]
          });
        }
        break;
      default:
        break;
    }
    this.setState({
      contactinfo: {
        ...this.state.contactinfo,
        [stateName]: event.target.value
      }
    });
  }
  //phone number onchange function
  handlephonenumber_register(employee_phonenumber) {
    try {
      this.setState({
        contactinfo: {
          ...this.state.contactinfo,
          employee_phonenumber: employee_phonenumber
        }
      });
      if (isValidPhoneNumber(employee_phonenumber)) {
        this.setState({ employee_phonenumberState: undefined });
      } else {
        this.setState({
          employee_phonenumberState: "Invalid phone number"
        });
      }
    } catch (err) {
      this.setState({
        employee_phonenumberState: "Enter valid phone number"
      });
    }
  }
  // enabling the next button
  isValidated() {
    if (
      this.state.employee_phonenumberState === undefined &&
      this.state.employee_mailIdState === "success" &&
      this.state.employee_addressState === "success"
    ) {
      this.props.getContactdata(this.state.contactinfo);
      return true;
    } else {
      const { contactinfo } = this.state;

      if (contactinfo.employee_phonenumber === "") {
        this.setState({
          employee_phonenumberState: "Phone number is required"
        });
      }
      if (contactinfo.employee_mailId === "") {
        this.setState({
          employee_mailIdState: "error",
          employee_mailIdErrMsg: "Email is required"
        });
      }

      if (contactinfo.employee_address === "") {
        this.setState({
          employee_addressState: "error",
          employee_addressErrMsg: "Address is required"
        });
      }
      return false;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} lg={10}>
            <h4 className={classes.infoText}>Enter your contact details</h4>
          </GridItem>
          <GridItem xs={12} sm={5} lg={5}>
            <InfoArea
              title="About Authority"
              description="We've created the marketing campaign of the website. It was a very interesting collaboration."
              icon={Link}
              iconColor="info"
            />
            <InfoArea
              title="Requesting for a pin"
              description="We've created the marketing campaign of the website. It was a very interesting collaboration."
              icon={Link}
              iconColor="info"
            />
          </GridItem>
          <GridItem xs={12} sm={5} lg={5}>
            <CustomInput
              success={this.state.employee_addressState === "success"}
              error={this.state.employee_addressState === "error"}
              id="Register_employee_address"
              name="Register_employee_address"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employee_addressState === "error"
                  ? this.state.employee_addressErrMsg
                  : null
              }
              inputProps={{
                multiline: true,
                rows: 1,
                placeholder: "Address*",
                onChange: event =>
                  this.register_step2_change(
                    event,
                    "employee_address",
                    "address_register",
                    5
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <Home className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              success={this.state.employee_mailIdState === "success"}
              error={this.state.employee_mailIdState === "error"}
              id="Register_employee_mailid"
              name="Register_employee_mailid"
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employee_mailIdState === "error"
                  ? this.state.employee_mailIdErrMsg
                  : null
              }
              inputProps={{
                placeholder: "Email*",
                onChange: event =>
                  this.register_step2_change(
                    event,
                    "employee_mailId",
                    "email_register"
                  ),
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <br />
            <br />
            <PhoneInput
              country="IN"
              placeholder="Phone Number*"
              name="Register_employee_contactnumber"
              value={this.state.employee_phonenumber}
              onChange={employee_phonenumber =>
                this.handlephonenumber_register(employee_phonenumber)
              }
              error={this.state.employee_phonenumberState}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

//connect this component to the store
const mapStateToProps = state => {
  return {
    employee: state.employee
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getContactdata: contactinfo => {
      dispatch({
        type: "GET_CONTACT_DATA",
        payload: contactinfo
      });
    }
  };
};
const withstylecontact = withStyles(step1style)(Step2);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withstylecontact);
