import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import PhonelinkLock from "@material-ui/icons/PhonelinkLock";
import Lock from "@material-ui/icons/Lock";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Hidden from "@material-ui/core/Hidden";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoaderButton from "../../Custom-component/LoaderButton";
//images
import Robot from "assets/img/robot.png";
// custom components
import CustomInput from "../../Custom-component/CustomInput/CustomInput.jsx";
// file imports
import config from "../../config";
import WarningAlert from "../WarningAlert.jsx";
// styles
import loginPageStyle from "./loginPageStyle.jsx";
// validations
import validation from "../Validation/ProfileValidation.jsx";
// for hasing the values
var sha256 = require("js-sha256");

class ForgetpasswordPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton_forgot: "cardHidden",
      showme: true,
      alert: null,
      forgetpassword_username: "",
      forgetpassword_usernameState: "",
      forgetpassword_newotp: "",
      forgetpassword_newotpState: "",
      forgot_passwordState: "",
      forgot_password: "",
      forgot_passwordConfirm: "",
      forgot_passwordConfirmState: "",
      value: "60",
      buttonenable: true,
      hidepassword_confirm: false,
      hidepassword: false,
      checkpassword: "",
      checkpassword1: "",
      forgetpassword_usernameErrMsg: "",
      forgetpassword_newotpErrMsg: "",
      confirmPasswordErrorMessage: "",
      forgot_passwordErrMsg: "",
      errorMessage_forgetpassword: "",
      isLoading: false,
      openWarning_forgetpassword: false
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction_forgot = setTimeout(
      function() {
        this.setState({ cardAnimaton_forgot: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction_forgot);
    this.timeOutFunction_forgot = null;
  }
  // compare password and confirm password fields
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // onchange function for all input fields
  forget_change(event, stateName, type) {
    switch (type) {
      case "username_forgetpassword":
        var username_forgot_validation = validation.verifyUsername(
          event.target.value.trim(),
          stateName
        );
        if (!username_forgot_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: username_forgot_validation[stateName]
          });
        }
        break;
      case "otp_forgot":
        var otp_forgot_validation = validation.verify_otp(
          event.target.value,
          stateName
        );
        if (!otp_forgot_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: otp_forgot_validation[stateName]
          });
        }
        break;
      case "password_forget":
        if (event.target.value) {
          var forgetpassword_validation = validation.password_validate_compare(
            event.target.value,
            stateName,
            this.state.checkpassword
          );
          if (forgetpassword_validation === "error") {
            this.setState({
              [stateName + "State"]: "error",
              [stateName +
              "ErrMsg"]: "Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8"
            });
          } else {
            if (forgetpassword_validation === "success_compare") {
              this.setState({
                [stateName + "ConfirmState"]: "success"
              });
            } else if (forgetpassword_validation === "error_compare") {
              this.setState({
                [stateName + "ConfirmState"]: "error"
              });
            }
            this.setState({
              [stateName + "State"]: "success",
              checkpassword1: event.target.value
            });
          }
        } else {
          this.setState({ [stateName + "ErrMsg"]: "Password is required" });
        }
        break;
      case "equalTo_forgot_password":
        if (
          validation.compare_password(
            event.target.value,
            this.state.checkpassword1,
            stateName
          )
        ) {
          this.setState({
            [stateName + "State"]: "success",
            checkpassword: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            checkpassword: event.target.value
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  // enable a button when username is valid
  buttonenable() {
    if (this.state.forgetpassword_usernameState === "success") {
      return false;
    } else {
      if (this.state.forgetpassword_usernameState !== "success") {
        return true;
      }
    }
  }
  // enable button when otp & password is valid
  verifybutton() {
    if (
      this.state.forgetpassword_newotpState === "success" &&
      this.state.forgot_passwordState === "success" &&
      this.state.forgot_passwordConfirmState === "success"
    ) {
      return false;
    } else {
      return true;
    }
  }
  // function to get otp
  sendotp = async event => {
    event.preventDefault();
    if (!this.buttonenable()) {
      this.setState({ isLoading: true, openWarning_forgetpassword: false });
      const payload = {
        username: this.state.forgetpassword_username
      };
      var headers = {
        "Content-Type": "application/json"
      };
      try {
        await axios
          .post(
            config.cognito.forgetpassword_url,
            payload,
            { crossdomain: true },
            { headers: headers }
          )
          .then(response => {
            if (response.data.data === null) {
              if (
                response.data.errors.error_code === "GBECO1002" ||
                response.data.errors.error_code === "GBECO1011"
              ) {
                this.setState({
                  isLoading: false,
                  forgetpassword_usernameState: "error",
                  forgetpassword_usernameErrMsg:
                    "Username does not exist. Please provide valid username"
                });
              } else if (response.data.errors.error_code === "GBECO1013") {
                this.setState({
                  isLoading: false,
                  errorMessage_forgetpassword:
                    "Unable to send OTP. Maximum limit exceed. Please try after some time",
                  openWarning_forgetpassword: true
                });
              } else {
                this.setState({
                  isLoading: false,
                  errorMessage_forgetpassword:
                    response.data.errors.error_message,
                  openWarning_forgetpassword: true
                });
              }
            } else {
              this.setState({
                isLoading: false,
                value: 60
              });
              this.OtpAlert_forget();
              this._increase_forget();
            }
          })
          .catch(err => {
            this.setState({
              isLoading: false,
              errorMessage_forgetpassword:
                "We experiencing difficulties with connectivity of the application, Please try again later",
              openWarning_forgetpassword: true
            });
          });
      } catch (e) {
        this.setState({
          isLoading: false,
          errorMessage_forgetpassword:
            "We experiencing difficulties with connectivity of the application, Please try again later",
          openWarning_forgetpassword: true
        });
      }
    } else {
      if (this.state.forgetpassword_username === "") {
        this.setState({
          forgetpassword_usernameState: "error",
          forgetpassword_usernameErrMsg: "Username is required"
        });
      }
    }
  };
  //function to reset password
  resetpassword = async event => {
    event.preventDefault();
    if (!this.verifybutton()) {
      this.setState({ isLoading1: true, openWarning_forgetpassword: false });
      const resetpassword_payload = {
        username: this.state.forgetpassword_username,
        password: sha256(this.state.forgot_password),
        code: this.state.forgetpassword_newotp
      };
      var headers = {
        "Content-Type": "application/json"
      };
      try {
        await axios
          .post(
            config.cognito.confirmForgotPassword_url,
            resetpassword_payload,
            { crossdomain: true },
            { headers: headers }
          )
          .then(response => {
            if (response.data.data === null) {
              if (response.data.errors.error_code === "GBECO1011") {
                this.setState({
                  isLoading1: false,
                  errorMessage_forgetpassword:
                    "User name not exists.Please provide valid username",
                  openWarning_forgetpassword: true
                });
              } else if (response.data.errors.error_code === "GBECO1012") {
                this.setState({
                  isLoading1: false,
                  errorMessage_forgetpassword:
                    "Your OTP gets expired. Please resent your OTP",
                  openWarning_forgetpassword: true
                });
              } else if (response.data.errors.error_code === "GBECO1013") {
                this.setState({
                  isLoading1: false,
                  errorMessage_forgetpassword:
                    "Unable to change your password. Maximum limit exceed. Please try after some time",
                  openWarning_forgetpassword: true
                });
              } else {
                this.setState({
                  isLoading1: false,
                  errorMessage_forgetpassword:
                    response.data.errors.error_message,
                  openWarning_forgetpassword: true
                });
              }
            } else {
              this.setState({ isLoading1: false });
              this.autoCloseAlert_forget();
            }
          })
          .catch(err => {
            this.setState({
              isLoading1: false,
              errorMessage_forgetpassword:
                "We experiencing difficulties with connectivity of the application, Please try again later",
              openWarning_forgetpassword: true
            });
          });
      } catch (e) {
        this.setState({
          isLoading1: false,
          errorMessage_forgetpassword:
            "We experiencing difficulties with connectivity of the application, Please try again later",
          openWarning_forgetpassword: true
        });
      }
    } else {
      if (this.state.forgetpassword_newotp === "") {
        this.setState({
          forgetpassword_newotpState: "error",
          forgetpassword_newotpErrMsg: "OTP is required"
        });
      }
      if (this.state.forgot_password === "") {
        this.setState({
          forgot_passwordState: "error",
          forgot_passwordErrMsg: "Password is required"
        });
      }
      if (this.state.forgot_passwordConfirm === "") {
        this.setState({
          forgot_passwordConfirmState: "error",
          confirmPasswordErrorMessage: "Confirm password is required"
        });
      }
    }
  };
  // success alert for reset password
  autoCloseAlert_forget = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px", color: "#000000" }}
          title="Password reset successfully!"
          onConfirm={() => this.successhideAlert_forget()}
          showConfirm={false}
        >
          <h4>Your password has been reset</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert_forget, 2000);
  };
  // success alert for OTP send to your email
  OtpAlert_forget = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="OTP sent!"
          onConfirm={() => this.successhideAlert_forget()}
          showConfirm={false}
        >
          Check your email to get OTP.
        </SweetAlert>
      )
    });
    setTimeout(this.otphidealert_forget, 2000);
  };
  // close success alert
  successhideAlert_forget = () => {
    this.setState({
      alert: null
    });
    this.props.history.push("/auth/login");
  };
  //close otp success alert
  otphidealert_forget = () => {
    this.setState({
      alert: null,
      showme: false
    });
  };
  // hide/show password
  hidepassword_forget = () => {
    this.setState({ hidepassword: !this.state.hidepassword });
  };
  // hide/show confirm password
  hidepassword_confirm_forget = () => {
    this.setState({ hidepassword_confirm: !this.state.hidepassword_confirm });
  };
  // function to decrease value from 60 to 0
  _increase_forget = () => {
    if (this.state.value > 0) {
      this.setState({
        value: this.state.value - 1
      });
      if (this.state.value !== 0) {
        setTimeout(this._increase_forget.bind(this), 1000);
        return true;
      } else {
        clearInterval(this.interval);
        return false;
      }
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="space-between" className={classes.alignImage}>
          <Hidden smDown>
            <GridItem xs={12} sm={6} md={5} only={["md", "xl"]}>
              <img src={Robot} alt="..." className="img" />
            </GridItem>
          </Hidden>
          <GridItem xs={12} sm={6} md={5}>
            {this.state.showme ? (
              <div>
                <form>
                  <Card
                    login
                    className={classes[this.state.cardAnimaton_forgot]}
                  >
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                    >
                      <div className={classes.socialLine}>
                        <p
                          style={{ fontSize: "1.3em" }}
                          className={classes.cardTitle}
                        >
                          Forget Password
                        </p>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        success={
                          this.state.forgetpassword_usernameState === "success"
                        }
                        error={
                          this.state.forgetpassword_usernameState === "error"
                        }
                        id="forgetpassword_username"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        helpText={
                          this.state.forgetpassword_usernameState === "error"
                            ? this.state.forgetpassword_usernameErrMsg
                            : null
                        }
                        inputProps={{
                          onChange: event =>
                            this.forget_change(
                              event,
                              "forgetpassword_username",
                              "username_forgetpassword",
                              3
                            ),
                          type: "text",
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Person className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: "Username*"
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.textCenter}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                          <LoaderButton
                            id="forgetpassword_button"
                            disabled={this.buttonenable()}
                            onClick={this.sendotp}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Submit"
                            loadingText="Submitting.."
                          />
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  </Card>
                </form>
              </div>
            ) : (
              <div>
                <form>
                  <Card
                    login
                    className={classes[this.state.cardAnimaton_forgot]}
                  >
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                    >
                      <h4 className={classes.cardTitle}> Forget Password</h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                          <CustomInput
                            success={
                              this.state.forgetpassword_newotpState ===
                              "success"
                            }
                            error={
                              this.state.forgetpassword_newotpState === "error"
                            }
                            id="newotp"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            helpText={
                              this.state.forgetpassword_newotpState === "error"
                                ? this.state.forgetpassword_newotpErrMsg
                                : null
                            }
                            inputProps={{
                              onChange: event =>
                                this.forget_change(
                                  event,
                                  "forgetpassword_newotp",
                                  "otp_forgot",
                                  "length",
                                  6
                                ),
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <PhonelinkLock
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "Enter your OTP code*"
                            }}
                          />
                          <CustomInput
                            success={
                              this.state.forgot_passwordState === "success"
                            }
                            error={this.state.forgot_passwordState === "error"}
                            id="Register_employee_account_password"
                            formControlProps={{
                              fullWidth: true
                            }}
                            helpText={
                              this.state.forgot_passwordState === "error"
                                ? this.state.forgot_passwordErrMsg
                                : null
                            }
                            inputProps={{
                              placeholder: "New Password*",
                              onChange: event =>
                                this.forget_change(
                                  event,
                                  "forgot_password",
                                  "password_forget"
                                ),
                              type: this.state.hidepassword
                                ? "text"
                                : "password",
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Lock
                                    className={classes.inputAdornmentIcon}
                                    style={{ color: "#ffc90d" }}
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment
                                  style={{ marginRight: "-5px" }}
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  {this.state.hidepassword ? (
                                    <Visibility
                                      className={classes.inputAdornmentIcon}
                                      style={{
                                        color: "00acc1",
                                        cursor: "pointer"
                                      }}
                                      onClick={this.hidepassword_forget}
                                    />
                                  ) : (
                                    <VisibilityOff
                                      className={classes.inputAdornmentIcon}
                                      style={{
                                        color: "00acc1",
                                        cursor: "pointer"
                                      }}
                                      onClick={this.hidepassword_forget}
                                    />
                                  )}
                                </InputAdornment>
                              )
                            }}
                          />
                          <CustomInput
                            success={
                              this.state.forgot_passwordConfirmState ===
                              "success"
                            }
                            error={
                              this.state.forgot_passwordConfirmState === "error"
                            }
                            id="Forgotpassword_confirmpassword"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            helpText={
                              this.state.forgot_passwordConfirmState === "error"
                                ? "Please provide same password"
                                : null
                            }
                            inputProps={{
                              placeholder: "Confirm Password*",
                              onChange: event =>
                                this.forget_change(
                                  event,
                                  "forgot_passwordConfirm",
                                  "equalTo_forgot_password",
                                  "forgot_password"
                                ),
                              // onChange:this.handleInputChange,
                              type: this.state.hidepassword_confirm
                                ? "text"
                                : "password",
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Lock
                                    className={classes.inputAdornmentIcon}
                                    style={{ color: "#ffc90d" }}
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment
                                  style={{ marginRight: "-5px" }}
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  {this.state.hidepassword_confirm ? (
                                    <Visibility
                                      className={classes.inputAdornmentIcon}
                                      style={{
                                        color: "00acc1",
                                        cursor: "pointer"
                                      }}
                                      onClick={this.hidepassword_confirm_forget}
                                    />
                                  ) : (
                                    <VisibilityOff
                                      className={classes.inputAdornmentIcon}
                                      style={{
                                        color: "00acc1",
                                        cursor: "pointer"
                                      }}
                                      onClick={this.hidepassword_confirm_forget}
                                    />
                                  )}
                                </InputAdornment>
                              )
                            }}
                          />

                          <div className={classes.textCenter}>
                            <LoaderButton
                              id="Forgetpassword_verifyotpbutton"
                              type="submit"
                              disabled={this.verifybutton()}
                              onClick={this.resetpassword}
                              isLoading={this.state.isLoading1}
                              text="Verify OTP"
                              loadingText="Verifying..."
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter className={classes.textCenter}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                          <p>
                            Please wait for {this.state.value} seconds to resend
                            code...
                          </p>
                          <LoaderButton
                            id="Forgetpassword_resendotpbutton"
                            type="submit"
                            disabled={this.state.value !== 0}
                            onClick={
                              this.state.value === 0 ? this.sendotp : null
                            }
                            isLoading={this.state.isLoading}
                            text="Resend"
                            loadingText="Resending..."
                          />
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  </Card>
                </form>
              </div>
            )}
          </GridItem>
        </GridContainer>

        {this.state.alert}
        {this.state.openWarning_forgetpassword ? (
          <WarningAlert
            confirmBtnText="Try Again"
            errorMessage={this.state.errorMessage_forgetpassword}
            title="Failed"
          />
        ) : null}
      </div>
    );
  }
}

ForgetpasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(ForgetpasswordPage);
