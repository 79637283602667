import React from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";
// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
// @material-ui icons
import Home from "@material-ui/icons/Home";
// @material-ui core component
import withStyles from "@material-ui/core/styles/withStyles";
// image
import pagenotfound from "../../assets/img/page not found.png";
// style
import Notfoundstyle from "./Notfoundstyle";
// file import
import history from "../../history";
class Notfound extends React.Component {
  handleClose_notfound = () => {};

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal
          open={true}
          animationDuration={1000}
          onClose={this.handleClose_notfound}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h1
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                PAGE NOT FOUND!!!
              </h1>
              <img src={pagenotfound} alt="..." className={classes.img} />
              <div className={classes.center}>
                <Button
                  color="tumblr"
                  onClick={() => history.push("/dashboard")}
                >
                  <Home /> &nbsp; Go Back To Homepage
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </Modal>
      </div>
    );
  }
}

Notfound.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Notfoundstyle)(Notfound);
