import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import Check from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import Hourglass from "@material-ui/icons/HourglassEmpty";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
//Custom-Component
import dashboardStyle from "../CustomStyles/dashboardstyle";
import UrlList from "../Url/UrlList.jsx";
import ErrorPage from "../Pages/errorPage.jsx";

class Dashboardpage extends React.Component {
  constructor(props) {
    super(props);
    const {
      getTotalCount,
      getApprovedCount,
      getPendingCount,
      getRejectedCount
    } = this.props.document;
    this.state = {
      alert: "",
      isFlipped: false,
      employer_username: "",
      //Statistics count
      total_doc: getTotalCount.count,
      approved_doc: getApprovedCount.count,
      pending_doc: getPendingCount.count,
      rejected_doc: getRejectedCount.count,
      hasError: false
    };
  }
  // componentDidCatch for errorhandling
  componentDidCatch() {
    this.setState({ hasError: true });
  }
  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <div>
          <ErrorPage />{" "}
        </div>
      );
    } else {
      return (
        <div>
          {this.state.alert}
          <div className={classes.dashboardrowContainer}>
            <Card className={classes.statscard}>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <InsertDriveFile />
                </CardIcon>
                <p className={classes.cardCategory}>Uploaded Document</p>
                <h3 className={classes.cardCategory}>{this.state.total_doc}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Icon>info</Icon>
                  <Link
                    to={{
                      pathname: "/document",
                      state: "All"
                    }}
                    className={classes.a}
                  >
                    More Info
                  </Link>
                </div>
              </CardFooter>
            </Card>
            <Card className={classes.statscard}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Check />
                </CardIcon>
                <p className={classes.cardCategory}>Approved Document</p>
                <h3 className={classes.cardCategory}>
                  {this.state.approved_doc}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Icon>info</Icon>
                  <Link
                    to={{
                      pathname: "/document",
                      state: "Approved"
                    }}
                    className={classes.a}
                  >
                    More Info
                  </Link>
                </div>
              </CardFooter>
            </Card>
            <Card className={classes.statscard}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Cancel />
                </CardIcon>
                <p className={classes.cardCategory}>Rejected Document</p>
                <h3 className={classes.cardCategory}>
                  {this.state.rejected_doc}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Icon>info</Icon>
                  <Link
                    to={{
                      pathname: "/document",
                      state: "Rejected"
                    }}
                    className={classes.a}
                  >
                    More Info
                  </Link>
                </div>
              </CardFooter>
            </Card>
            <Card className={classes.statscard}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Hourglass />
                </CardIcon>
                <p className={classes.cardCategory}>Pending Document</p>
                <h3 className={classes.cardCategory}>
                  {this.state.pending_doc}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Icon>info</Icon>
                  <Link
                    to={{
                      pathname: "/document",
                      state: "Pending"
                    }}
                    className={classes.a}
                  >
                    More Info
                  </Link>
                </div>
              </CardFooter>
            </Card>
          </div>
          {/* Generating URL */}
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={11}>
              <UrlList user_doc={this.props.document} />
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

Dashboardpage.propTypes = {
  classes: propTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboardpage);
