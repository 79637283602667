import React from "react";
import MUIDataTable from "mui-datatables";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider } from "@material-ui/core/styles";
// @materila-ui icon
import ShowChart from "@material-ui/icons/ShowChart";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//styles
import userProfileStyles from "../CustomStyles/profilepagestyle";
import Mytheme from "../Muitheme_Modified.jsx";
// file import
import Errorpage from "../Pages/errorPage";

// Date formater
var dateFormat = require("dateformat");

class SystemLogGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      systemlogTable_data: [], // array to display system log
      hashError: false
    };
  }
  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }
  componentDidMount() {
    var systemlogTable_data = [];
    var self = this;
    const { systomlog } = this.props;
    var systemlog_list = systomlog.getSystemLog.logs;
    Object.keys(systemlog_list).forEach(function(key) {
      systemlogTable_data.push([
        systemlog_list[key].operation,
        !systemlog_list[key].timestamp
          ? "-"
          : dateFormat(
              new Date(systemlog_list[key].timestamp),
              "dd/mm/yyyy HH:MM:ss"
            ),
        !systemlog_list[key].browser ? "-" : systemlog_list[key].browser,
        !systemlog_list[key].ip ? "-" : systemlog_list[key].ip
      ]);
    });

    self.setState({
      systemlogTable_data: systemlogTable_data
    });
  }
  render() {
    const { classes } = this.props;
    const { hashError } = this.state;
    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      selectableRows: false,
      page: this.state.page
    };
    // declaring column name with filter
    const columns = [
      {
        name: "Activities",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Performed On",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Browser",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "IP",
        options: {
          filter: false,
          sort: true
        }
      }
    ];
    if (hashError) {
      return (
        <div>
          <Errorpage />
        </div>
      );
    } else {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} lg={12}>
            <div>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <ShowChart />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Activities List</h4>
                </CardHeader>
                <CardBody>
                  <MuiThemeProvider theme={Mytheme}>
                    {/* Displaying data in table */}
                    <MUIDataTable
                      title={""}
                      data={this.state.systemlogTable_data}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </CardBody>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      );
    }
  }
}

export default withStyles(userProfileStyles)(SystemLogGrid);
