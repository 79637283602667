import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";
import { ApolloConsumer } from "react-apollo";
import { graphql } from "react-apollo";
//Graphql and Mutation Query
import Getnotification from "../../graphql/getnotification.js";
import marknotificationRead from "../../graphql/marknotificationMutation.js";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
// import Search from '@material-ui/icons/Search';
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
// core components
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "./headerlinkStyle.jsx";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      alert: null,
      NotificationList:
        this.props.getNotification === null
          ? null
          : this.props.getNotification.notification,
      isloading: false,
      isError: this.props.isError ? this.props.isError : null
    };
  }
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleNotificationClick = async () => {
    this.setState({ open: true });
  };

  // Function to update the notification after mutation
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.NotificationList &&
      prevState.NotificationList.length === 1 &&
      nextProps.getNotification === null
    ) {
      return {
        NotificationList: nextProps.getNotification
      };
    } else if (
      prevState.NotificationList &&
      nextProps.getNotification &&
      prevState.NotificationList.length !==
        nextProps.getNotification.notification.length
    ) {
      return {
        NotificationList: nextProps.getNotification.notification
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  // function to delete the notification after mutation
  notifyClick = async (event, id) => {
    this.setState({ open: false });
    this.props.history.push(event);

    if (window.location.pathname !== "/requestdocument") {
      await this.props
        .mutate({
          variables: {
            username: localStorage.getItem("username"),
            id: id
          },
          refetchQueries: [
            {
              query: Getnotification,
              variables: {
                username: localStorage.getItem("username")
              }
            }
          ]
        })
        .then(res => {
          this.setState({ muatatenotification: true });
        })
        .catch(err => {
          this.setState({
            isError: true
          });
        });
    }
  };
  handleClose_headerlinks = () => {
    this.setState({ open: false });
  };
  // handle user logout
  handleLogout = async event => {
    localStorage.clear();
    this.props.history.push("/auth/login");
  };
  // handle logout failure
  warningWithConfirmMessage = err => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px", color: "black" }}
          title="Please try again"
          onConfirm={() => this.failhideAlert}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        >
          {err.message}
        </SweetAlert>
      )
    });
  };
  //to hide alert
  failhideAlert = () => {
    this.setState({
      alert: null
    });
  };
  render() {
    const { classes, rtlActive } = this.props;
    const { NotificationList, isError, open } = this.state;
    //!for search field in header
    // const searchButton =
    // 	classes.top +
    // 	' ' +
    // 	classes.searchButton +
    // 	' ' +
    // 	classNames({
    // 		[classes.searchRTL]: rtlActive
    // 	});
    const dropdownItem = classNames(classes.dropdownItem, classes.infoHover, {
      [classes.dropdownItemRTL]: rtlActive
    });
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <ApolloConsumer>
        {client => (
          <div className={wrapper}>
            {this.state.alert}
            {
              //! serch is added for future
              /* <CustomInput
							rtlActive={rtlActive}
							formControlProps={{
								className: classes.top + " " + classes.search
							}}
							inputProps={{
								placeholder: "Search",
								inputProps: {
								"aria-label": "Search",
								className: classes.searchInput
								}
							}}
							/>
							<Button
							color="white"
							aria-label="edit"
							justIcon
							round
							className={searchButton}
							>
							<Search
								className={classes.headerLinksSvg + " " + classes.searchIcon}
							/>
							</Button> */
            }
            <div className={managerClasses}>
              <Hidden smDown implementation="css">
                <Button
                  color="transparent"
                  simple
                  aria-label="Dashboard"
                  justIcon
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                  onClick={() => this.props.history.push("/dashboard")}
                >
                  <Dashboard
                    className={
                      classes.headerLinksSvg +
                      " " +
                      (rtlActive
                        ? classes.links + " " + classes.linksRTL
                        : classes.links)
                    }
                  />
                  <Hidden mdUp implementation="css">
                    <span className={classes.linkText}>{"Dashboard"}</span>
                  </Hidden>
                </Button>
                <Button
                  color="transparent"
                  justIcon
                  aria-label="Notifications"
                  aria-owns={open ? "menu-list" : null}
                  aria-haspopup="true"
                  onClick={this.handleNotificationClick}
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                  buttonRef={node => {
                    this.anchorEl = node;
                  }}
                >
                  <Notifications
                    className={
                      classes.headerLinksSvg +
                      " " +
                      (rtlActive
                        ? classes.links + " " + classes.linksRTL
                        : classes.links)
                    }
                  />
                  {isError ? (
                    <span className={classes.warningNotification}>!</span>
                  ) : NotificationList === null ? (
                    ""
                  ) : (
                    <span className={classes.notifications}>
                      {NotificationList.length}
                    </span>
                  )}
                  <Hidden mdUp implementation="css">
                    <span
                      onClick={this.handleClick}
                      className={classes.linkText}
                    >
                      {"Notification"}
                    </span>
                  </Hidden>
                </Button>
                <Popper
                  open={open}
                  anchorEl={this.anchorEl}
                  transition
                  disablePortal
                  placement="bottom-end"
                  className={classNames({
                    [classes.popperClose]: !open,
                    [classes.pooperResponsive]: true,
                    [classes.pooperNav]: true
                  })}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list"
                      style={{ transformOrigin: "0 0 0" }}
                    >
                      <Paper className={classes.dropdown}>
                        <ClickAwayListener
                          onClickAway={this.handleClose_headerlinks}
                        >
                          <MenuList role="menu">
                            {isError ? (
                              <MenuItem className={dropdownItem} disabled>
                                {
                                  "Something went wrong. Please try after some time"
                                }
                              </MenuItem>
                            ) : NotificationList === null ? (
                              <MenuItem className={dropdownItem} disabled>
                                {"No Notification found"}
                              </MenuItem>
                            ) : (
                              NotificationList.map((prop, key) => (
                                <div key={key}>
                                  <MenuItem
                                    key={key}
                                    className={dropdownItem}
                                    onClick={() =>
                                      this.notifyClick(prop.action_url, prop.id)
                                    }
                                  >
                                    {prop.message}
                                  </MenuItem>
                                </div>
                              ))
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Button
                  color="transparent"
                  aria-label="PowerSettingsNew"
                  justIcon
                  className={classes.buttonLink}
                  muiClasses={{
                    label: ""
                  }}
                  onClick={this.handleLogout}
                >
                  <PowerSettingsNew
                    className={classes.headerLinksSvg + " " + classes.links}
                  />
                </Button>
              </Hidden>
            </div>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const headerlinkwithstyle = withStyles(headerLinksStyle)(HeaderLinks);
export default graphql(marknotificationRead)(headerlinkwithstyle);
