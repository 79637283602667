import gql from 'graphql-tag'

const profileverificationMutation = gql`
  mutation CommentQuery(
    $username: String!
    $employer_username: String!
    $id: Int!
    $response: String!
    $file_hashes: String
    $expire_time: Date
    $ip: String!
    $browser: String!
    $version: String!
    $device_id: String!
  ) {
    approveOrRejectProfileValidtion(
      username: $username
      employer_username: $employer_username
      id: $id
      response: $response
      file_hashes: $file_hashes
      expire_time: $expire_time
      ip: $ip
      browser: $browser
      version: $version
      device_id: $device_id
    ) {
      message
      __typename
    }
    __typename
  }
`

export default profileverificationMutation
