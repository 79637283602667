import React from "react";
import { ApolloConsumer } from "react-apollo";
// file imports
import AadharVerifyPage from "./AadharVerifyPage";

class AadharIntegrationQuery extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <AadharVerifyPage history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default AadharIntegrationQuery;
