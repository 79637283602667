import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import { graphql } from "react-apollo";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Notifications from "@material-ui/icons/Notifications";
import headerStyle from "./headerStyle.jsx";
//file import
import HeaderLinkQuery from "./HeaderLinkQuery.jsx";

//queries
import Getnotification from "../../graphql/getnotification.js";
import marknotificationRead from "../../graphql/marknotificationMutation.js";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      alert: null,
      NotificationList: null,
      isloading: false,
      isError: false
    };
  }
  makeBrand() {
    const props = this.props;
    var name;
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }

  async componentDidMount() {
    // retrieve the notification count and array
    await this.props.client
      .query({
        query: Getnotification,
        variables: {
          username: localStorage.getItem("username")
        }
      })
      .then(response => {
        if (response.data.getNotification !== null)
          this.setState({
            NotificationList:
              response.data.getNotification === null
                ? null
                : response.data.getNotification.notification,
            isloading: false
          });
      })
      .catch(err => {
        this.setState({ isloading: false, isError: true });
      });
  }

  // function to delete the notification
  handlenotification = async (url, id) => {
    this.setState({ open: !this.state.open });
    if (window.location.pathname !== "/requestdocument") {
      await this.props
        .mutate({
          variables: {
            username: localStorage.getItem("username"),
            id: id
          },
          refetchQueries: [
            {
              query: Getnotification,
              variables: {
                username: localStorage.getItem("username")
              }
            }
          ]
        })
        .then(response => {
          this.setState({ isloading: false });
          if (url !== "") {
            this.props.history.push(url);
          }
        })
        .catch(err => {
          this.setState({
            isloading: false,
            isError: true
          });
        });
    }
  };
  handleNotificationClick = async () => {
    this.setState({ open: !this.state.open });
  };
  handleClose_header = () => {
    this.setState({ open: false });
  };

  // to retrieve the notification from headerlink query after mutation
  back = event => {
    const { NotificationList } = this.state;

    if (NotificationList && NotificationList.length === 1 && event === null) {
      this.setState({ NotificationList: event });
    } else if (
      NotificationList &&
      event.notification &&
      NotificationList.length !== event.notification.length
    ) {
      this.setState({
        NotificationList:
          event.notification === null ? null : event.notification
      });
    }
  };

  render() {
    const { classes, color, rtlActive } = this.props;
    const { NotificationList, isError, open } = this.state;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    const sidebarMinimize =
      classes.sidebarMinimize +
      " " +
      cx({
        [classes.sidebarMinimizeRTL]: rtlActive
      });
    const dropdownItem = cx(classes.dropdownItem, classes.infoHover, {
      [classes.dropdownItemRTL]: rtlActive
    });

    const managerClasses = cx({
      [classes.managerClasses]: true
    });
    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={sidebarMinimize}>
              {this.props.miniActive ? (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={this.props.sidebarMinimize}
                >
                  <ViewList className={classes.sidebarMiniIcon} />
                </Button>
              ) : (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={this.props.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
            </div>
          </Hidden>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button href="#" className={classes.title} color="transparent">
              {this.makeBrand()}
            </Button>
          </div>
          <Hidden smDown implementation="css">
            <HeaderLinkQuery
              rtlActive={rtlActive}
              history={this.props.history}
              client={this.props.client}
              back={this.back}
            />
          </Hidden>
          <Hidden mdUp implementation="css">
            <div className={managerClasses}>
              <Button
                color="transparent"
                justIcon
                aria-label="Notifications"
                aria-owns={open ? "menu-list" : null}
                aria-haspopup="true"
                onClick={this.handleNotificationClick}
                className={
                  rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                }
                muiClasses={{
                  label: rtlActive ? classes.labelRTL : ""
                }}
                buttonRef={node => {
                  this.anchorEl = node;
                }}
              >
                <Notifications
                  className={
                    classes.headerLinksSvg +
                    " " +
                    (rtlActive
                      ? classes.links + " " + classes.linksRTL
                      : classes.links)
                  }
                />
                {isError ? (
                  <span className={classes.warningNotification}>!</span>
                ) : NotificationList === null ? (
                  ""
                ) : (
                  <span className={classes.notifications}>
                    {NotificationList.length}
                  </span>
                )}
              </Button>
              <Popper
                open={open}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                placement="bottom-end"
                className={cx({
                  [classes.popperClose]: !open,
                  [classes.pooperResponsive]: true,
                  [classes.pooperNav]: true
                })}
                modifiers={{
                  arrow: {
                    enabled: true,
                    element: "node"
                  }
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list"
                    style={{ transformOrigin: "0 0 0" }}
                  >
                    <Paper className={classes.dropdown}>
                      <ClickAwayListener onClickAway={this.handleClose_header}>
                        <MenuList role="menu">
                          {isError ? (
                            <MenuItem className={dropdownItem} disabled>
                              {
                                "Something went wrong. Please try after some time"
                              }
                            </MenuItem>
                          ) : NotificationList === null ? (
                            <MenuItem className={dropdownItem} disabled>
                              {"No Notification found"}
                            </MenuItem>
                          ) : (
                            NotificationList.map((prop, key) => (
                              <div key={key}>
                                <MenuItem
                                  key={key}
                                  className={dropdownItem}
                                  onClick={() =>
                                    this.handlenotification(
                                      prop.action_url,
                                      prop.id
                                    )
                                  }
                                >
                                  {prop.message}
                                </MenuItem>
                              </div>
                            ))
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

              <Button
                className={classes.appResponsive}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={this.props.handleDrawerToggle}
              >
                <Menu />
              </Button>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

const headerwithstyle = withStyles(headerStyle)(Header);
export default graphql(marknotificationRead)(headerwithstyle);
