const initialState = {
  personal: {},
  contactinfo: {},
  account: {},
  otpcode: {}
};

const Employee_reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PERSONAL_DATA':
      state = {
        ...state,
        personal: action.payload
      };
      break;
    case 'GET_CONTACT_DATA':
      state = {
        ...state,
        contactinfo: action.payload
      };
      break;
    case 'GET_IDENTITY_DATA':
      state = {
        ...state,
        account: action.payload
      };
      break;
    case 'GET_OTP_DATA':
      state = {
        ...state,
        otpcode: action.payload
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};
export default Employee_reducer;
