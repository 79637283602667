// ##############################
// // // RegisterPage view styles
// #############################

const notfoundstyle = {
  center: {
    textAlign: 'center'
  },
  img: {
    width: '95%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '0'
  }
};

export default notfoundstyle;
