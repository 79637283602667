import React from "react";
import { ApolloConsumer } from "react-apollo";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";
// core components
import Generateurl from "./Generateurl.jsx";

// file import
import ErrorPage from "../Pages/errorPage.jsx";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

//queries and mutation
import urlquery from "../../graphql/urlQuery.js";

// url query
const UrlQuery = ({
  username = localStorage.getItem("username"),
  btnshow,
  backclose
}) => (
  //Url
  <Query query={urlquery} variables={{ username }}>
    {({ loading, error, data, getAllDocumentEmployee }) => {
      // loading until getting response from the backend
      if (loading) {
        return <LoadingScreenPage />;
      }
      // if any error occur in backend it will redirect to error page
      if (error) {
        return (
          <div>
            <ErrorPage />
          </div>
        );
      }

      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        // page to generate url
        <div>
          <Generateurl
            key={data.getEmployee.username}
            userdoc={data}
            buttonShow={btnshow}
            back={backclose}
          />
        </div>
      );
    }}
  </Query>
);

class UrlList extends React.Component {
  // scroll to top when page routes
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <ApolloConsumer>
        {client => (
          // calling query to get details of  uploaded,approved,pending document
          <UrlQuery
            backclose={this.props.close}
            btnshow={this.props.showButton}
            client={client}
          />
        )}
      </ApolloConsumer>
    );
  }
}

export default UrlList;
