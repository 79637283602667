import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
// material ui components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
// To import Material ui icon
import LockOpen from '@material-ui/icons/LockOpen';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// custom components
import CustomInput from 'Custom-component/CustomInput/CustomInput.jsx';
import LoaderButton from '../Custom-component/LoaderButton';
// file imports
import changepasswordStyle from './changepasswordStyle.jsx';
import WarningAlert from '../Container/WarningAlert.jsx';
import config from '../config.js';
import history from '../history';

//for validations
import validation from '../Container/Validation/ProfileValidation.jsx';
// for hasing passwords
var sha256 = require('js-sha256');

class Changepassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: '',
      oldpasswordState: '',
      oldpasswordErrMsg: '',
      newpasswordErrMsg: '',
      newpassword: '',
      newpasswordState: '',
      newpasswordConfirm: '',
      newpasswordConfirmState: '',
      newpasswordConfirmErrMsg: '',
      hidepassword_confirm: false,
      hidepassword: false,
      errorMessage_changepassword: '',
      openWarning_changepassword: false,
      alert: null,
      showChapgepasswordModal: false,
      closemodal: false,
      isLoading: false
    };
  }
  // modal close button onclick function
  handleClose_changepass = () => {
    this.setState({
      alert: null,
      showChapgepasswordModal: false
    });
    this.props.close(this.state.closemodal);
  };
  // onchange function for input fields
  changepassword_change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'password_change_old':
        var old_password_validation = validation.password_validate(
          event.target.value,
          stateName
        );
        if (!old_password_validation[stateName]) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({
            [stateName + 'State']: 'error',
            [stateName + 'ErrMsg']: old_password_validation[stateName]
          });
        }
        break;
      case 'password_change_new':
        if (event.target.value) {
          var changepassword_validation = validation.password_validate_compare(
            event.target.value,
            stateName,
            this.state.newpasswordConfirm
          );
          if (changepassword_validation === 'error') {
            this.setState({
              [stateName + 'State']: 'error',
              newpasswordErrMsg:
                'Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8'
            });
          } else {
            if (changepassword_validation === 'success_compare') {
              this.setState({
                [stateName + 'ConfirmState']: 'success'
              });
            } else if (changepassword_validation === 'error_compare') {
              this.setState({
                [stateName + 'ConfirmState']: 'error'
              });
            }
            this.setState({
              [stateName + 'State']: 'success'
            });
          }
        } else {
          this.setState({ newpasswordErrMsg: 'Password is required' });
        }
        break;
      case 'equalTo_changepass':
        if (
          validation.compare_password(
            event.target.value,
            this.state.newpassword,
            stateName
          )
        ) {
          this.setState({
            [stateName + 'State']: 'success'
          });
        } else {
          this.setState({
            [stateName + 'State']: 'error'
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  // to enable_changepassword_button a change password button
  enable_changepassword_button = () => {
    if (
      this.state.oldpasswordState === 'success' &&
      this.state.newpasswordState === 'success' &&
      this.state.newpasswordConfirmState === 'success'
    ) {
      return false;
    } else {
      return true;
    }
  };
  // success alert
  autoCloseAlert_changepass = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px', color: '#000000' }}
          title='Password changed successfully'
          onConfirm={() => this.handleClose_changepass()}
          onCancel={() => this.handleClose_changepass()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
        />
      )
    });
  };
  // hide/show password
  hidepassword_changepass = () => {
    this.setState({ hidepassword: !this.state.hidepassword });
  };
  // hide/show password
  hidepassword_confirm_changepass = () => {
    this.setState({ hidepassword_confirm: !this.state.hidepassword_confirm });
  };
  // when button is enable_changepassword_buttond and onclicking the button
  handlechangepassword = async event => {
    this.setState({ isLoading: true, openWarning_changepassword: false });
    if (
      this.state.oldpasswordState === 'success' &&
      this.state.newpasswordState === 'success' &&
      this.state.newpasswordConfirmState === 'success'
    ) {
      try {
        const changepassword_payload = {
          newpassword: sha256(this.state.newpassword),
          oldpassword: sha256(this.state.oldpassword)
        };
        var headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('accessToken')
        };
        axios
          .post(config.cognito.changepassword_url, changepassword_payload, {
            headers: headers
          })
          .then(response => {
            this.setState({ isLoading: false });
            if (response.data.data === null) {
              if (response.data.errors.error_code === 'GBECO1013') {
                this.setState({
                  errorMessage_changepassword:
                    'Unable to change your password. Maximum limit exceed. Please try after some time',
                  openWarning_changepassword: true
                });
              } else {
                this.setState({
                  errorMessage_changepassword:
                    response.data.errors.error_message,
                  openWarning_changepassword: true
                });
              }
            } else {
              this.setState({
                shownewpasswordConfirm: false,
                oldpasswordState: '',
                newpasswordState: '',
                newpasswordConfirmState: ''
              });
              this.autoCloseAlert_changepass();
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              localStorage.clear();
              history.push('/auth/login');
            } else {
              this.setState({
                isLoading: false,
                errorMessage_changepassword:
                  'We experiencing difficulties with connectivity of the application, Please try again later',
                openWarning_changepassword: true
              });
            }
          });
      } catch (e) {
        this.setState({
          isLoading: false,
          errorMessage_changepassword:
            'We experiencing difficulties with connectivity of the application, Please try again later',
          openWarning_changepassword: true
        });
      }
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Dialog
          classes={{
            root: classes.center + ' ' + classes.modalRoot,
            paper: classes.modal
          }}
          open={true}
          keepMounted
          onClose={() => this.handleClose_changepass()}
          aria-labelledby='notice-modal-slide-title'
          aria-describedby='notice-modal-slide-description'
        >
          <DialogTitle
            id='notice-modal-slide-title'
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              <b>Change Password</b>
            </h4>
          </DialogTitle>
          <DialogContent
            id='notice-modal-slide-description'
            className={classes.modalBody}
          >
            <CustomInput
              success={this.state.oldpasswordState === 'success'}
              error={this.state.oldpasswordState === 'error'}
              id='changepassword_oldpassword'
              name='changepassword_oldpassword'
              formControlProps={{
                fullWidth: true,
                className: classes.formControl
              }}
              inputProps={{
                type: 'password',
                placeholder: 'Old Password*',
                onChange: event =>
                  this.changepassword_change(
                    event,
                    'oldpassword',
                    'password_change_old',
                    3
                  ),
                startAdornment: (
                  <InputAdornment
                    position='start'
                    className={classes.inputAdornment}
                  >
                    <LockOpen className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
              helpText={
                this.state.oldpasswordState === 'error'
                  ? this.state.oldpasswordErrMsg
                  : ''
              }
            />
            <CustomInput
              success={this.state.newpasswordState === 'success'}
              error={this.state.newpasswordState === 'error'}
              id='changepassword_newpassword'
              name='changepassword_newpassword'
              formControlProps={{
                fullWidth: true,
                className: classes.formControl
              }}
              inputProps={{
                type: this.state.hidepassword ? 'text' : 'password',
                placeholder: 'New Password*',
                onChange: event =>
                  this.changepassword_change(
                    event,
                    'newpassword',
                    'password_change_new',
                    3
                  ),
                startAdornment: (
                  <InputAdornment
                    position='start'
                    className={classes.inputAdornment}
                  >
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    style={{ marginRight: '-5px' }}
                    position='end'
                    className={classes.inputAdornment}
                  >
                    {this.state.hidepassword ? (
                      <Tooltip
                        id='tooltip-top'
                        title='Show'
                        placement='bottom'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Visibility
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: '00acc1',
                            cursor: 'pointer'
                          }}
                          onClick={this.hidepassword_changepass}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        id='tooltip-top'
                        title='Hide'
                        placement='bottom'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <VisibilityOff
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: '00acc1',
                            cursor: 'pointer'
                          }}
                          onClick={this.hidepassword_changepass}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                )
              }}
              helpText={
                this.state.newpasswordState === 'error'
                  ? this.state.newpasswordErrMsg
                  : ''
              }
            />
            <CustomInput
              success={this.state.newpasswordConfirmState === 'success'}
              error={this.state.newpasswordConfirmState === 'error'}
              id='changepassword_newpasswordConfirm'
              name='changepassword_newpasswordConfirm'
              formControlProps={{
                fullWidth: true,
                className: classes.formControl
              }}
              inputProps={{
                type: this.state.hidepassword_confirm ? 'text' : 'password',
                placeholder: 'Confirm Password*',
                onChange: event =>
                  this.changepassword_change(
                    event,
                    'newpasswordConfirm',
                    'equalTo_changepass',
                    'newpassword'
                  ),
                startAdornment: (
                  <InputAdornment
                    position='start'
                    className={classes.inputAdornment}
                  >
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    style={{ marginRight: '-5px' }}
                    position='end'
                    className={classes.inputAdornment}
                  >
                    {this.state.hidepassword_confirm ? (
                      <Tooltip
                        id='tooltip-top'
                        title='Show'
                        placement='bottom'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Visibility
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: '00acc1',
                            cursor: 'pointer'
                          }}
                          onClick={this.hidepassword_confirm_changepass}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        id='tooltip-top'
                        title='Hide'
                        placement='bottom'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <VisibilityOff
                          className={classes.inputAdornmentIcon}
                          style={{
                            color: '00acc1',
                            cursor: 'pointer'
                          }}
                          onClick={this.hidepassword_confirm_changepass}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                )
              }}
              helpText={
                this.state.newpasswordConfirmState === 'error'
                  ? 'Please provide same password'
                  : ''
              }
            />
          </DialogContent>
          <DialogActions>
            <GridContainer justify='flex-start'>
              <Button
                onClick={() => this.handleClose_changepass()}
                className={classes.closebutton}
              >
                Close
              </Button>
            </GridContainer>
            <GridContainer justify='flex-end'>
              <LoaderButton
                color='info'
                disabled={this.enable_changepassword_button()}
                onClick={this.handlechangepassword}
                isLoading={this.state.isLoading}
                text=' Change Password'
                loadingText='Changing...'
              />
            </GridContainer>
          </DialogActions>
        </Dialog>
        {this.state.alert}
        {this.state.openWarning_changepassword ? (
          <WarningAlert
            confirmBtnText='Try Again'
            errorMessage={this.state.errorMessage_changepassword}
            title='Failed To Change'
          />
        ) : null}
      </div>
    );
  }
}

Changepassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(changepasswordStyle)(Changepassword);
