import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import Recaptcha from "react-google-invisible-recaptcha";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
// file imports
import config from "../../config.js";
import wizardStyle from "./WizardStyle.jsx";
import LoaderButton from "../LoaderButton";
import WarningAlert from "../../Container/WarningAlert.jsx";
//styles
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
// for hasing the passwords
var sha256 = require("js-sha256");
// styles
const style = {
  ...sweetAlertStyle,
  ...wizardStyle,
  ...buttonStyle
};

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      submitButton: this.props.steps.length === 2 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      },
      alert: null,
      allStates: {}, //! restructure the allstates data
      employee: {},
      errorMessage: "",
      isLoading: false,
      openwarning_registeration: false,
      resolved: false
    };
  }
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }
  updateWidth = () => {
    this.refreshAnimation(this.state.currentStep);
  };
  // ! TODO deprecated lifecycle want to change
  componentWillReceiveProps(nextProps) {
    this.setState({ employee: nextProps.employee });
  }
  //* execute when navigate through the tabs
  navigationStepChange = key => {
    if (this.props.steps) {
      var validationState = true;
      if (key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (this[this.props.steps[i].stepId].sendState !== undefined) {
            // this.setState({
            // 	allStates: [
            // 		...this.state.allStates,
            // 		{
            // 			[this.props.steps[i].stepId]: this[
            // 				this.props.steps[i].stepId
            // 			].sendState()
            // 		}
            // 	]
            // });
          }

          if (
            this[this.props.steps[i].stepId].isValidated !== undefined &&
            this[this.props.steps[i].stepId].isValidated() === false
          ) {
            validationState = false;
            break;
          }
        }
      }
      if (this.state.currentStep !== 3 && key !== 2) {
        if (validationState) {
          if (this.props.steps.length === key + 1) {
            this.formsubmit();
          } else {
            this.setState({
              currentStep: key,
              nextButton: this.props.steps.length > key + 2 ? true : false,
              previousButton: key > 0 && key < 3 ? true : false,
              finishButton: this.props.steps.length === key + 1 ? true : false,
              submitButton: this.props.steps.length === key + 2 ? true : false
            });
            this.refreshAnimation(key);
          }
        }
      }
    }
  };
  // function that retrieve response from google
  onResolved = () => {
    if (this.recaptcha.getResponse()) {
      this.setState({ resolved: true });
    }
  };
  autoCloseAlert_wizard = () => {
    // var contactnumber = String(employee_personal_contactnumber).substring(10, 13);

    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="OTP sent!"
          onConfirm={() => this.successhideAlert_wizard()}
          showConfirm={false}
        >
          Check your email to get OTP.
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert_wizard, 2000);
  };
  SuccessAlert_wizard = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="Registration Successfull!"
          onConfirm={() => this.hidesuccessAlert_wizard()}
          showConfirm={false}
        >
          <h4>Have a cool Verification process</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.hidesuccessAlert_wizard, 2000);
  };
  successhideAlert_wizard = () => {
    var key = this.state.currentStep + 1;
    this.setState({
      currentStep: key,
      nextButton: this.props.steps.length > key + 2 ? true : false,
      previousButton: key > 0 && key < 3 ? true : false,
      finishButton: this.props.steps.length === key + 1 ? true : false,
      submitButton: this.props.steps.length === key + 2 ? true : false
    });
    this.refreshAnimation(key);
    this.setState({
      alert: null
    });
    this[this.props.steps[this.state.currentStep].stepId]._increase();
  };
  hidesuccessAlert_wizard = () => {
    this.setState({
      alert: null
    });

    this.props.history.push("/auth/login");
  };
  //* create account for employee in cognito
  formsubmit = async () => {
    const account = this[
      this.props.steps[this.state.currentStep].stepId
    ].sendState();
    var username = account.account.employee_account_username;
    var password = sha256(account.account.employee_account_password);
    const {
      employee_fullname,
      employee_gender,
      employee_dob
    } = this.props.employee.personal;
    const {
      employee_address,
      employee_mailId,
      employee_phonenumber
    } = this.props.employee.contactinfo;
    try {
      await this.recaptcha.execute();
      this.setState({ openwarning_registeration: false });
      const signup_payload = {
        username: username,
        password: password,
        email: employee_mailId,
        name: employee_fullname.trim(),
        birthdate: employee_dob,
        phonenumber: employee_phonenumber,
        gender: employee_gender,
        address: employee_address.trim()
      };

      var headers = {
        "Content-Type": "application/json"
      };
      if (this.state.resolved) {
        axios
          .post(config.cognito.signup_url, signup_payload, { headers: headers })
          .then(response => {
            this.setState({ isLoading: false, resolved: false });
            if (response.data.data === null) {
              if (response.data.errors.error_code !== "GBECO1001") {
                this.setState({
                  isLoading: false,
                  openwarning_registeration: true,
                  errorMessage: response.data.errors.error_message
                });
              }
            } else {
              localStorage.setItem("username", username);
              this.autoCloseAlert_wizard();
            }
          })
          .catch(err => {
            this.setState({
              resolved: false,
              isLoading: false,
              openwarning_registeration: true,
              errorMessage:
                "We experiencing difficulties with connectivity of the application, Please try again later"
            });
          });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (e) {
      this.recaptcha.reset();
      this.setState({
        isLoading: false,
        openwarning_registeration: true,
        errorMessage:
          "We experiencing difficulties with connectivity of the application, Please try again later"
      });
    }
  };

  nextButtonClick() {
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        // this.setState({
        // 	allStates: [
        // 		...this.state.allStates,
        // 		{
        // 			[this.props.steps[this.state.currentStep].stepId]: this[
        // 				this.props.steps[this.state.currentStep].stepId
        // 			].sendState()
        // 		}
        // 	]
        // });
      }
      var key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 2 ? true : false,
        previousButton: key > 0 && key < 3 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
        submitButton: this.props.steps.length === key + 2 ? true : false
      });
      this.refreshAnimation(key);
    }
  }
  previousButtonClick = () => {
    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      // this.setState({
      // 	allStates: [
      // 		...this.state.allStates,
      // 		{
      // 			[this.props.steps[this.state.currentStep].stepId]: this[
      // 				this.props.steps[this.state.currentStep].stepId
      // 			].sendState()
      // 		}
      // 	]
      // });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 2 ? true : false,
        previousButton: key > 0 && key < 3 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
        submitButton: this.props.steps.length === key + 2 ? true : false
      });
      this.refreshAnimation(key);
    }
  };
  finishButtonClick = () => {
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      this.finishregistration();
    }
  };
  submitButtonClick = () => {
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      this.setState({ isLoading: true });
      this.formsubmit();
    }
  };
  refreshAnimation = index => {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.wizard.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({ movingTabStyle: movingTabStyle });
  };
  render() {
    const { classes, title, subtitle, color, steps } = this.props;
    return (
      <div className={classes.wizardContainer} ref="wizard">
        {this.state.alert}
        <Card className={classes.card}>
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>{title}</h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <button
                      className={classes.stepsAnchor}
                      onClick={() => this.navigationStepChange(key)}
                    >
                      {prop.stepName}
                    </button>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={node => (this[prop.stepId] = node)}
                    allStates={this.state.allStates}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            <div className={classes.left}>
              {this.state.submitButton ? (
                <div style={{ marginTop: "80px" }} />
              ) : null}
              {this.state.previousButton ? (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.right}>
              {this.state.nextButton ? (
                <Button
                  color="info"
                  className={this.props.nextButtonClasses}
                  onClick={() => this.nextButtonClick()}
                >
                  {this.props.nextButtonText}
                </Button>
              ) : null}
              {this.state.finishButton ? (
                <Button
                  style={{ display: "none" }}
                  color="info"
                  disabled
                  className={this.finishButtonClasses}
                >
                  {this.props.finishButtonText}
                </Button>
              ) : null}
              {this.state.submitButton ? (
                <span>
                  <Recaptcha
                    ref={ref => (this.recaptcha = ref)}
                    sitekey={config.siteKey}
                    onResolved={this.onResolved}
                  />
                  <br />
                  <LoaderButton
                    className={this.finishButtonClasses}
                    type="submit"
                    onClick={() => this.submitButtonClick()}
                    isLoading={this.state.isLoading}
                    text={this.props.submitButtonText}
                    loadingText="Submitting..."
                  />
                </span>
              ) : null}
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
        {this.state.openwarning_registeration ? (
          <WarningAlert
            confirmBtnText="Try Again"
            errorMessage={this.state.errorMessage}
            title="Registration Failed!!"
          />
        ) : null}
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "rose",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  submitButtonText: "Submit",
  finishButtonClasses: "",
  finishButtonText: "Verify otp",
  history: ""
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.func.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "info",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  formSubmitButton: PropTypes.func,
  submitButtonText: PropTypes.string,
  verifyButtonText: PropTypes.string,
  verifyButtonClick: PropTypes.func,
  validate: PropTypes.bool,
  history: PropTypes.object.isRequired
};

//connect this component to redux store
const mapStateToProps = state => {
  return {
    employee: state.employee
  };
};

const withstyleWizard = withStyles(style)(Wizard);

export default connect(mapStateToProps)(withstyleWizard);

// export default withStyles(wizardStyle)(Wizard);
