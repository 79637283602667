import { ApolloConsumer } from "react-apollo";
import React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";

// file import
import UrlGrid from "./UrlGrid.jsx";
import Errorpage from "../Pages/errorPage";
import Newuserpage from "../Pages/newuserpage.jsx";
import UrlList from "./UrlList";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

// query and mutation
import Urllistquery from "../../graphql/urllistQuery.js";

//Employment query
const UrlGridQuery = ({
  username = localStorage.getItem("username"),
  created_by = localStorage.getItem("username"),

  client
}) => (
  <Query query={Urllistquery} variables={{ username, created_by }}>
    {({ loading, data, error }) => {
      if (loading) {
        // loading until getting response from the backend
        return <LoadingScreenPage />;
      }
      // if any error occur in the backend
      if (error) {
        return (
          <div>
            <Errorpage />
          </div>
        );
      }

      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          {data.getMulUrl.Url === null &&
          Number.parseInt(localStorage.getItem("documentupload"), 10) === 1 ? (
            // if user does not upload any document it will redirect to onboardingscreen
            <div>
              <Newuserpage client={client} />
            </div>
          ) : // if user upload the document but does not share any document it will redirect to generate url screen
          data.getMulUrl.Url === null ? (
            <UrlList />
          ) : (
            // display user genereated URL's
            <UrlGrid
              key={data.getMulUrl.id}
              userdoc={data.getMulUrl}
              client={client}
            />
          )}
        </div>
      );
    }}
  </Query>
);

class UrlGridList extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <UrlGridQuery history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default UrlGridList;
