// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FolderShared from "@material-ui/icons/FolderShared";
import WatchLater from "@material-ui/icons/WatchLater";
import Link from "@material-ui/icons/Link";
import Description from "@material-ui/icons/Description";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Announcement from "@material-ui/icons/Announcement";
import InsertEmoticon from "@material-ui/icons/InsertEmoticon";
import ShowChart from "@material-ui/icons/ShowChart";
//core components
import Dashboard from "../Container/Dashboard/Dashboardquery.jsx";
import EmployeeList from "../Container/MyProfile/MyprofileQuery.jsx";
import MyDocument from "../Container/Document/DocumentQuery.jsx";
import AadharIntegrationQuery from "../Container/Aadhar/AadharIntegrationQuery";
import HistoryList from "../Container/History/HistoryQuery.jsx";
import UrlGridList from "../Container/Url/UrlGridQuery.jsx";
import ViewProfileRequest from "../Container/ViewProfileRequest/ProfileRequestQuery.jsx";
import SystemLog from "../Container/SystemLogs/SystemlogQuery.jsx";
import Billings from "../Container/Help/Help";
import Faqs from "../Container/FAQS/FAQS";
import Feedback from "../Container/Feedback/Feedback";
import DocumentRequest from "../Container/DocumentRequest/DocumentRequestPage.jsx";

var authenticatedroutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: AccountCircle,
    component: EmployeeList
  },
  {
    path: "/document",
    name: "My Document",
    icon: FolderShared,
    component: MyDocument
  },
  {
    path: "/history",
    name: "My History",
    icon: WatchLater,
    component: HistoryList
  },
  {
    path: "/url",
    name: "Digital Resume",
    state: "Digital Resume",
    icon: Link,
    component: UrlGridList
  },

  {
    path: "/viewprofilerequest",
    name: "View Profile Request",
    state: "viewprofilerequest",
    icon: Description,
    component: ViewProfileRequest
  },
  {
    path: "/activities",
    name: "Activities",
    state: "activities",
    icon: ShowChart,
    component: SystemLog
  },
  {
    path: "/help",
    name: "Help",
    icon: HelpOutline,
    component: Billings
  },

  {
    path: "/faq",
    name: "FAQs",
    icon: Announcement,
    component: Faqs
  },
  {
    path: "/feedback",
    name: "Feedback",
    icon: InsertEmoticon,
    component: Feedback
  },
  {
    path: "/requestdocument",
    name: "Document Request",
    icon: InsertEmoticon,
    component: DocumentRequest,
    aadharCollapse: true
  },
  {
    path: "/identityverify",
    name: "Identity verify",
    aadharCollapse: true,
    icon: InsertEmoticon,
    component: AadharIntegrationQuery
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" },
  { redirect: true, path: "*", pathTo: "/404", name: "NotFound" }
];

export default authenticatedroutes;
