import React from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
// @material-ui/icons
import PhonelinkLock from "@material-ui/icons/PhonelinkLock";
import Link from "@material-ui/icons/Link";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// custom components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
import LoaderButton from "../../../Custom-component/LoaderButton";
// file imports
import config from "../../../config.js";
import history from "../../../history.js";
import WarningAlert from "../../WarningAlert.jsx";
//styles
import step1style from "./step1style.jsx";
//for validation
import validation from "../../Validation/ProfileValidation.jsx";

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_verification_otpcode: "",
      employee_verification_otpcodeState: "",
      value: "60",
      isLoading: false,
      isLoading1: false,
      alert: null,
      errorMessage: "",
      employee_verification_otpcodeErrMsg: "",
      openwarning_register: false
    };
  }
  sendState() {
    return this.state;
  }
  // button enable_otpverify_button
  enable_otpverify_button = () => {
    if (this.state.employee_verification_otpcodeState === "success") {
      return false;
    } else {
      return true;
    }
  };
  // function for decrease value from 60 to 0
  _increase = () => {
    if (this.state.value > 0) {
      this.setState({
        value: this.state.value - 1
      });
      if (this.state.value !== 0) {
        setTimeout(this._increase.bind(this), 1000);
        return true;
      } else {
        clearInterval(this.interval);
        return false;
      }
    }
  };
  // onchange set values and do validation
  register_step4_change(event, stateName, type) {
    switch (type) {
      case "register_otp":
        var otp_register_validation = validation.verify_otp(
          event.target.value,
          stateName
        );
        if (!otp_register_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: otp_register_validation[stateName]
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  // dispatch the data to store in redux
  isValidated() {
    // const { employee } = this.props;
    if (this.state.employee_verification_otpcodeState === "success") {
      this.props.getotpcode(this.state);
      return true;
    } else {
      this.setState({ employee_verification_otpcodeState: "error" });
      return false;
    }
  }
  // onclik for resend button
  resendcode = async () => {
    const { employee } = this.props;
    try {
      this.setState({ isLoading1: true, openwarning_register: false });
      const resendsignp_payload = {
        username: employee.account.employee_account_username
      };
      var headers = {
        "Content-Type": "application/json"
      };
      axios
        .post(config.cognito.resendcode_url, resendsignp_payload, {
          headers: headers
        })
        .then(response => {
          if (response.data.data === null) {
            this.setState({
              isLoading1: false,
              openwarning_register: true,
              errorMessage: response.data.errors.error_message
            });
          } else {
            this.autoCloseAlert_register();
            this.setState({ value: "60", isLoading1: false });
            this._increase();
          }
        })
        .catch(err => {
          this.setState({
            isLoading1: false,
            openwarning_register: true,
            errorMessage:
              "We experiencing difficulties with connectivity of the application, Please try again later"
          });
        });
    } catch (e) {
      this.setState({
        isLoading1: false,
        openwarning_register: true,
        errorMessage:
          "We experiencing difficulties with connectivity of the application, Please try again later"
      });
    }
  };

  // success alert
  SuccessAlert_register = () => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="Registration Successfull!"
          onConfirm={() => this.hidesuccessAlert_register()}
          showConfirm={false}
        >
          <h4>Have a cool Verification process</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.hidesuccessAlert_register, 2000);
  };
  // close success alert
  hidesuccessAlert_register = () => {
    this.setState({
      alert: null
    });
    history.push("/auth/login");
  };
  //* verify employee mail to confirm the user in cognito registration process
  finishregistration = async () => {
    if (this.state.employee_verification_otpcodeState === "success") {
      try {
        this.setState({ isLoading: true });
        const confirmSignUp_payload = {
          username: localStorage.getItem("username"),
          code: this.state.employee_verification_otpcode
        };
        var headers = {
          "Content-Type": "application/json"
        };
        axios
          .post(config.cognito.confirmsignup_url, confirmSignUp_payload, {
            headers: headers
          })
          .then(response => {
            this.setState({ isLoading: false });
            if (response.data.data === null) {
              if (response.data.errors.error_code === "GBECO1012") {
                this.setState({
                  errorMessage: "Your OTP gets expired. Please resent your OTP",
                  openwarning_register: true
                });
              } else if (
                response.data.errors.error_code === "GBECO1011" ||
                response.data.errors.error_code === "GBECO1002"
              ) {
                this.setState({
                  errorMessage:
                    "Username not exists.Please provide valid username",
                  openwarning_register: true
                });
              } else {
                this.setState({
                  errorMessage: response.data.errors.error_message,
                  openwarning_register: true
                });
              }
            } else {
              this.setState({ isLoading: false });
              this.SuccessAlert_register();
            }
          })
          .catch(err => {
            this.setState({
              isLoading: false,
              openwarning_register: true,
              errorMessage:
                "We experiencing difficulties with connectivity of the application, Please try again later"
            });
          });
      } catch (e) {
        this.setState({
          isLoading: false,
          openwarning_register: true,
          errorMessage:
            "We experiencing difficulties with connectivity of the application, Please try again later"
        });
      }
    } else {
      if (this.state.employee_verification_otpcode === "") {
        this.setState({
          employee_verification_otpcodeState: "error",
          employee_verification_otpcodeErrMsg: "otp is required"
        });
      }
    }
  };
  //success alert
  autoCloseAlert_register = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-200px", color: "black" }}
          title="OTP sent!"
          onConfirm={() => this.successhideAlert_register()}
          showConfirm={false}
        >
          Check your email to get OTP.
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert_register, 2000);
  };
  // close success alert
  successhideAlert_register = () => {
    this.setState({
      alert: null
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>OTP Verification</h4>
        </GridItem>
        <GridItem xs={12} sm={12} lg={12}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={5} lg={5}>
              <InfoArea
                title="OTP code"
                description="Please check your email for OTP code."
                icon={Link}
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={5} lg={5}>
              <CustomInput
                success={
                  this.state.employee_verification_otpcodeState === "success"
                }
                error={
                  this.state.employee_verification_otpcodeState === "error"
                }
                id="Register_employee_verification_otpcode"
                name="Register_employee_verification_otpcode"
                formControlProps={{
                  fullWidth: true
                }}
                helpText={
                  this.state.employee_verification_otpcodeState === "error"
                    ? this.state.employee_verification_otpcodeErrMsg
                    : null
                }
                inputProps={{
                  placeholder: "Enter your OTP*",
                  onChange: event =>
                    this.register_step4_change(
                      event,
                      "employee_verification_otpcode",
                      "register_otp",
                      "length",
                      6
                    ),
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <PhonelinkLock className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} lg={12}>
          <GridContainer justify="center">
            <LoaderButton
              disabled={
                this.state.employee_verification_otpcodeState !== "success"
              }
              type="submit"
              onClick={this.finishregistration}
              isLoading={this.state.isLoading}
              text="Verify OTP"
              loadingText="Verifying..."
            />
          </GridContainer>
        </GridItem>
        <br />
        <GridItem xs={11} sm={8} md={8} lg={8}>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={8} md={8} lg={8}>
                <h4 className={classes.infoText}>
                  <br />
                  Please Wait {this.state.value} seconds to resend code..
                </h4>
              </GridItem>
              <br />
              <GridItem xs={11} sm={4} md={4} lg={4}>
                <div>
                  <LoaderButton
                    disabled={this.state.value !== 0}
                    type="submit"
                    onClick={this.state.value === 0 ? this.resendcode : null}
                    isLoading={this.state.isLoading1}
                    text="Resend"
                    loadingText="Resending..."
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
        {this.state.alert}
        {this.state.openwarning_register ? (
          <WarningAlert
            confirmBtnText="Try Again"
            errorMessage={this.state.errorMessage}
            title="Registration Failed!!"
          />
        ) : null}
      </GridContainer>
    );
  }
}

// connect this component to redux store
const mapStateToProps = state => {
  return {
    employee: state.employee
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getotpcode: otpcode => {
      dispatch({
        type: "GET_OTP_DATA",
        payload: otpcode
      });
    }
  };
};
const withstyleOTP = withStyles(step1style)(Step4);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withstyleOTP);
