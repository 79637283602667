import userProfileStyles from '../CustomStyles/profilepagestyle';
import { infoColor } from 'assets/jss/material-dashboard-pro-react.jsx';

const documentStyle = {
  ...userProfileStyles,
  center: {
    textAlign: 'center'
  },
  cardheader: {
    marginLeft: '20px !important',
    marginTop: '10px'
  },
  cardsideHeader: {
    color: '#989595',
    fontWeight: '300',
    fontSize: '1.2em'
  },
  cardShadow: {
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  viewiconbutton: {
    color: '#ffffff',
    backgroundColor: infoColor,
    '&:hover ,&:focus': {
      color: 'black'
    }
  }
};
export default documentStyle;
