import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import qs from "query-string";
import { Redirect } from "react-router-dom";
// file imports
import Errorpage from "../Pages/errorPage.jsx";
import DocumentRequest from "./DocumentRequest.jsx";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

//Graphql Queries
import StagingQuery from "../../graphql/stagingQuery.js";

//Retrieve all employees onboarded into our application
const DocumentStagingQuery = ({
  username = localStorage.getItem("username"),
  file_hash,
  history,
  client
}) => (
  <Query query={StagingQuery} variables={{ username, file_hash }}>
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingScreenPage />;
      }
      if (error) {
        return (
          <div>
            <Errorpage />
          </div>
        );
      }
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        <div>
          <DocumentRequest
            requestDetails={data.showStagingFile}
            history={history}
            client={client}
          />
        </div>
      );
    }}
  </Query>
);

class DocumentRequestPage extends React.Component {
  render() {
    const parsed = qs.parse(window.location.search);
    return (
      <ApolloConsumer>
        {client => (
          <DocumentStagingQuery
            file_hash={parsed.file_hash}
            history={this.props.history}
            client={client}
          />
        )}
      </ApolloConsumer>
    );
  }
}

export default DocumentRequestPage;
