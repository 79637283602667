import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import SweetAlert from "react-bootstrap-sweetalert"; // react component used to create sweet alerts
import { connect } from "react-redux";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
// custom coponents
import CustomInput from "../../Custom-component/CustomInput/CustomInput.jsx";
import LoaderButton from "../../Custom-component/LoaderButton";
// styles
import loginPageStyle from "./loginPageStyle.jsx";
//images
import Robot from "assets/img/robot.png";
// File imports
import config from "../../config.js";
import WarningAlert from "../WarningAlert.jsx";
// for validations
import validation from "../Validation/ProfileValidation.jsx";
// for hasing the values
var sha256 = require("js-sha256");

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton_login: "cardHidden",
      employee_login_username: "",
      employee_login_usernameState: "",
      employee_login_password: "",
      employee_login_passwordState: "",
      employee_login_usernameErrMsg: "",
      employee_login_passwordErrMsg: "",
      isLoading: false,
      alert: null,
      user_data: {},
      errorMessage_login: "",
      openWarning_login: false
    };
  }
  // Success alert
  autoCloseAlert_login = name => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-218px", color: "#000000" }}
          title="Login Successfull!"
          onConfirm={() => this.successhideAlert_login()}
          showConfirm={false}
        >
          <h4>Welcome {name} !!</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert_login, 2000);
  };
  // close the success alert
  successhideAlert_login = () => {
    this.setState({
      alert: null
    });
    this.props.history.push("/dashboard");
  };

  // onchange function for all input fields
  login_change(event, stateName, type) {
    switch (type) {
      case "username_login":
        var username_login_validation = validation.verifyUsername(
          event.target.value,
          stateName
        );
        if (!username_login_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: username_login_validation[stateName]
          });
        }
        break;
      case "password_login":
        var password_login_validation = validation.password_validate(
          event.target.value,
          stateName
        );
        if (!password_login_validation[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "ErrMsg"]: password_login_validation[stateName]
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  // onclick for login button
  loginClick = async event => {
    event.preventDefault();
    var user_data = {};
    this.setState({ isLoading: true, openWarning_login: false });
    if (
      this.state.employee_login_usernameState !== "error" &&
      this.state.employee_login_passwordState !== "error"
    ) {
      try {
        const signin_payload = {
          username: this.state.employee_login_username,
          password: sha256(this.state.employee_login_password)
        };
        var headers = {
          "Content-Type": "application/json"
        };
        axios
          .post(
            config.cognito.signin_url,
            signin_payload,
            { crossdomain: true },
            { headers: headers }
          )
          .then(response => {
            if (response.data.data === null) {
              localStorage.setItem("userlogged", false);
              this.setState({ isLoading: false });
              if (response.data.errors.error_code === "GBECO1007") {
                this.setState({
                  errorMessage_login:
                    "You are not verified your OTP. Please wait 24 hrs to make registration. Use new Username and Email for immediate registration ",
                  openWarning_login: true
                });
              } else {
                this.setState({
                  errorMessage_login: response.data.errors.error_message,
                  openWarning_login: true
                });
              }
            } else {
              user_data = {
                accessToken: response.data.data.AccessToken,
                idToken: response.data.data.IdToken,
                refreshToken: response.data.data.RefreshToken,
                username: this.state.employee_login_username
              };
              localStorage.setItem(
                "accessToken",
                response.data.data.AccessToken
              );
              localStorage.setItem("idToken", response.data.data.IdToken);
              localStorage.setItem(
                "refreshToken",
                response.data.data.RefreshToken
              );
              localStorage.setItem(
                "username",
                this.state.employee_login_username.toLowerCase()
              );
              localStorage.setItem("name", response.data.data.Name);
              localStorage.setItem("email", response.data.data.Email);
              localStorage.setItem(
                "aadhar_verified",
                response.data.data.aadhar_verified
              );
              this.props.getuserdata(user_data);
              this.autoCloseAlert_login(response.data.data.Name);
              localStorage.setItem("userlogged", true);
              this.setState({ isLoading: false });
            }
          })
          .catch(err => {
            localStorage.setItem("userlogged", false);
            this.setState({
              isLoading: false,
              errorMessage_login:
                "We experiencing difficulties with connectivity of the application. Please try again later",
              openWarning_login: true
            });
          });
      } catch (e) {
        this.setState({
          isLoading: false,
          errorMessage_login:
            "We experiencing difficulties with connectivity of the application. Please try again later",
          openWarning_login: true
        });
      }
    } else {
      this.setState({ isLoading: false });
      if (
        this.state.employee_login_password === "" ||
        this.state.employee_login_passwordState === "error"
      ) {
        this.setState({
          employee_login_passwordState: "error",
          employee_login_passwordErrMsg: "Password is required"
        });
      }
      if (
        this.state.employee_login_username === "" ||
        this.state.employee_login_usernameState === "error"
      ) {
        this.setState({
          employee_login_usernameState: "error",
          employee_login_usernameErrMsg: "Username is required"
        });
      }
    }
  };
  // enabling login button
  enable_login() {
    if (
      this.state.employee_login_usernameState === "success" &&
      this.state.employee_login_passwordState === "success"
    ) {
      return false;
    } else {
      return true;
    }
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction_login = setTimeout(
      function() {
        this.setState({ cardAnimaton_login: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction_login);
    this.timeOutFunction_login = null;
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.alert}
        <GridContainer justify="space-between" className={classes.alignImage}>
          <Hidden smDown>
            <GridItem xs={12} sm={6} md={5}>
              <img
                display={{ xs: "none", md: "block" }}
                src={Robot}
                alt="..."
                className="img"
              />
            </GridItem>
          </Hidden>
          <GridItem xs={12} sm={6} md={5}>
            <form>
              <Card login className={classes[this.state.cardAnimaton_login]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="info"
                >
                  <div className={classes.socialLine}>
                    <h4 className={classes.cardTitle}>Login</h4>
                  </div>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={
                      this.state.employee_login_usernameState === "success"
                    }
                    error={this.state.employee_login_usernameState === "error"}
                    id="employee_login_username"
                    name="employee_login_username"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      onChange: event =>
                        this.login_change(
                          event,
                          "employee_login_username",
                          "username_login"
                        ),
                      type: "text",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Person className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Username*"
                    }}
                    helpText={
                      this.state.employee_login_usernameState === "error"
                        ? this.state.employee_login_usernameErrMsg
                        : ""
                    }
                  />
                  <CustomInput
                    success={
                      this.state.employee_login_passwordState === "success"
                    }
                    error={this.state.employee_login_passwordState === "error"}
                    id="login_userpassword"
                    name="Login_userpassword"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      onChange: event =>
                        this.login_change(
                          event,
                          "employee_login_password",
                          "password_login"
                        ),
                      type: "password",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Lock className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Password*"
                    }}
                    helpText={
                      this.state.employee_login_passwordState === "error"
                        ? this.state.employee_login_passwordErrMsg
                        : ""
                    }
                  />
                  <p style={{ paddingTop: "0px" }}>* Required Fields</p>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer justify="center">
                    <div className={classes.textCenter}>
                      <GridItem xs={12} sm={12} md={12}>
                        <LoaderButton
                          id="login_button"
                          disabled={this.enable_login()}
                          type="submit"
                          onClick={this.loginClick}
                          isLoading={this.state.isLoading}
                          text="Let's GO"
                          loadingText="Logging in…"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <p>
                          {" "}
                          <Link
                            to="/auth/ForgetPasswordPage"
                            className={classes.a}
                          >
                            {" "}
                            Forget Password
                          </Link>
                        </p>
                      </GridItem>
                      <GridItem xs={12} sm={10} md={12}>
                        <p>
                          Not Registered Yet?{" "}
                          <Link to="/auth/register" className={classes.a}>
                            Register Now
                          </Link>
                        </p>
                      </GridItem>
                    </div>
                  </GridContainer>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        {this.state.openWarning_login ? (
          <WarningAlert
            confirmBtnText="Try Again"
            errorMessage={this.state.errorMessage_login}
            title="Failed To Login"
          />
        ) : null}
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

// connect this component to the store
const mapStateToProps = state => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getuserdata: user => {
      dispatch({
        type: "GET_USER_DATA",
        payload: user
      });
    }
  };
};

const withloginPageStyle = withStyles(loginPageStyle)(LoginPage);

export default connect(mapStateToProps, mapDispatchToProps)(withloginPageStyle);
