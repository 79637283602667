const initialState = {
  user: {}
};

const User_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_DATA":
      state = {
        ...state,
        user: action.payload
      };
      break;

    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};
export default User_reducer;
