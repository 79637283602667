import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";
//core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// file import
import ProfileRequest from "./ProfileRequest.jsx";
import DataNotFound from "../DataNotFound";
import Errorpage from "../Pages/errorPage";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

//queries
import viewprofilerequestQuery from "../../graphql/viewprofileQuery.js";
import urlquery from "../../graphql/urlQuery.js";

// view profile query
const ProfileRequestQuery = ({
  username = localStorage.getItem("username"),
  client
}) => (
  <Query query={viewprofilerequestQuery} variables={{ username }}>
    {({ loading: loadingOne, data: { viewProfileRequest }, error }) => (
      <Query query={urlquery} variables={{ username }}>
        {({
          loading: loadingTwo,
          data: { getMulEmployment, getAllExpTime },
          error
        }) => {
          if (loadingOne || loadingTwo) {
            return <LoadingScreenPage />;
          }
          if (error) {
            return (
              <div>
                <Errorpage />
              </div>
            );
          }
          return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
            <Redirect to="/identityverify" />
          ) : viewProfileRequest.profileRequest === null ? (
            // when no profile request exits
            <DataNotFound
              title={"No Profile Request Found"}
              message={
                "You will get this data if You Request your Employee to share their Employment Data with you"
              }
              button={false}
            />
          ) : (
            // profile request existing
            <div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <ProfileRequest
                    key={viewProfileRequest.profileRequest.id}
                    userdoc={viewProfileRequest}
                    client={client}
                    docdetails={getMulEmployment}
                    validity={getAllExpTime}
                  />
                </GridItem>
              </GridContainer>
            </div>
          );
        }}
      </Query>
    )}
  </Query>
);

class DocumentRequest extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <ProfileRequestQuery history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default DocumentRequest;
