import React from "react";
import PropTypes from "prop-types";
//material ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
//components
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Card from "components/Card/Card.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
//styles
import newuserStyle from "../../Container/Pages/newuserpagestyle.jsx";

class ImageCard extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card product className={classes.cardHover}>
        {this.props.badge_content}
        <CardHeader image className={classes.cardHeaderImage}>
          <img src={this.props.image} alt="..." />
        </CardHeader>
        <CardBody>
          <h4 className={classes.cardProductTitle}>
            <a href="#pablo" onClick={e => e.preventDefault()}>
              <b>{this.props.heading} </b>
            </a>
          </h4>
          <p className={classes.cardProductDesciprion}>
            {this.props.discription}
          </p>
        </CardBody>
        <CardFooter>
          <Hidden smDown>
            <GridContainer justify="center">
              {this.props.footer_content}
            </GridContainer>
          </Hidden>
          <Hidden mdUp>
            <GridContainer justify="flex-start">
              {this.props.backward_arrow}
            </GridContainer>
            <GridContainer justify="center">
              {this.props.footer_content}
            </GridContainer>
            <GridContainer justify="flex-end">
              {this.props.forward_arrow}
            </GridContainer>
          </Hidden>
        </CardFooter>
      </Card>
    );
  }
}

ImageCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(newuserStyle)(ImageCard);
