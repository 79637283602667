import gql from 'graphql-tag'

const Urllistquery = gql`
  query UrlQuery($created_by: String!) {
    getMulUrl(created_by: $created_by) {
      Url {
        url_address
        pin
        file_hashes
        document_count
        email
        exp_time
        created_by
        created_at
        __typename
      }
      __typename
    }
    __typename
  }
`
export default Urllistquery
