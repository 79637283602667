import React, { Suspense } from "react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

// @materiui core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// file import
import config from "../../config";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

// styles
import userProfileStyles from "../CustomStyles/profilepagestyle.jsx";
// Custom component
import Badge from "../../Custom-component/Badge/Badge.jsx";
import PictureUpload from "../../Custom-component/CustomUpload/PictureUpload.jsx";
// @materialui next icon
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import AddAlert from "@material-ui/icons/AddAlert";
// date formattar
var dateFormat = require("dateformat");
//Edit profile page
const EditProfile = React.lazy(() => import("./EditProfile.jsx"));
class Profilepage extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      // variable declaration
      username: user.username,
      name: user.name,
      aadhar_number: user.aadhar_number === null ? "-" : user.aadhar_number,
      unique_id: user.hyperledger_id === null ? "-" : user.hyperledger_id,
      address: user.address,
      email: user.email,
      email_verified: user.email_verified,
      phone: user.phone_number,
      gender: user.gender,
      dob: dateFormat(new Date(user.birthdate), "dd/mm/yyyy"),
      displaydob: user.birthdate,
      organizationname: user.company_name === null ? "-" : user.company_name,
      department: user.department === null ? "-" : user.department,
      designation: user.designation === null ? "-" : user.designation,
      // to display modal
      showEdit: false,
      urlcopied: "copy",
      profile_image_hash: user.profile_image_hash,
      fileContent: null,
      loaderDisplay: true,
      error_message: ""
    };
  }
  //function to show edit profile page
  editClick = () => {
    this.setState({ showEdit: true });
  };

  //Notification
  showNotification(place) {
    if (!this.state[place]) {
      var x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(
        function() {
          x[place] = false;
          this.setState(x);
        }.bind(this),
        6000
      );
    }
  }
  // function  to view profile page
  updateProfile = data => {
    this.setState({
      showEdit: false,
      email: data.changeEmail,
      name: data.name,
      phone: data.phone,
      dob: data.dob,
      displaydob: data.displayDob,
      address: data.address,
      gender: data.gender,
      email_verified:
        data.isVerified === "" ? this.state.email_verified : data.isVerified,
      urlcopied: "copy"
    });
  };

  // function  to view profile page
  closeclick = (email, isVerified) => {
    this.setState({
      showEdit: false,
      email: email,
      email_verified:
        isVerified === "" ? this.state.email_verified : isVerified,
      urlcopied: "copy"
    });
  };
  componentDidMount() {
    if (this.props.user.profile_image_hash) {
      try {
        const retrieveProfileData = {
          userName: this.state.username,
          hasedFilename: this.props.user.profile_image_hash
        };
        axios
          .post(config.profileImage.ProfileRetrieve, retrieveProfileData)
          .then(response => {
            if (response.data.data) {
              this.setState({
                loaderDisplay: false,
                fileContent:
                  "data:image/jpeg;base64," + response.data.data.fileContent
              });
            } else {
              this.setState({ loaderDisplay: false });
            }
          })
          .catch(err => {
            this.setState({ loaderDisplay: false });
          });
      } catch (e) {
        this.setState({ loaderDisplay: false });
      }
    } else {
      this.setState({ loaderDisplay: false });
    }
  }

  //getting error message
  back = e => {
    if (e.errorMessage !== "") {
      this.setState({
        error_message: e.errorMessage
      });
      this.showNotification("bc");
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Snackbar
          place="bc"
          color="danger"
          icon={AddAlert}
          message={this.state.error_message}
          open={this.state.bc}
          closeNotification={() => this.setState({ bc: false })}
          close
        />

        {/* to display  values in  edit profile page */}
        {this.state.loaderDisplay ? (
          <LoadingScreenPage />
        ) : this.state.showEdit ? (
          <Suspense fallback={<LoadingScreenPage />}>
            <EditProfile
              profileData={this.state}
              updateProfile={this.updateProfile}
              closeclick={this.closeclick}
            />
          </Suspense>
        ) : (
          // to display profile page
          <GridContainer justify="flex-start">
            <GridItem xs={12} sm={12} md={12}>
              <Card profile>
                <CardAvatar profile>
                  <PictureUpload
                    profile_image_hash={this.props.user.profile_image_hash}
                    email={this.state.email}
                    fileContent={this.state.fileContent}
                    data={this.back}
                  />
                </CardAvatar>
                <CardBody profile>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={11}>
                      <h5 className={classes.cardTitle}>{this.state.name}</h5>
                      <h4 className={classes.cardCategory}>
                        {this.state.designation}
                      </h4>
                      <p className={classes.description}>
                        This Profile is based on the details given by the user
                        on their registration...
                      </p>
                    </GridItem>
                  </GridContainer>

                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={6}>
                          <Card className={classes.cardSignup}>
                            <p className={classes.cardheader}>
                              Identity Details
                            </p>
                            <CardBody className={classes.cardBody}>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Unique Id</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                {/* <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.unique_id}</h4>
                                </GridItem> */}
                                {/* <GridContainer justify='center'> */}
                                {/* <GridItem xs={10} sm={7} md={5}>
                                  <h4
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      textAlign: 'center'
                                    }}
                                  >
                                    {this.state.unique_id}
                                  </h4>
                                </GridItem> */}
                                {/* <GridItem xs={2} md={2}>
                                  <Tooltip
                                    id='employee_unique_id_tooltip'
                                    title={this.state.urlcopied}
                                    placement='top'
                                  > */}

                                <div
                                  style={{
                                    width: "200px",
                                    overflow: "hidden"
                                  }}
                                >
                                  <IconButton
                                    style={{ float: "right" }}
                                    className={classes.viewiconbutton}
                                    id="generateurl_urlcopy_iconbutton"
                                    key="employer_unique_id"
                                    aria-label="employer_unique_id"
                                    color="inherit"
                                  >
                                    <CopyToClipboard
                                      text={this.state.unique_id}
                                      onCopy={() =>
                                        this.setState({
                                          urlcopied: "copied"
                                        })
                                      }
                                    >
                                      <Tooltip
                                        id="employer_unique_id_tooltip"
                                        title={this.state.urlcopied}
                                        placement="top"
                                      >
                                        <FileCopyOutlined
                                          style={{
                                            color: "#fff"
                                          }}
                                        />
                                      </Tooltip>
                                    </CopyToClipboard>
                                  </IconButton>

                                  <h4 className={classes.uniqueid_text}>
                                    {this.state.unique_id}
                                  </h4>
                                </div>

                                {/* </Tooltip>
                                </GridItem> */}
                              </GridContainer>
                              {/* </GridContainer> */}
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Username</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.username}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b>National Identity Number</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.aadhar_number}</h4>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <Card className={classes.cardSignup}>
                            <p className={classes.cardheader}>
                              Current/Past Company Details
                            </p>
                            <CardBody className={classes.cardBody}>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Company Name</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.organizationname}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Department Name</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.department}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Designation Name</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.designation}</h4>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card className={classes.cardSignup}>
                        <p className={classes.cardheader}>Employee Details</p>
                        <CardBody className={classes.cardBody}>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Name</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.name}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Gender</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.gender}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Address</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={5}>
                                  <h4>{this.state.address}</h4>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> DOB</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.dob}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Email</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={5}>
                                  <h4>{this.state.email}</h4>
                                </GridItem>
                                <GridItem xs={11} sm={3} md={1}>
                                  <GridContainer justify="flex-start">
                                    {this.state.email_verified !== "1" ? (
                                      <h4>
                                        <Badge
                                          color="info"
                                          tooltip={true}
                                          tooltipContent={
                                            "Your Email id is unverified. Please contact the administrator for more details."
                                          }
                                        >
                                          {"unverified"}
                                        </Badge>
                                      </h4>
                                    ) : null}
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="flex-start">
                                <GridItem xs={9} sm={3} md={3}>
                                  <h4 className={classes.cardsideHeader}>
                                    <b> Contact Number</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>:</h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={7}>
                                  <h4>{this.state.phone}</h4>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>

                  <Button color="info" onClick={this.editClick}>
                    Edit Profile
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}
export default withStyles(userProfileStyles)(Profilepage);
