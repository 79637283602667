// ##############################
// // // Dashboard View styles
// #############################

import {
  tooltip,
  cardTitle
} from 'assets/jss/material-dashboard-pro-react.jsx';
import cardHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/cardHeaderStyle.jsx';

const newuserStyle = {
  ...cardHeaderStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center'
  },
  cardCategory: {
    color: '#999999',
    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0'
  },
  cardProductDesciprion: {
    textAlign: 'center',
    color: '#999999'
  },
  iconbutton: {
    color: '#afb6b7'
  },

  cardHeaderImage: {
    position: 'relative',
    padding: '0',
    zIndex: '1',
    '& img': {
      width: '100% !important',
      borderRadius: '6px',
      pointerEvents: 'none',
      padding: '10% 20% 0 20% !important',
      boxShadow: 'none'
    },
    '& a': {
      display: 'block'
    }
  }
};

export default newuserStyle;
