var errors = {};
function Calculateminutes(validity_time, number, statename) {
  var validateDay = /^([1-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|3[0-5][0-9]|36[0-5])$/;
  var timeValidation = /^([1-9]|[1-5][0-9]|60)$/;
  var hourscheck = /^([1-9]|1[0-9]|2[0-4])$/;
  if (validity_time === 'Days' && number !== '' && !validateDay.test(number)) {
    errors[statename] = 'You can set maximum of 365 days';
  } else if (
    validity_time === 'Minutes' &&
    number !== '' &&
    !timeValidation.test(number)
  ) {
    errors[statename] =
      'Maximum of 60 minutes is allowed.You can configure in hours or days';
  } else if (
    validity_time === 'Hours' &&
    number !== '' &&
    !hourscheck.test(number)
  ) {
    errors[statename] =
      'Maximum of 24 hours is allowed.You can configure in days';
  } else {
    errors = {};
  }
  return errors;
}
export default { Calculateminutes };
