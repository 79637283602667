import gql from 'graphql-tag';

const HistoryEmployeeQuery = gql`
  query EmploymentQuery($username: String!) {
    getEmployeeHistory(username: $username) {
      history {
        employer_username
        employer_name
        employee_username
        employee_name
        file_hash
        organization_name
        employee_id
        designation
        department
        transaction_id
        transaction_details
        joining_date
        end_date
        action
        added_on
        __typename
      }
      __typename
    }
  }
`;
export default HistoryEmployeeQuery;
