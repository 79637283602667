import React from "react";
import propTypes from "prop-types";
import { ApolloConsumer } from "react-apollo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SweetAlert from "react-bootstrap-sweetalert";
import Email from "@material-ui/icons/Email";
import { graphql } from "react-apollo";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
// @material-ui/icons
import Linkicon from "@material-ui/icons/Link";
import Lock from "@material-ui/icons/Lock";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Timeline from "@material-ui/icons/Timeline";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
// Custom-Component
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
import LoaderButton from "../../Custom-component/LoaderButton";
//file imports
import WarningAlert from "../WarningAlert.jsx";
import validation from "../Validation/ProfileValidation.jsx";
import TimeCalculation from "../Validation/TimeCalculation.jsx";
// queries and mutation
import Urlmutation from "../../graphql/urlmutation.js";
import Emailquery from "../../graphql/emailQuery.js";
// images
import verified from "assets/img/verified.png";
// styles
import "./url.css";
import urlStyle from "./urlStyle";
import Mytheme from "../Muitheme_Modified.jsx";

//for date calculation
let date = require("date-and-time");
// for select in validity
var genurl_validityoption = [];
// for select in file
var genurl_fileoption = [];

const genurl_item_height = 48;
const genurl_item_padding_top = 8;
const genurl_menuprops = {
  PaperProps: {
    style: {
      maxHeight: genurl_item_height * 4.5 + genurl_item_padding_top,
      width: 250
    }
  }
};
class Generateurl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      generateUrlshow:
        this.props.userdoc.getApprovedCount.count > 0 ? true : false,
      validity: this.props.userdoc.getAllExpTime.time,
      employment: this.props.userdoc.getMulEmployment.Employment,

      file_hash: "",
      validity_display: "",
      employer_username: "",
      url: "",
      url_validity_time: "",
      pin: "",
      documents: [],
      number: "",
      numberState: "",
      email: "",
      emailErrMsg: "",
      emailState: "",
      urlcopied: false,
      pincopied: false,
      numberErrMsg: "",
      isLoading: false,
      showUrl: false,
      isFlipped: false,
      onboardcardFlip: false,
      requestsuccess: false,
      onboardrequestsuccess: false,
      approvedDocument:
        this.props.userdoc.getApprovedCount.count > 0 ? false : true,
      openWarning_generateurl: false,
      generateurl_errorMessage: ""
    };
  }

  // onchange funtion for input fields
  genurl_change(event, stateName, type, stateNameEqualTo, maxValue) {
    this.setState({ [stateName]: event.target.value });
    switch (type) {
      case "email":
        var verifyEmail = validation.verifyEmail(event.target.value, stateName);
        if (!verifyEmail[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            emailErrMsg: verifyEmail[stateName]
          });
        }
        break;

      case "number":
        var verifyDuration = validation.verifyDuration(
          event.target.value,
          stateName
        );
        if (!verifyDuration[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            numberErrMsg: verifyDuration[stateName]
          });
        }
        break;
      default:
        break;
    }
  }

  // successAlert_genurl message
  successAlert_genurl = () => {
    this.setState({
      alert: (
        <SweetAlert
          id="generateurl_emailsent_success"
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Email sent Successfully"
          onConfirm={() => this.successhideAlert_genurl}
          showConfirm={false}
        >
          <h4>Request your employer to check</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert_genurl, 3000);
  };
  // success alert time duration
  successhideAlert_genurl = () => {
    this.setState({
      alert: null,
      number: "",
      email: "",
      url: "",
      urlcopied: false,
      pincopied: false,
      pin: "",
      showUrl: false,
      generateUrlshow: true,
      file_hash: "",
      documents: [],
      url_validity_time: "",
      validity_display: "",
      exp_time: "",
      // type validation
      emailState: "",
      numberState: ""
    });
    window.location.reload();
  };

  // Function to validate duration
  validateDuration_url = async () => {
    if (!this.isValidated_genurl("url")) {
      this.setState({ isLoading: true });
      const { number, url_validity_time } = this.state;
      if (url_validity_time && number) {
        var validateduration = TimeCalculation.Calculateminutes(
          url_validity_time,
          number,
          "number"
        );
        if (validateduration["number"]) {
          this.setState({
            numberErrMsg: validateduration["number"],
            numberState: "error",
            isLoading: false
          });
        } else {
          this.generateClick();
        }
      }
    }
  };

  // onclick function for generate url and pin
  generateClick = async () => {
    var exp;
    var time = new Date();
    this.setState({ openWarning_generateurl: false });
    time.setMinutes(time.getMinutes() + 330);
    var number = parseInt(this.state.number, 10);
    var url_validity_time = this.state.url_validity_time;
    if (url_validity_time === "Minutes") {
      exp = date.addMinutes(time, number);
      exp = exp
        .toJSON()
        .substring(0, 19)
        .replace("T", " ");
      this.setState({
        exp_time: exp
      });
    } else if (url_validity_time === "Hours") {
      exp = date.addHours(time, number);
      exp = exp
        .toJSON()
        .substring(0, 19)
        .replace("T", " ");
      this.setState({
        exp_time: exp
      });
    } else if (url_validity_time === "Days") {
      exp = date.addDays(time, number);
      exp = exp
        .toJSON()
        .substring(0, 19)
        .replace("T", " ");
      this.setState({
        exp_time: exp
      });
    }
    try {
      await this.props
        .mutate({
          variables: {
            username: localStorage.getItem("username"),
            created_by: localStorage.getItem("username"),
            file_hashes: this.state.file_hash,
            exp_time: exp,
            ip: localStorage.getItem("ip"),
            browser: localStorage.getItem("browserName"),
            version: localStorage.getItem("browserVersion"),
            device_id: localStorage.getItem("deviceId")
          }
        })
        .then(response => {
          this.setState({
            showUrl: true,
            generateUrlshow: false,
            url: response.data.urlInsert.url_address,
            pin: response.data.urlInsert.pin,
            isLoading: false
          });
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            openWarning_generateurl: true,
            generateurl_errorMessage: "Response Not Successful"
          });
        });
    } catch (e) {
      this.setState({
        isLoading: false,
        openWarning_generateurl: true,
        generateurl_errorMessage: "Response Not Successful"
      });
    }
  };

  // enable/disable email submisssion button
  isValidated_genurl(type) {
    if (type === "email") {
      if (this.state.emailState !== "error" && this.state.email !== "") {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        this.state.file_hash &&
        this.state.numberState !== "error" &&
        this.state.url_validity_time !== ""
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  //function to select the duration
  changeduration_url = event => {
    this.setState({
      validity_display: event.target.value,
      url_validity_time: event.target.value.value
    });
  };

  // function to select the file listed in dropdown
  fileSelect = event => {
    this.setState({ documents: event.target.value });
    var file_arr;
    for (var i = 0; i < event.target.value.length; i++) {
      file_arr =
        i === 0
          ? event.target.value[i].value
          : file_arr + "," + event.target.value[i].value;
    }
    this.setState({ file_hash: file_arr });
  };

  //function to unselect the file
  handleDelete_url = data => () => {
    const chipData = [...this.state.documents];
    const chipToDelete = chipData.indexOf(data);
    chipData.splice(chipToDelete, 1);
    this.setState({ documents: chipData });
  };

  componentDidMount() {
    if (genurl_validityoption.length === 0) {
      this.state.validity.map((prop, key) => {
        return genurl_validityoption.push({
          value: prop.type,
          label: prop.type
        });
      });
      this.state.employment.map((prop, key) => {
        if (prop.verified_status === "Approved") {
          this.setState({ approvedDocument: false, generateUrlshow: true });
          return genurl_fileoption.push({
            value: prop.file_hash,
            label: prop.document_name
          });
        }
        return null;
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <ApolloConsumer>
        {client => (
          <div className={classes.dashboardrowContainer}>
            {this.state.alert}
            {this.props.buttonShow ? (
              <GridContainer justify="center" style={{ paddingRight: "18px" }}>
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip
                    id="tooltip-top"
                    title="Back"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      className={classes.backiconarrow}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.props.back}
                    >
                      <Icon>keyboard_backspace</Icon>
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            ) : null}
            <Card className={classes.statscard}>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardIconTitle.color}>
                    <b>Digital Resume</b>
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody className={classes.cardStyle}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={5}>
                    <br />
                    <br />
                    <InfoArea
                      title="Generating a public URL"
                      description="A public URL is generated with a validity of your choice which you can share with an employer"
                      icon={Linkicon}
                      iconColor="rose"
                    />
                    <InfoArea
                      title="Secure PIN"
                      description="A 4 digit security PIN is generated along with the public URL which will allow the employer to view your profile"
                      icon={Lock}
                      iconColor="primary"
                    />
                    <InfoArea
                      title="Tracking and Monitoring"
                      description="You can track if the employer has viewed your profile and revoke or extend the URL validity"
                      icon={Timeline}
                      iconColor="info"
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Card key="front" className={classes.cardSignup}>
                      <h4 className={classes.cardTitle}>
                        <b>Digital Resume Public URL</b>
                      </h4>
                      <CardBody>
                        {/* to display verify onboarding page */}
                        {this.state.approvedDocument ? (
                          <div className="verify-row">
                            <div className="verify-cell">
                              <img
                                src={verified}
                                alt="..."
                                className="verify-img"
                              />
                              <h3 className={classes.headingStyle}>
                                Verify Your Document{" "}
                              </h3>
                              <h4 className={classes.subheadingStyle}>
                                Verify Your Document by sending it to your
                                Authority
                              </h4>
                            </div>
                          </div>
                        ) : null}

                        {/* to display the generate url page*/}
                        {this.state.generateUrlshow ? (
                          <form className={classes.form}>
                            <MuiThemeProvider theme={Mytheme}>
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={10}>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      FormLabelClasses={{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused
                                      }}
                                    >
                                      Select your file*
                                    </InputLabel>
                                    <Select
                                      multiple
                                      value={this.state.documents}
                                      onChange={this.fileSelect}
                                      input={
                                        <Input
                                          classes={{
                                            underline: classes.underline
                                          }}
                                          id="select-multiple-chip"
                                        />
                                      }
                                      renderValue={selected => (
                                        <div className={classes.chips}>
                                          {selected.map(selectedValue => (
                                            <Chip
                                              key={selectedValue.value} // to avoid duplication
                                              label={selectedValue.label} // value displayed in chip
                                              className={classes.chip}
                                              onDelete={this.handleDelete_url(
                                                selectedValue
                                              )}
                                            />
                                          ))}
                                        </div>
                                      )}
                                      MenuProps={genurl_menuprops}
                                    >
                                      <MenuItem value="" disabled={true}>
                                        Employment
                                      </MenuItem>
                                      {genurl_fileoption.map(documents => (
                                        <MenuItem
                                          key={documents.value} // to avoid duplication
                                          value={documents} //pass key,value pair to display label
                                        >
                                          <Checkbox
                                            checked={
                                              this.state.documents.indexOf(
                                                documents
                                              ) > -1
                                            } // check selected document and exists documents are same to enable check box
                                          />

                                          {documents.label}
                                        </MenuItem> // values displayed in drop down
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={6}>
                                      <CustomInput
                                        success={
                                          this.state.numberState === "success"
                                        }
                                        error={
                                          this.state.numberState === "error"
                                        }
                                        labelText="Duration*"
                                        id="number"
                                        formControlProps={{
                                          fullWidth: true,
                                          className:
                                            classes.customFormControlClasses
                                        }}
                                        helpText={
                                          this.state.numberState === "error"
                                            ? this.state.numberErrMsg
                                            : null
                                        }
                                        inputProps={{
                                          onChange: event =>
                                            this.genurl_change(
                                              event,
                                              "number",
                                              "number"
                                            )
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          FormLabelClasses={{
                                            root: classes.cssLabel,
                                            focused: classes.cssFocused
                                          }}
                                        >
                                          Validity*
                                        </InputLabel>
                                        <Select
                                          MenuProps={genurl_menuprops}
                                          classes={{
                                            select: classes.select
                                          }}
                                          value={this.state.validity_display}
                                          onChange={this.changeduration_url}
                                          input={
                                            <Input
                                              classes={{
                                                underline: classes.underline
                                              }}
                                              id="select-single-chip"
                                            />
                                          }
                                        >
                                          {genurl_validityoption.map(
                                            genurl_validityoption => (
                                              <MenuItem
                                                key={
                                                  genurl_validityoption.value
                                                }
                                                value={genurl_validityoption}
                                              >
                                                {genurl_validityoption.label}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="center">
                                <div className={classes.center}>
                                  <LoaderButton
                                    style={{ marginTop: "10px" }}
                                    id="generateurl_button"
                                    color="info"
                                    disabled={this.isValidated_genurl("url")}
                                    onClick={this.validateDuration_url}
                                    isLoading={this.state.isLoading}
                                    text="Generate Url and Pin"
                                    loadingText="Generating..."
                                  />
                                </div>
                              </GridContainer>
                            </MuiThemeProvider>
                          </form>
                        ) : null}
                        {/* to display url and pin */}
                        {this.state.showUrl ? (
                          <form className={classes.form}>
                            <div>
                              <InfoArea
                                title="Your URL"
                                description=""
                                icon={Linkicon}
                                iconColor="info"
                              />
                              <GridContainer justify="center">
                                <GridItem xs={11} sm={11} md={11}>
                                  <GridContainer justify="center">
                                    <GridItem xs={10} sm={10} md={10}>
                                      <div className={classes.urlbox}>
                                        <span id="generateurl_urlcopy">
                                          <h4
                                            style={{
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              textAlign: "center",
                                              padding: "5px"
                                            }}
                                          >
                                            {this.state.url}
                                          </h4>
                                        </span>
                                      </div>
                                    </GridItem>
                                    <GridItem xs={2} md={2}>
                                      <Tooltip
                                        id="generateurl_urlcopy_tooltip"
                                        title={
                                          this.state.urlcopied
                                            ? "copied"
                                            : "copy"
                                        }
                                        placement="top"
                                      >
                                        <IconButton
                                          className={classes.viewiconbutton}
                                          id="generateurl_urlcopy_iconbutton"
                                          key="gen url"
                                          aria-label="url"
                                          color="inherit"
                                        >
                                          <CopyToClipboard
                                            text={this.state.url}
                                            onCopy={() =>
                                              this.setState({
                                                urlcopied: true,
                                                pincopied: false
                                              })
                                            }
                                          >
                                            <FileCopyOutlined
                                              style={{
                                                color: "#fff"
                                              }}
                                            />
                                          </CopyToClipboard>
                                        </IconButton>
                                      </Tooltip>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                              <InfoArea
                                title="Your PIN"
                                description=""
                                icon={LockOutlined}
                                iconColor="info"
                              />
                              <GridContainer justify="center">
                                <GridItem xs={11} sm={11} md={11}>
                                  <GridContainer justify="center">
                                    <GridItem xs={10} sm={10} md={10}>
                                      <div className={classes.urlbox}>
                                        <span id="generateurl_urlcopy">
                                          <h4
                                            style={{
                                              textAlign: "center",
                                              padding: "5px"
                                            }}
                                          >
                                            {this.state.pin}
                                          </h4>
                                        </span>
                                      </div>
                                    </GridItem>
                                    <GridItem xs={2} md={2}>
                                      <Tooltip
                                        id="generateurl_urlcopy_tooltip"
                                        title={
                                          this.state.pincopied
                                            ? "copied"
                                            : "copy"
                                        }
                                        placement="top"
                                      >
                                        <IconButton
                                          className={classes.viewiconbutton}
                                          id="generateurl_pincopy_iconbutton"
                                          key="gen pin"
                                          aria-label="pin"
                                          color="inherit"
                                        >
                                          <CopyToClipboard
                                            text={this.state.pin}
                                            onCopy={() =>
                                              this.setState({
                                                urlcopied: false,
                                                pincopied: true
                                              })
                                            }
                                          >
                                            <FileCopyOutlined
                                              style={{
                                                color: "#fff"
                                              }}
                                            />
                                          </CopyToClipboard>
                                        </IconButton>
                                      </Tooltip>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify="center">
                                <GridItem xs={10} sm={10} md={11}>
                                  <CustomInput
                                    success={
                                      this.state.emailState === "success"
                                    }
                                    error={this.state.emailState === "error"}
                                    labelText="Authority Email*"
                                    id="Revoke_User_Username"
                                    formControlProps={{
                                      fullWidth: true,
                                      className:
                                        classes.customFormControlClasses
                                    }}
                                    helpText={
                                      this.state.emailState === "error"
                                        ? this.state.emailErrMsg
                                        : null
                                    }
                                    inputProps={{
                                      onChange: event =>
                                        this.genurl_change(
                                          event,
                                          "email",
                                          "email"
                                        ),
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          className={classes.inputAdornment}
                                        >
                                          <Email
                                            className={
                                              classes.inputAdornmentIcon
                                            }
                                          />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <div className={classes.center}>
                                <LoaderButton
                                  id="generateurl_submit_button"
                                  color="info"
                                  disabled={this.isValidated_genurl("email")}
                                  text="submit"
                                  onClick={async () => {
                                    if (!this.isValidated_genurl("email")) {
                                      // query to send email
                                      this.setState({
                                        isLoading: true,
                                        openWarning_generateurl: false
                                      });
                                      await client
                                        .query({
                                          query: Emailquery,
                                          variables: {
                                            username: localStorage.getItem(
                                              "username"
                                            ),
                                            employer_mail: this.state.email,
                                            url: this.state.url,
                                            exp_time: this.state.exp_time
                                          }
                                        })
                                        .then(response => {
                                          this.setState({
                                            isLoading: false
                                          });
                                          this.successAlert_genurl();
                                        })
                                        .catch(err => {
                                          this.setState({
                                            isLoading: false,
                                            openWarning_generateurl: true,
                                            generateurl_errorMessage:
                                              "Email Not Send!!"
                                          });
                                        });
                                    }
                                  }}
                                  isLoading={this.state.isLoading}
                                  loadingText="submitting..."
                                />
                              </div>
                            </div>
                          </form>
                        ) : null}
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            {this.state.openWarning_generateurl ? (
              <WarningAlert
                title="Failed!!"
                confirmBtnText="Try Again"
                errorMessage={this.state.generateurl_errorMessage}
              />
            ) : null}
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

Generateurl.propTypes = {
  classes: propTypes.object.isRequired
};

const urlwithstyle = withStyles(urlStyle)(Generateurl);

export default graphql(Urlmutation)(urlwithstyle);
