import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Link from '@material-ui/icons/Link';
import Lock from '@material-ui/icons/Lock';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
// custom components
import CustomInput from 'Custom-component/CustomInput/CustomInput.jsx';
import InfoArea from 'Custom-component/InfoArea/InfoArea.jsx';
//styles
import step1style from './step1style.jsx';
// File imports
import config from '../../../config.js';
//validations
import validation from '../../Validation/ProfileValidation.jsx';

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        employee_account_username: '',
        employee_account_password: '',
        employee_account_passwordConfirm: ''
      },
      employee_account_usernameState: '',
      employee_account_username: '',
      employee_account_passwordState: '',
      employee_account_passwordErrMsg: '',
      employee_account_passwordConfirmState: '',
      alert: null,
      employee_account_usernameErrMsg: '',
      password_empty: false,
      confirm_passwrod_empty: false,
      checkpassword: '',
      checkpassword1: '',
      hidepassword_confirm: false,
      hidepassword: false
    };
  }
  sendState() {
    return this.state;
  }
  // onchange function for all input fields
  register_step3_change(event, stateName, type) {
    switch (type) {
      case 'username_register':
        var username_register_validation = validation.verifyUsername(
          event.target.value.trim(),
          stateName
        );
        if (!username_register_validation[stateName]) {
          this.setState({ [stateName + 'State']: 'success' });
        } else {
          this.setState({
            [stateName + 'State']: 'error',
            [stateName + 'ErrMsg']: username_register_validation[stateName]
          });
        }
        break;
      case 'password_register':
        if (event.target.value) {
          var registerpassword_validation = validation.password_validate_compare(
            event.target.value,
            stateName,
            this.state.checkpassword
          );
          if (registerpassword_validation === 'error') {
            this.setState({
              [stateName + 'State']: 'error',
              [stateName +
              'ErrMsg']: 'Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8'
            });
          } else {
            if (registerpassword_validation === 'success_compare') {
              this.setState({
                [stateName + 'ConfirmState']: 'success'
              });
            } else if (registerpassword_validation === 'error_compare') {
              this.setState({
                [stateName + 'ConfirmState']: 'error'
              });
            }
            this.setState({
              [stateName + 'State']: 'success',
              checkpassword1: event.target.value
            });
          }
        } else {
          this.setState({ [stateName + 'ErrMsg']: 'Password is required' });
        }
        break;
      case 'equalTo_register':
        if (
          validation.compare_password(
            event.target.value,
            this.state.checkpassword1,
            stateName
          )
        ) {
          this.setState({
            [stateName + 'State']: 'success',
            checkpassword: event.target.value
          });
        } else {
          this.setState({
            [stateName + 'State']: 'error',
            checkpassword: event.target.value
          });
        }
        break;
      default:
        break;
    }
    this.setState({
      account: {
        ...this.state.account,
        [stateName]: event.target.value
      }
    });
  }
  // hide/show password
  hidepassword_register = () => {
    this.setState({ hidepassword: !this.state.hidepassword });
  };
  // hide/show password
  hidepassword_confirm_register = () => {
    this.setState({ hidepassword_confirm: !this.state.hidepassword_confirm });
  };
  //enabling the submit button
  isValidated() {
    if (
      this.state.employee_account_usernameState === 'success' &&
      this.state.employee_account_passwordState === 'success' &&
      this.state.employee_account_passwordConfirmState === 'success'
    ) {
      this.props.getIdentitydata(this.state.account);
      return true;
    } else {
      const { account } = this.state;

      if (account.employee_account_username === '') {
        this.setState({
          employee_account_usernameState: 'error',
          employee_account_usernameErrMsg: 'Username is required'
        });
      }
      if (account.employee_account_password === '') {
        this.setState({
          employee_account_passwordState: 'error',
          password_empty: true,
          employee_account_passwordErrMsg: 'Password is required'
        });
      }
      if (account.employee_account_passwordConfirm === '') {
        this.setState({
          employee_account_passwordConfirmState: 'error',
          confirm_passwrod_empty: true
        });
      }
      return false;
    }
    // return true
  }
  //checking user if already exist
  handleBlur = () => {
    try {
      const userCheck = {
        username: this.state.account.employee_account_username
      };
      var headers = {
        'Content-Type': 'application/json'
      };
      axios
        .post(
          config.cognito.listUser,
          userCheck,
          { crossdomain: true },
          { headers: headers }
        )
        .then(response => {
          if (response.data.data === 'Username already exists') {
            this.setState({
              employee_account_usernameState: 'error',
              employee_account_usernameErrMsg: 'Username already exists'
            });
          }
        })
        .catch(err => {});
    } catch (e) {}
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <h4 className={classes.infoText}>Give your account details</h4>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={5} lg={4}>
            <InfoArea
              title='About Company'
              description="We've created the marketing campaign of the website. It was a very interesting collaboration."
              icon={Link}
              iconColor='info'
            />
            <InfoArea
              title='Requesting for a pin'
              description="We've created the marketing campaign of the website. It was a very interesting collaboration."
              icon={Link}
              iconColor='info'
            />
          </GridItem>
          <GridItem xs={12} sm={5} lg={5}>
            <CustomInput
              success={this.state.employee_account_usernameState === 'success'}
              error={this.state.employee_account_usernameState === 'error'}
              id='Register_employee_account_username'
              name='Register_employee_account_username'
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employee_account_usernameState === 'error'
                  ? this.state.employee_account_usernameErrMsg
                  : null
              }
              inputProps={{
                placeholder: 'Username*',
                onBlur: e => this.handleBlur(),
                onChange: event =>
                  this.register_step3_change(
                    event,
                    'employee_account_username',
                    'username_register',
                    3
                  ),
                startAdornment: (
                  <InputAdornment
                    position='start'
                    className={classes.inputAdornment}
                  >
                    <Person className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              success={this.state.employee_account_passwordState === 'success'}
              error={this.state.employee_account_passwordState === 'error'}
              id='Register_employee_account_password'
              formControlProps={{
                fullWidth: true
              }}
              helpText={
                this.state.employee_account_passwordState === 'error'
                  ? this.state.employee_account_passwordErrMsg
                  : null
              }
              inputProps={{
                placeholder: 'Password*',
                onChange: event =>
                  this.register_step3_change(
                    event,
                    'employee_account_password',
                    'password_register'
                  ),
                type: this.state.hidepassword ? 'text' : 'password',
                startAdornment: (
                  <InputAdornment
                    position='start'
                    className={classes.inputAdornment}
                  >
                    <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    className={classes.inputAdornment}
                  >
                    {this.state.hidepassword ? (
                      <Visibility
                        className={classes.inputAdornmentIcon}
                        style={{ color: '00acc1', cursor: 'pointer' }}
                        onClick={this.hidepassword_register}
                      />
                    ) : (
                      <VisibilityOff
                        className={classes.inputAdornmentIcon}
                        style={{ color: '00acc1', cursor: 'pointer' }}
                        onClick={this.hidepassword_register}
                      />
                    )}
                  </InputAdornment>
                )
              }}
            />
            <CustomInput
              success={
                this.state.employee_account_passwordConfirmState === 'success'
              }
              error={
                this.state.employee_account_passwordConfirmState === 'error'
              }
              id='Register_employee_account_confirmpassword'
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
              }}
              helpText={
                this.state.employee_account_passwordConfirmState === 'error'
                  ? 'Please provide the same password'
                  : null
              }
              inputProps={{
                placeholder: 'Confirm Password*',
                onChange: event =>
                  this.register_step3_change(
                    event,
                    'employee_account_passwordConfirm',
                    'equalTo_register',
                    'employee_account_password'
                  ),
                type: this.state.hidepassword_confirm ? 'text' : 'password',
                startAdornment: (
                  <InputAdornment
                    position='start'
                    className={classes.inputAdornment}
                  >
                    <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    className={classes.inputAdornment}
                  >
                    {this.state.hidepassword_confirm ? (
                      <Visibility
                        className={classes.inputAdornmentIcon}
                        style={{ color: '00acc1', cursor: 'pointer' }}
                        onClick={this.hidepassword_confirm_register}
                      />
                    ) : (
                      <VisibilityOff
                        className={classes.inputAdornmentIcon}
                        style={{ color: '00acc1', cursor: 'pointer' }}
                        onClick={this.hidepassword_confirm_register}
                      />
                    )}
                  </InputAdornment>
                )
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// connect this component to redux store
const mapStateToProps = state => {
  return {
    employee: state.employee
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getIdentitydata: account => {
      dispatch({
        type: 'GET_IDENTITY_DATA',
        payload: account
      });
    }
  };
};
const withstyleidentity = withStyles(step1style)(Step3);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withstyleidentity);
