import gql from 'graphql-tag'

const UnverifiedemployerQuery = gql`
  query commentandemployer($username: String!, $file_hash: String!) {
    getEmployment(username: $username, file_hash: $file_hash) {
      username
      file_hash
    }
    getComments(username: $username, file_hash: $file_hash) {
      comments {
        file_hash
        comment
        added_by
        added_at
        __typename
      }
    }
    getOtherEmployer(username: $username, file_hash: $file_hash) {
      otherEmployers {
        file_hash
        other_employer_contact_name
        other_employer_mailid
        other_employer_organization_name
        added_by
        __typename
      }
    }
  }
`
export default UnverifiedemployerQuery
