import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
//material ui components
import withStyles from "@material-ui/core/styles/withStyles";
//styles
import userProfileStyles from "./CustomStyles/profilepagestyle";
//file imports
import history from "../history.js";
class WarningAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: ""
    };
  }
  // close failure alert
  closeAlert = () => {
    this.setState({
      alert: null
    });
    if (this.props.aadharclose) {
      localStorage.clear();
      history.push("/auth");
    }
  };
  render() {
    return (
      <div>
        {this.state.alert !== null ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px", color: "#000000" }}
            title={this.props.title}
            onConfirm={() => this.closeAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.confirmBtnText}
          >
            {this.props.errorMessage}
          </SweetAlert>
        ) : null}
        {this.state.alert}
      </div>
    );
  }
}

export default withStyles(userProfileStyles)(WarningAlert);
