import React, { Component } from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// Custom component
import Button from 'Custom-component/CustomButtons/Button.jsx';
import InfoArea from 'Custom-component/InfoArea/InfoArea.jsx';
// @material-ui icons
import Code from '@material-ui/icons/Code';
// core components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
// file import
import history from '../history.js';
//styles
import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

class DataNotFound extends Component {
  render() {
    const { classes, button, title, buttontext, link } = this.props;
    return (
      <div>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={10} md={8}>
            <Card className={classes.cardSignup}>
              <h3 className={classes.cardTitle}>
                <b>{title}</b>
              </h3>
              <CardBody>
                <GridContainer justify='center'>
                  <GridItem xs={11} sm={12} md={10}>
                    {/* <h3 style={{ textAlign: "center" }}>{message}</h3> */}
                    <InfoArea
                      title='Step 1'
                      description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.'
                      icon={Code}
                      iconColor='info'
                    />
                    <InfoArea
                      title='Step 2'
                      description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.'
                      icon={Code}
                      iconColor='info'
                    />
                    <InfoArea
                      title='Step 3'
                      description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.'
                      icon={Code}
                      iconColor='info'
                    />
                  </GridItem>
                </GridContainer>
                {button ? (
                  <GridContainer justify='center'>
                    <Button
                      color='info'
                      style={{ textAlign: 'center' }}
                      onClick={() => history.push(link)}
                    >
                      {buttontext}
                    </Button>
                  </GridContainer>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(registerPageStyle)(DataNotFound);
