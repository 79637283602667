import gql from 'graphql-tag'

const Urlmutation = gql`
  mutation UrlMutation(
    $created_by: String!
    $file_hashes: String!
    $exp_time: Date!
    $ip: String!
    $browser: String!
    $version: String!
    $device_id: String!
  ) {
    urlInsert(
      created_by: $created_by
      file_hashes: $file_hashes
      exp_time: $exp_time
      ip: $ip
      browser: $browser
      version: $version
      device_id: $device_id
    ) {
      url_address
      pin
      created_by
      exp_time
      __typename
    }
    __typename
  }
`
export default Urlmutation
