import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";

import { Redirect } from "react-router-dom";

// file imports
import Dashboardpage from "./Dashboardpage.jsx";
import Errorpage from "../Pages/errorPage";
import Newuserpage from "../Pages/newuserpage.jsx";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

//queries
import Documentquery from "../../graphql/documentQuery";

// retrieve document counts uploaded by employee - Dashboard query
const Dashboardquery = ({
  username = localStorage.getItem("username"),
  client
}) => (
  <Query query={Documentquery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingScreenPage />;
      }
      if (error) {
        return (
          <div>
            <Errorpage />
          </div>
        );
      }
      if (data.getMulEmployment.Employment !== null) {
        localStorage.setItem("documentupload", 0);
      } else {
        localStorage.setItem("documentupload", 1);
      }
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : data.getMulEmployment.Employment === null ? (
        <div>
          <Newuserpage client={client} />
        </div>
      ) : (
        // redirect to dashboard page
        <div>
          <Dashboardpage key={data.getEmployee.username} document={data} />
        </div>
      );
    }}
  </Query>
);

class Dashboard extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <Dashboardquery history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default Dashboard;
