import gql from "graphql-tag";

const urlquery = gql`
  query DocumentQuery($username: String!) {
    getEmployee(username: $username) {
      username
      __typename
    }
    getMulEmployment(username: $username) {
      Employment {
        username
        document_name
        file_id
        file_hash
        document_extension
        document_upload_type
        employee_id
        employer_name
        employer_mail_id
        organization_name
        joining_date
        end_date
        experience
        designation
        department
        uploaded_by
        uploaded_at
        modified_at
        verified_status
        __typename
      }
    }

    getApprovedCount(username: $username) {
      count
      __typename
    }
    getPendingCount(username: $username) {
      count
      __typename
    }
    getRejectedCount(username: $username) {
      count
      __typename
    }
    getTotalCount(username: $username) {
      count
      __typename
    }
    getAllExpTime(username: $username) {
      time {
        type
        created_at
        created_by
        modified_at
        __typename
      }
      __typename
    }
    __typename
  }
`;
export default urlquery;
