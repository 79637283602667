import gql from 'graphql-tag';

const profileverificationMutation = gql`
  mutation onboardQuery(
    $username: String!
    $file_hash: String!
    $response: String!
  ) {
    employmentStaging(
      username: $username
      file_hash: $file_hash
      response: $response
    ) {
      message
    }
  }
`;

export default profileverificationMutation;
