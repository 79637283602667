import {
  warningColor,
  dangerColor,
  successColor
} from 'assets/jss/material-dashboard-pro-react.jsx';
import buttonStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import userProfileStyles from '../CustomStyles/profilepagestyle';
import modalStyle from '../../assets/jss/material-dashboard-pro-react/modalStyle.jsx';

const profilerequestStyle = theme => ({
  ...userProfileStyles,
  ...buttonStyle,
  ...modalStyle(theme),
  //for approve icon
  profileiconbuttonApprove: {
    color: successColor,
    padding: '0px',
    backgroundColor: '#ffffff00'
  },
  //for pending icon
  profileiconbuttonPending: {
    color: warningColor,
    padding: '0px',
    backgroundColor: '#ffffff00'
  },
  //for reject icon
  profileiconbuttonReject: {
    color: dangerColor,
    padding: '0px',
    backgroundColor: '#ffffff00'
  },
  form: {
    padding: '0 20px',
    position: 'relative'
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#3C4858'
    }
  },
  cssFocused: {},
  underline: {
    '&:after': {
      borderBottom: '2px solid #ffc90d'
    }
  },
  title: {
    color: '#3C4858',

    textDecoration: 'none',
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  labelalign: {
    float: 'left'
  }
});
export default profilerequestStyle;
