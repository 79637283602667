import React, { Suspense } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MUIDataTable from "mui-datatables";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider } from "@material-ui/core/styles";
// material ui icons
import Assignment from "@material-ui/icons/Assignment";
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// file imports
import config from "../../config";
import LoadingScreenPage from "../LoadingScreenPage.jsx";
import Errorpage from "../Pages/errorPage";
//styles
import documentStyle from "./documentStyle.jsx";
import Mytheme from "../Muitheme_Modified.jsx";
//queries
import UnverifiedemployerQuery from "../../graphql/unverifiedemployerQuery.js";
import BlockchaindetailsQuery from "../../graphql/blockchaindetailsQuery.js";
//file imports
const EditDocument = React.lazy(() => import("./EditDocument.jsx"));
const ViewDocument = React.lazy(() => import("./ViewDocument.jsx"));
const AddDocument = React.lazy(() => import("./AddDocument.jsx"));
// Date formater
var dateFormat = require("dateformat");
//for downloading files
var mime = require("mime-types");
var b64toBlob = require("b64-to-blob");

class EmploymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingalert: null,
      loadingFailAlert: null,
      successalert: false,
      failalert: false,
      message: "",
      documentList_Data: [],
      show_documents_list: this.props.user.Employment !== null ? true : false,
      showEditpage: false,
      showViewpage: false,
      showAdd: this.props.user.Employment === null ? true : false,
      viewDetails: "",
      EditDetails: "",
      comments: "",
      document_requested_status:
        !this.props.docStatus || this.props.docStatus === "All"
          ? ["Pending", "Approved", "Rejected"]
          : [this.props.docStatus],
      blockchaindetails: "",
      hashError: false
    };
  }
  //back click for all back buttons in document page
  handleBack_doc = () => {
    this.setState({
      show_documents_list: true,
      showEditpage: false,
      showViewpage: false,
      showAdd: false
    });
  };
  //onclick function for add button
  addDocumentClick = () => {
    this.setState({ showAdd: true, show_documents_list: false });
  };
  //click back when uploading documents
  uplodClose = data => {
    if (data) {
      this.warningWithConfirmMessage(data);
    } else {
      this.setState({
        show_documents_list: true,
        showEditpage: false,
        showViewpage: false,
        showAdd: false
      });
    }
  };
  //failure alert
  warningWithConfirmMessage(file_id) {
    this.setState({
      loadingalert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure want to exit?"
          onConfirm={() => this.successDelete(file_id)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.info
          }
          cancelBtnCssClass={this.props.classes.button}
          confirmBtnText="YES"
          cancelBtnText="CANCEL"
          showCancel
        />
      )
    });
  }
  //delete the file
  successDelete(file_id) {
    try {
      const removeData = {
        hasedFilename: file_id,
        userName: localStorage.getItem("username")
      };
      var headers = {
        Authorization: localStorage.getItem("accessToken")
      };
      axios
        .post(config.s3.s3Delete, removeData, { headers: headers })
        .then(response => {
          if (
            response.data.message === "File is deleted successfully from s3"
          ) {
            this.setState({
              loadingalert: null
            });
            this.setState({
              show_documents_list: true,
              showEditpage: false,
              showViewpage: false,
              showAdd: false
            });
          } else {
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            localStorage.clear();
            this.props.history.push("/auth/login");
          }
        });
    } catch (e) {}
  }
  //close the alert
  hideAlert() {
    this.setState({
      loadingalert: null
    });
  }
  componentDidMount() {
    this.generateTable(this.props.user.Employment);
  }
  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }
  //function to download user uploded document
  handleDocumentDownload = (
    employee_file_id,
    employee_document_extension,
    employee_document_name,
    employee_username
  ) => {
    try {
      const retrieveData = {
        userName: employee_username,
        hasedFilename: employee_file_id
      };
      var headers = {
        Authorization: localStorage.getItem("accessToken")
      };
      axios
        .post(config.s3.s3Download, retrieveData, { headers: headers })
        .then(response => {
          if (response.data.fileContent) {
            var mime_type = mime.lookup(employee_document_extension);
            var link = document.createElement("a");
            link.download =
              "Verify Mate_" +
              employee_username +
              "_" +
              employee_document_name +
              "_" +
              Date.now() +
              "." +
              employee_document_extension;

            link.href =
              "data:" + mime_type + ";base64," + response.data.fileContent;
            // for edge browser
            if (window.navigator.msSaveOrOpenBlob) {
              var blob = b64toBlob(response.data.fileContent, mime_type);
              window.navigator.msSaveOrOpenBlob(blob, link.download);
            } else {
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          } // for other browsers
          else {
            this.setState({
              filedownloaderrorMessage:
                "Unable to download the file.Please try after some time",
              tr: true
            });
            this.setState({ isLoading: false });
          }
        })
        .catch(err => {
          this.setState({
            filedownloaderrorMessage:
              "Unable to download the file.Please try after some time",
            tr: true
          });
          this.setState({ isLoading: false });
        });
    } catch (e) {
      this.setState({
        filedownloaderrorMessage:
          "Unable to download the file.Please try after some time",
        tr: true
      });
      this.setState({ isLoading: false });
    }
  };
  componentDidUpdate(props, state) {
    if (props.user.Employment !== null) {
      if (
        Object.keys(props.user.Employment).length === 1 &&
        state.documentList_Data.length === 0
      ) {
        localStorage.setItem("documentupload", 0);
        this.generateTable(props.user.Employment);
      }
    }
    if (state.documentList_Data.length) {
      var document = props.user.Employment;
      var changed = false;
      if (Object.keys(document).length !== state.documentList_Data.length) {
        changed = true;
      } else {
        Object.keys(document).forEach(function(key) {
          var endDate = dateFormat(
            new Date(document[key].end_date),
            "dd/mm/yyyy"
          );
          if (
            document[key].end_date !== null &&
            endDate !== state.documentList_Data[key][4]
          ) {
            changed = true;
          }
        });
      }
      if (changed) {
        this.generateTable(props.user.Employment);
      }
    }
  }
  generateTable = Employement => {
    var documentList_Data = [];
    var self = this;
    if (this.state.show_documents_list) {
      const { classes, client } = this.props;
      var document_list = Employement;
      Object.keys(document_list).forEach(function(key) {
        documentList_Data.push([
          document_list[key].organization_name,
          !document_list[key].document_name
            ? "-"
            : document_list[key].document_name,
          document_list[key].verified_status,
          dateFormat(new Date(document_list[key].joining_date), "dd/mm/yyyy"),
          !document_list[key].end_date
            ? "-"
            : dateFormat(new Date(document_list[key].end_date), "dd/mm/yyyy"),
          !document_list[key].transaction_id
            ? "-"
            : document_list[key].transaction_id,
          <div>
            {document_list[key].verified_status === "Approved" ? (
              <div style={{ color: "green" }}>Approved</div>
            ) : (
              <div>
                {document_list[key].verified_status === "Pending" ? (
                  <div style={{ color: "#ff9800" }}>Pending</div>
                ) : (
                  <div style={{ color: "red" }}>Rejected</div>
                )}
              </div>
            )}
          </div>,
          <div className="actions-right">
            <IconButton
              aria-label="View"
              className={classes.profileiconbutton}
              onClick={async () => {
                self.setState({
                  show_documents_list: false,
                  loadingalert: <LoadingScreenPage />
                });
                await client
                  .query({
                    query: UnverifiedemployerQuery,
                    variables: {
                      username: localStorage.getItem("username"),
                      file_hash: document_list[key].file_hash
                    }
                  })
                  .then(response => {
                    client
                      .query({
                        query: BlockchaindetailsQuery,
                        variables: {
                          file_hash: document_list[key].file_hash
                        }
                      })
                      .then(res => {
                        self.setState({
                          blockchaindetails: res,
                          loadingalert: null,
                          viewDetails: document_list[key],
                          comments:
                            response.data.getComments.comments === null
                              ? "No Comments for your document"
                              : response.data.getComments.comments[0].comment,
                          showEditpage: false,
                          showViewpage: true,
                          show_documents_list: false
                        });
                      })
                      .catch(err => {
                        self.setState({
                          loadingFailAlert: true,
                          loadingalert: null,
                          show_documents_list: true
                        });
                      });
                  })
                  .catch(err => {
                    self.setState({
                      loadingFailAlert: true,
                      loadingalert: null,
                      show_documents_list: true
                    });
                  });
              }}
            >
              <Tooltip title="View">
                <Icon>visibility</Icon>
              </Tooltip>
            </IconButton>
            &nbsp; &nbsp;
            <IconButton
              aria-label="Edit"
              className={classes.profileiconbuttonPending}
              disabled={
                document_list[key].verified_status === "Approved" &&
                !document_list[key].end_date
                  ? false
                  : true
              }
              onClick={async () => {
                self.setState({
                  loadingalert: <LoadingScreenPage />,
                  show_documents_list: false
                });
                await client
                  .query({
                    query: UnverifiedemployerQuery,
                    variables: {
                      username: localStorage.getItem("username"),
                      file_hash: document_list[key].file_hash
                    }
                  })
                  .then(response => {
                    self.setState({
                      loadingalert: null,
                      comments:
                        response.data.getComments.comments === null
                          ? "No Comments for your document"
                          : response.data.getComments.comments[0].comment,
                      EditDetails: document_list[key],
                      showEditpage: true,
                      showViewpage: false,
                      show_documents_list: false
                    });
                  })
                  .catch(err => {
                    self.setState({
                      show_documents_list: true,
                      loadingFailAlert: true,
                      loadingalert: null
                    });
                  });
              }}
            >
              <Tooltip title="Edit">
                <Icon
                  style={
                    document_list[key].verified_status === "Approved" &&
                    !document_list[key].end_date
                      ? null
                      : { color: "#b7b3ae" }
                  }
                >
                  edit
                </Icon>
              </Tooltip>
            </IconButton>
            &nbsp; &nbsp;
          </div>
        ]);
      });
      self.setState({
        documentList_Data: documentList_Data
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { hashError } = this.state;
    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      selectableRows: false,
      page: this.state.page
    };
    const columns = [
      {
        name: "Organization",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Document Name",
        options: {
          filter: true,
          sort: true,
          display: false
        }
      },
      {
        name: "Document Status",
        options: {
          filter: true,
          sort: true,
          display: false,
          filterList:
            this.state.document_requested_status === undefined
              ? ["Pending", "Approved", "Rejected"]
              : this.state.document_requested_status
        }
      },
      {
        name: "Join Date",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Exit Date",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Transaction Id",
        options: {
          filter: true,
          sort: true,
          display: false
        }
      },
      {
        name: "Status",
        options: {
          filter: false,
          sort: false,
          download: false
        }
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          download: false
        }
      }
    ];
    if (hashError) {
      return (
        <div>
          <Errorpage />
        </div>
      );
    } else {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} lg={12}>
            <Snackbar
              place="bc"
              color="danger"
              icon={AddAlert}
              message={this.state.failalert ? <b>{this.state.message}</b> : ""}
              open={this.state.failalert}
              closeNotification={() => this.setState({ failalert: false })}
              close
            />
            <Snackbar
              place="bc"
              color="info"
              icon={AddAlert}
              message={
                this.state.successalert ? <b>{this.state.message}</b> : ""
              }
              open={this.state.successalert}
              closeNotification={() => this.setState({ successalert: false })}
              close
            />
            {this.state.loadingalert}
            {/* sweet alert for failure */}
            {this.state.loadingFailAlert ? (
              <SweetAlert
                style={{
                  display: "block",
                  marginTop: "-100px",
                  color: "#000000"
                }}
                title="Failed to fetch."
                onConfirm={() => this.setState({ loadingFailAlert: null })}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                showConfirm={true}
              >
                <h4>Please try Again.</h4>
              </SweetAlert>
            ) : null}
            {this.state.show_documents_list ? (
              <div>
                <GridContainer
                  justify="flex-end"
                  style={{ paddingRight: "18px" }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="Add"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      className={classes.blockiconbutton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.addDocumentClick}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  </Tooltip>
                </GridContainer>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      Employment Details
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <MuiThemeProvider theme={Mytheme}>
                      <MUIDataTable
                        title={""}
                        data={this.state.documentList_Data}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </CardBody>
                </Card>
              </div>
            ) : null}
            {this.state.showEditpage ? (
              <Suspense fallback={<LoadingScreenPage />}>
                <EditDocument
                  handleBack_doc={this.handleBack_doc}
                  EditDetails={this.state.EditDetails}
                  comments={this.state.comments}
                  client={this.props.client}
                  handleDocumentDownload={this.handleDocumentDownload}
                />
              </Suspense>
            ) : null}
            {this.state.showViewpage ? (
              <Suspense fallback={<LoadingScreenPage />}>
                <ViewDocument
                  blockchaindetails={this.state.blockchaindetails}
                  viewDetails={this.state.viewDetails}
                  handleBack_doc={this.handleBack_doc}
                  comments={this.state.comments}
                  handleDocumentDownload={this.handleDocumentDownload}
                />
              </Suspense>
            ) : null}
            {this.state.showAdd ? (
              <Suspense fallback={<LoadingScreenPage />}>
                <AddDocument
                  showBack={this.props.user.Employment === null ? false : true}
                  handleBack_doc={this.uplodClose}
                  client={this.props.client}
                />{" "}
              </Suspense>
            ) : null}
          </GridItem>
        </GridContainer>
      );
    }
  }
}

export default withStyles(documentStyle)(EmploymentDetails);
