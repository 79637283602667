// ##############################
// // // RegisterPage view styles
// #############################

const errorPagestyle = {
  container: {
    position: 'relative',
    zIndex: '3',
    paddingTop: '15px',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'auto',
    width: '100%',
    '&:before,&:after': {
      display: 'table',
      content: '" "'
    },
    '&:after': {
      clear: 'both'
    }
  },
  center: {
    textAlign: 'center'
  }
};

export default errorPagestyle;
