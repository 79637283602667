import React, { Component } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
// images
import ComingSoon from "assets/img/ComingSoon.png";
//styles
import "../Help/helpStlyle.css";

//customly edited components
class Feedback extends Component {
  render() {
    return (
      <div>
        <GridContainer justify="center">
          <div className="row">
            <div className="cell">
              <img src={ComingSoon} alt="..." className="img" />
            </div>
          </div>
        </GridContainer>
      </div>
    );
  }
}

export default Feedback;
