import gql from 'graphql-tag';

const StagingQuery = gql`
  query stagingQuery($username: String!, $file_hash: String!) {
    showStagingFile(username: $username, file_hash: $file_hash) {
      employee_id
      file_hash
      uploaded_by
      employer_name
      employer_mail_id
      organization_name
      joining_date
      end_date
      department
      designation
      is_verified_employer
      document_upload_type
      employement_status
      experience
      verified_status
      approved_by
      approver_email
    }
  }
`;
export default StagingQuery;
