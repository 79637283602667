import {
  cardTitle,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const userProfileStyles = {
  cardTitle,
  ...customCheckboxRadioSwitch,
  ...sweetAlertStyle,
  ...modalStyle,
  ...buttonStyle,
  checkboxAndRadioHorizontal: {
    position: "relative",
    display: "block",
    "&:first-child": {
      marginTop: "15px"
    },
    "&:not(:first-child)": {
      marginTop: "-14px"
    },
    "@media (max-width: 300px)": {
      marginTop: "10px "
    },
    marginTop: "40px",
    marginBottom: "0"
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: "20px important",
    float: "left !important"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardSignup: {
    borderRadius: "6px",
    padding: "20px 0px",
    fontWeight: "400 !important"
  },
  cardBody: {
    textAlign: "left",
    fontSize: "0.9em"
  },
  cardheader: {
    textAlign: "left",
    fontWeight: "400",
    marginLeft: "20px",
    fontSize: "1.3em",
    color: "#635a5a"
  },
  cardsideHeader: {
    color: "#4a4a4a",
    fontWeight: "300"
  },
  cardsideHeaderedit: {
    color: "#989595",
    fontWeight: "300",
    fontSize: "1.2em"
  },
  inputlabelheader: {
    color: "#989595",
    fontWeight: "300",
    fontSize: "1em"
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center"
  },
  description: {
    color: "#999999"
  },
  updateProfileButton: {
    float: "right"
  },
  backProfileButton: {
    float: "left"
  },
  inputAdornment: {
    marginRight: "0px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  editbuttonStyle: {
    backgroundColor: "#fff",
    color: "#000",
    border: "none"
  },
  editiconStyle: {
    fontSize: "1.5em",
    cursor: "pointer"
  },
  savebuttonStyle: {
    cursor: "pointer",
    backgroundColor: infoColor,
    border: "1px solid #ffc90d",
    width: "80px",
    marginRight: "5px",
    borderRadius: "15px"
  },
  cancelbuttonStyle: {
    cursor: "pointer",
    backgroundColor: infoColor,
    color: "red"
  },
  approvestatusicon: {
    color: successColor,
    fontSize: "3em"
  },
  rejectstatusicon: {
    color: dangerColor,
    fontSize: "3em"
  },
  pendingstatusicon: {
    color: warningColor,
    fontSize: "3em"
  },
  commentvalue: {
    display: "flex",
    textAlign: "justify"
  },
  modalCloseButton: {
    color: "#999999",
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right"
  },
  radio: {
    color: infoColor + "!important"
  },
  modalClose: {
    width: "60px !important",
    height: "16px"
  },
  //for back icon
  backiconarrow: {
    float: "right",
    color: "#ffffff",
    backgroundColor: "#ffc90d",
    "&:hover ,&:focus": {
      color: "black"
    }
  },
  cssLabel: {
    "&$cssFocused": {
      color: "#3C4858"
    }
  },
  cssFocused: {},
  underline: {
    "&:after": {
      borderBottom: "2px solid #ffc90d"
    }
  },
  //for view icon
  profileiconbutton: {
    color: infoColor,
    padding: "0px",
    backgroundColor: "#ffffff00"
  },
  //for approve icon
  profileiconbuttonApprove: {
    color: successColor,
    padding: "0px",
    backgroundColor: "#ffffff00"
  },
  //for pending icon
  profileiconbuttonPending: {
    color: warningColor,
    padding: "0px",
    backgroundColor: "#ffffff00"
  },
  //for reject icon
  profileiconbuttonReject: {
    color: dangerColor,
    padding: "0px",
    backgroundColor: "#ffffff00"
  },
  //for add document icon
  blockiconbutton: {
    color: "#fff",
    backgroundColor: infoColor
  },
  viewiconbutton: {
    color: "#ffffff",
    backgroundColor: infoColor,
    "&:hover ,&:focus": {
      color: "black"
    }
  },
  uniqueid_text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",
    paddingLeft: "14px"
  }
};
export default userProfileStyles;
