import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import buttonStyle from "../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";

const step1style = {
  ...sweetAlertStyle,
  ...buttonStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#ffc90d !important"
  },
  inputAdornment: {
    position: "relative"
  },
  iconCheckboxIcon: {
    fontSize: "100px !important",
    lineHeight: "111px",
    marginTop: "15px !important",
    textAlign: "center !important"
  }
};

export default step1style;
