import gql from 'graphql-tag';

const EmployeeprofileQuery = gql`
  query EmployeeQuery($username: String!) {
    getEmployee(username: $username) {
      username
      name
      aadhar_number
      aadhar_verified
      email
      email_verified
      phone_number
      company_name
      designation
      department
      bucket_id
      hyperledger_id
      gender
      birthdate
      address
      profile_status
      reason
      created_at
      created_by
      updated_at
      updated_by
      profile_image_hash
      __typename
    }
    __typename
  }
`;
export default EmployeeprofileQuery;
