import gql from 'graphql-tag'

const Getnotification = gql`
  query NotificationQuery($username: String!) {
    getNotification(username: $username) {
      notification {
        id
        username
        message
        action_url
        status
      }
      __typename
    }
    __typename
  }
`

export default Getnotification
