import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
//@material-ui/icons
import Icon from '@material-ui/core/Icon';
//core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
// custom components
import LoaderButton from '../../Custom-component/LoaderButton';
// file imports
import WarningAlert from '../WarningAlert.jsx';
import ErrorPage from '../Pages/errorPage.jsx';
//styles
import documentStyle from './documentRequestStyle.jsx';
// graphql queries
import { graphql } from 'react-apollo';
import OnboardQuery from '../../graphql/onboardingQuery.js';
import EmployeeQuery from '../../graphql/employeequery.js';
// Date formater
var dateFormat = require('dateformat');

class DocumentRequest extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    const { requestDetails } = this.props;
    this.state = {
      join_date: dateFormat(
        new Date(requestDetails.joining_date),
        'dd/mm/yyyy'
      ),
      exit_date: requestDetails.end_date
        ? dateFormat(new Date(requestDetails.end_date), 'dd/mm/yyyy')
        : '-',
      experience: requestDetails.experience ? requestDetails.experience : '-',
      hasError: false,
      alert: null,
      approveLoading: false,
      rejectLoading: false,
      openwarning_docreq: false
    };
  }
  // for error handling
  componentDidCatch() {
    this.setState({ hasError: true });
  }
  // onclick function for submit function
  handleSubmit_docreq = async status => {
    try {
      this.setState({ openwarning_docreq: false });
      await this.props
        .mutate({
          variables: {
            username: localStorage.getItem('username'),
            file_hash: this.props.requestDetails.file_hash,
            response: status,
            ip: localStorage.getItem('ip'),
            browser: localStorage.getItem('browserName'),
            version: localStorage.getItem('browserVersion'),
            device_id: localStorage.getItem('deviceId')
          },
          refetchQueries: [
            {
              query: EmployeeQuery,
              variables: { username: localStorage.getItem('username') }
            }
          ]
        })
        .then(response => {
          this.setState({ approveLoading: false, rejectLoading: false });
          this.successAlert_docreq(status);
        })
        .catch(err => {
          this.setState({
            approveLoading: false,
            rejectLoading: false,
            openwarning_docreq: true
          });
        });
    } catch (e) {
      this.setState({
        approveLoading: false,
        rejectLoading: false,
        openwarning_docreq: true
      });
    }
  };
  // successAlert_docreq message
  successAlert_docreq = type => {
    this.setState({
      alert: (
        <SweetAlert
          id='document_request'
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={
            type === 'Approved'
              ? 'Request has been accepted'
              : 'Request has been rejected'
          }
          onConfirm={() => this.successhideAlert_docreq(type)}
          showConfirm={true}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
        >
          <h4>Ask your employer to check</h4>
        </SweetAlert>
      )
    });
  };
  //success alert time duration
  successhideAlert_docreq = type => {
    this.setState({
      alert: null
    });
    if (type === 'Approved') {
      this.props.history.push('/document');
    } else {
      this.props.history.push('/dashboard');
    }
  };
  render() {
    const { classes, requestDetails } = this.props;
    // if error occurs
    if (this.state.hasError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <div>
          {this.state.alert}
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={11}>
              <Card className={classes.cardShadow}>
                <CardHeader color='info' icon>
                  <CardIcon color='info'>
                    <Icon>description</Icon>
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Document Request</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer justify='center'>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer justify='center'>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card className={classes.cardSignup}>
                            <h4 className={classes.center}>
                              <b> Employee details</b>
                            </h4>
                            <CardBody className={classes.cardBody}>
                              <GridContainer justify='center'>
                                <GridItem xs={9} sm={3} md={2}>
                                  <h4>
                                    <b>Employee Id</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>
                                    <b>:</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={2}>
                                  <h4>{requestDetails.employee_id}</h4>
                                </GridItem>
                                <GridItem xs={9} sm={3} md={2}>
                                  <h4>
                                    <b>Department</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>
                                    <b>:</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={2}>
                                  <h4>{requestDetails.department}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify='center'>
                                <GridItem xs={9} sm={3} md={2}>
                                  <h4>
                                    <b>Designation</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>
                                    <b>:</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={2}>
                                  <h4>{requestDetails.designation}</h4>
                                </GridItem>
                                <GridItem xs={9} sm={3} md={2}>
                                  <h4>
                                    <b> Joining Date</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>
                                    <b>:</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={2}>
                                  <h4>{this.state.join_date}</h4>
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify='center'>
                                <GridItem xs={9} sm={3} md={2}>
                                  <h4>
                                    <b> Exit Date</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>
                                    <b>:</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={2}>
                                  <h4>{this.state.exit_date}</h4>
                                </GridItem>
                                <GridItem xs={9} sm={3} md={2}>
                                  <h4>
                                    <b>Experience</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={1} sm={1} md={1}>
                                  <h4>
                                    <b>:</b>
                                  </h4>
                                </GridItem>
                                <GridItem xs={10} sm={7} md={2}>
                                  <h4>{this.state.experience}</h4>
                                </GridItem>
                              </GridContainer>

                              <GridContainer justify='center'>
                                <GridItem xs={11} sm={12} md={11}>
                                  <Card className={classes.cardSignup}>
                                    <CardBody className={classes.cardBody}>
                                      <GridContainer justify='center'>
                                        <h4>
                                          You have been onboard by{' '}
                                          {requestDetails.organization_name}.
                                          Please confirm to add this to your
                                          profile
                                        </h4>
                                      </GridContainer>
                                    </CardBody>
                                    <CardFooter>
                                      <GridContainer justify='center'>
                                        <LoaderButton
                                          round
                                          color={'info'}
                                          onClick={() => {
                                            this.setState({
                                              approveLoading: true
                                            });
                                            this.handleSubmit_docreq(
                                              'Approved'
                                            );
                                          }}
                                          text='Approve'
                                          isLoading={this.state.approveLoading}
                                          loadingText='Approving...'
                                        />
                                        &nbsp; &nbsp;
                                        <LoaderButton
                                          round
                                          color={'danger'}
                                          onClick={() => {
                                            this.setState({
                                              rejectLoading: true
                                            });
                                            this.handleSubmit_docreq(
                                              'Rejected'
                                            );
                                          }}
                                          text='Reject'
                                          loadingText='Rejecting...'
                                          isLoading={this.state.rejectLoading}
                                        />
                                      </GridContainer>
                                    </CardFooter>
                                  </Card>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>

                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {this.state.openwarning_docreq ? (
            <WarningAlert
              confirmBtnText='Try Again'
              errorMessage='Failed to upload'
              title='Response Not Successful'
            />
          ) : null}
        </div>
      );
    }
  }
}

const DocumentRequestwithstyle = withStyles(documentStyle)(DocumentRequest);
export default graphql(OnboardQuery)(DocumentRequestwithstyle);
