import { infoColor } from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import userProfileStyles from "../CustomStyles/profilepagestyle";

const urlStyle = theme => ({
  ...userProfileStyles,
  ...buttonStyle,
  card: {
    maxWidth: 345,
    boxShadow:
      "0 12px 20px -10px rgba(255, 201, 13, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 201, 13, 0.2)"
  },
  cardIconTitle: {
    color: "#000 !important",
    marginTop: "10px",
    marginBottom: "0px"
  },
  center: {
    textAlign: "center !important",
    paddingTop: "10px"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  viewiconbutton: {
    color: "#ffffff",
    backgroundColor: infoColor,
    "&:hover ,&:focus": {
      color: "black"
    }
  },
  urlbox: {
    border: "1px solid #ffc90d",
    borderRadius: "7px",
    height: "60px"
  },
  dashboardrowContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "stretch",
    "@media (min-width: 900px)": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  statscard: {
    flex: 1,
    marginRight: "30px",
    flexBasis: "20%",
    "@media (max-width: 1200px)": {
      flexBasis: "40%"
    },
    "@media (max-width: 600px)": {
      flexBasis: "90%"
    }
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  title: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "300",
    marginTop: "30px",
    marginBottom: "25px",
    minHeight: "32px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitle: {
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "3px",
    minHeight: "auto",
    "& a": {
      marginTop: ".625rem",
      marginBottom: "0.75rem",
      minHeight: "auto"
    }
  },
  infoArea: {
    maxWidth: "560px !important",
    margin: "0 auto",
    padding: "0px"
  },
  description: {
    color: "#999999",
    fontSize: "14px",
    marginTop: "0px",
    width: "60%"
  },
  successicons: {
    color: "green",
    fontSize: "4em"
  },
  rejecticons: {
    color: "red",
    fontSize: "4em"
  },
  cardStyle: {
    padding: "15px"
  },
  headingStyle: {
    color: "#3C4858",
    fontSize: "18px",
    fontWeight: 300
  },
  subheadingStyle: {
    color: "#999999"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
});
export default urlStyle;
