import gql from "graphql-tag";

const BlockchaindetailsQuery = gql`
  query historyquery($file_hash: String!) {
    getDocumentHistory(file_hash: $file_hash) {
      history {
        employer_username
        employer_name
        employee_username
        employee_name
        file_hash
        organization_name
        employee_id
        designation
        department
        transaction_id
        transaction_details
        joining_date
        end_date
        action
        added_on
      }
    }
  }
`;
export default BlockchaindetailsQuery;
