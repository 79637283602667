import React from 'react';
// material ui icons
import BusinessCenter from '@material-ui/icons/BusinessCenter';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Heading from 'components/Heading/Heading.jsx';
import Timeline from 'components/Timeline/Timeline.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import DataNotFound from '../DataNotFound';
// Date formater
var dateFormat = require('dateformat');

class History extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history_details: [] // array to push approved document
    };
  }
  componentDidMount() {
    var history_array = [];
    // Approved documents pushed into array to display history
    this.props.user.history.map((prop, key) => {
      if (prop.action === 'Approved') {
        history_array = [...history_array, prop];
      }
      return true;
    });
    this.setState({ history_details: history_array });
  }
  render() {
    var inverts = true;
    const stories = this.state.history_details.map((prop, key) => {
      inverts ? (inverts = false) : (inverts = true);
      return {
        inverted: inverts,
        badgeColor: 'info',
        badgeIcon: BusinessCenter,
        title: 'Employment',
        titleColor: 'info',
        body: (
          <React.Fragment>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Company Name</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                <p>{prop.organization_name}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Department</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                <p>{prop.department}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Designation</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                <p>{prop.designation}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Joining Date</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                {dateFormat(new Date(prop.joining_date), 'dd/mm/yyyy ')}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Exit Date</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                <p>
                  {prop.end_date === null
                    ? '-'
                    : dateFormat(new Date(prop.end_date), 'dd/mm/yyyy')}
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Status</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                <p>{prop.action}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Approved by</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                <p>{prop.employer_name ? prop.employer_name : 'admin'}</p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Approved On</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={4}>
                <p>
                  {dateFormat(new Date(prop.added_on), 'dd/mm/yyyy HH:MM:ss')}
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={9} sm={5} md={4}>
                <b>Transaction Id</b>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <p>:</p>
              </GridItem>
              <GridItem xs={11} sm={6} md={6}>
                <p>{prop.transaction_id ? prop.transaction_id : '-'}</p>
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )
      };
    });
    return (
      <div>
        {this.state.history_details.length === 0 ? null : (
          <Heading title='Document Verification History' textAlign='center' />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card plain>
              <CardBody plain>
                {/* if no approved documents */}
                {this.state.history_details.length === 0 ? (
                  <div>
                    <DataNotFound
                      title={'No History Found'}
                      message={
                        'You will get this data if You Request your Employee to share their Employment Data with you'
                      }
                      button={true}
                      link={'/document'}
                      buttontext={'Check your Document'}
                    />
                  </div>
                ) : (
                  <Timeline stories={stories} />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default History;
