import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";

// file import
import SystemLogGrid from "./systemLogGrid.jsx";
import DataNotFound from "../DataNotFound";
import Errorpage from "../Pages/errorPage";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

// queries
import systemlogQuery from "../../graphql/systemlogQuery.js";

//Systemlog query
const SystemLogQuery = ({ username = localStorage.getItem("username") }) => (
  <Query query={systemlogQuery} variables={{ username }}>
    {({ loading, error, data }) => {
      // loading until getting response from the backend
      if (loading) {
        return <LoadingScreenPage />;
      }
      // if any error occur in backend it will redirect to error page
      if (error) {
        return (
          <div>
            <Errorpage />
          </div>
        );
      }

      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : data.getSystemLog.logs === null ? (
        //if no log exists redirect to data not found page
        <DataNotFound
          title={"No Activities Found"}
          button={false}
          link={"/document"}
          buttontext={"Approve Document"}
        />
      ) : (
        <div>
          {/* redirecting to system log page */}
          <SystemLogGrid key={data.getSystemLog} systomlog={data} />
        </div>
      );
    }}
  </Query>
);

class SystemLog extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <SystemLogQuery history={this.props.history} client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default SystemLog;
