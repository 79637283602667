import React, { Suspense } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MUIDataTable from "mui-datatables";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import { MuiThemeProvider } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// queries
import Viewquery from "../../graphql/viewQuery.js";
// styles
import urlStyle from "./urlStyle.jsx";
import Mytheme from "../Muitheme_Modified.jsx";
// file import
import LoadingScreenPage from "../LoadingScreenPage.jsx";
import Errorpage from "../Pages/errorPage";
const ViewUrl = React.lazy(() => import("./ViewUrl.jsx"));
const UrlList = React.lazy(() => import("./UrlList.jsx"));
// Date formater
var dateFormat = require("dateformat");

class EmploymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingalert: false,
      loadingFetchAlert: false,
      successalert: false,
      failalert: false,
      message: "",
      urlList_Data: [],
      //variable to handle hide/show of pages
      show_url_list: true,
      showViewpage: false,
      showAdd: false,
      viewDetails: "",
      viewDocumentDetails: "",
      comments: "",
      hashError: false
    };
  }

  // fuction to show URL list page while clicking back button
  handleBack_url = () => {
    this.setState({
      show_url_list: true,
      showViewpage: false,
      showAdd: false
    });
  };

  // fuction to show URL list Add document  page while clicking back button
  addurlclick = () => {
    this.setState({ showAdd: true, show_url_list: false });
  };

  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }
  componentDidMount() {
    var url_list = this.props.userdoc.Url;
    this.urlListTable(url_list);
  }
  urlListTable(url_list) {
    var urlList_Data = [];
    var self = this;
    const { classes, client } = this.props;
    //* Employer List view data *//
    //for grid view element should be in same order, column and the data pushed here should be same column count.
    Object.keys(url_list).forEach(function(key) {
      urlList_Data.push([
        url_list[key].document_count,
        !url_list[key].email ? "-" : url_list[key].email,
        dateFormat(
          new Date(
            new Date(url_list[key].exp_time).getTime() +
              new Date(url_list[key].exp_time).getTimezoneOffset() * 60000
          ),
          "dd/mm/yyyy HH:MM:ss"
        ),
        !url_list[key].created_at
          ? "-"
          : dateFormat(new Date(url_list[key].created_at), "dd/mm/yyyy"),
        // dateFormat(new Date(url_list[key].employee_id), 'dd/mm/yyyy'),
        <div className="actions-right">
          {/* Icon button to edit the employee exit process for current employee*/}
          <Tooltip
            id="tooltip-top"
            title="View"
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              aria-label="View"
              className={classes.profileiconbutton}
              onClick={async () => {
                self.setState({
                  loadingalert: true,
                  show_url_list: false
                });
                // view url query
                await client
                  .query({
                    query: Viewquery,
                    variables: {
                      username: url_list[key].created_by,
                      url: url_list[key].url_address
                    }
                  })
                  .then(response => {
                    self.setState({
                      loadingalert: false,
                      viewDetails: url_list[key],
                      viewDocumentDetails: response,
                      showViewpage: true,
                      show_url_list: false
                    });
                  })
                  .catch(err => {
                    self.setState({
                      show_url_list: true,
                      loadingalert: false,
                      loadingFetchAlert: true,
                      viewDocumentDetails: "error"
                    });
                  });
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>{" "}
          &nbsp; &nbsp;
        </div>
      ]);
    });

    self.setState({
      urlList_Data: urlList_Data
    });
  }
  render() {
    const { classes } = this.props;
    const { hashError } = this.state;
    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      selectableRows: false,
      page: this.state.page
    };
    // declaring the column name
    const columns = [
      {
        name: "Document Count",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Authority Email",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Validity",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Created On",
        options: {
          filter: true,
          sort: true
        }
      },

      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          download: false
        }
      }
    ];
    if (hashError) {
      return (
        <div>
          <Errorpage />
        </div>
      );
    } else {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} lg={12}>
            <Snackbar
              place="bc"
              color="danger"
              icon={AddAlert}
              message={this.state.failalert ? <b>{this.state.message}</b> : ""}
              open={this.state.failalert}
              closeNotification={() => this.setState({ failalert: false })}
              close
            />
            <Snackbar
              place="bc"
              color="info"
              icon={AddAlert}
              message={
                this.state.successalert ? <b>{this.state.message}</b> : ""
              }
              open={this.state.successalert}
              closeNotification={() => this.setState({ successalert: false })}
              close
            />

            {/* to display url grid */}
            {this.state.show_url_list ? (
              <div>
                <GridContainer
                  justify="flex-end"
                  style={{ paddingRight: "18px" }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="Back"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      className={classes.blockiconbutton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.addurlclick}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  </Tooltip>
                </GridContainer>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <LinkIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      Digital Resume URL List
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <MuiThemeProvider theme={Mytheme}>
                      <MUIDataTable
                        title={""}
                        data={this.state.urlList_Data}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </CardBody>
                </Card>
              </div>
            ) : null}
            {/* loading alert */}
            {this.state.loadingalert ? <LoadingScreenPage /> : null}
            {/* sweet alert for failure */}
            {this.state.loadingFetchAlert ? (
              <SweetAlert
                style={{
                  display: "block",
                  marginTop: "-100px",
                  color: "#000000"
                }}
                title="Failed to fetch."
                onConfirm={() => this.setState({ loadingFetchAlert: null })}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                showConfirm={true}
              >
                <h4>Please try Again.</h4>
              </SweetAlert>
            ) : null}
            {/*  navigate to view page */}
            {this.state.showViewpage ? (
              <Suspense fallback={<LoadingScreenPage />}>
                <ViewUrl
                  viewDetails={this.state.viewDetails}
                  viewDocumentDetails={this.state.viewDocumentDetails}
                  handleBack_url={this.handleBack_url}
                  comments={this.state.comments}
                />
              </Suspense>
            ) : null}
            {/* to generate url page */}
            {this.state.showAdd ? (
              <Suspense fallback={<LoadingScreenPage />}>
                <UrlList close={this.handleBack_url} showButton />
              </Suspense>
            ) : null}
          </GridItem>
        </GridContainer>
      );
    }
  }
}

export default withStyles(urlStyle)(EmploymentDetails);
