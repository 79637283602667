import React, { Component } from "react";
import { CountryDropdown } from "react-country-region-selector";
import axios from "axios";
import queryString from "querystring";
import { Redirect } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material ui components
import Icon from "@material-ui/core/Icon"; // To import Material ui icon
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
//materail ui icons
import ContactPhoneOutlined from "@material-ui/icons/ContactPhoneOutlined";
// custom components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import LoaderButton from "Custom-component/LoaderButton";

// file imports
import Aadhar from "./Aadhar.jsx";
import WarningAlert from "../WarningAlert.jsx";
import config from "../../config"; // *End point configuration file
import history from "../../history.js";

//queries
import EmployeeAadharQuery from "../../graphql/AadharQuery.js";
// styles
import AadharVerifyStyle from "./AadharVerifyStyle";
// for hasing passwords
var sha256 = require("js-sha256");

class AadharVerifyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
      showCountry: true,
      aadhar_availability: false,
      request_id: localStorage.getItem("username") + new Date().getTime(),
      client_code: "",
      api_key: "",
      salt: "",
      hash_value: "",
      employee_aadhaar_number: "",
      aadhar_number: "",
      employee_aadhaar_numberState: "",
      countryMessage: "",
      employee_phonenumber: "",
      employee_phonenumberState: "",
      showAadhar: false,
      hash: "",
      status: "",
      uuid: "",
      isLoading: false,
      message:
        "We experiencing difficulties with connectivity of the application. Please try again later",
      openwarning_aadhar: false,
      aadhar_url: ""
    };
  }
  componentDidMount() {
    if (localStorage.getItem("aadhar_verified") === "0") {
      var headers = {
        Authorization: localStorage.getItem("accessToken")
      };
      axios
        .get(config.get_secret, {
          headers: headers
        })
        .then(res => {
          if (res.data) {
            var parseData = JSON.parse(res.data.message);
            this.setState({
              client_code: parseData.clientCode,
              api_key: parseData.apiKey,
              salt: parseData.salt,
              aadhar_url: parseData.aadhaarUrlInit
            });

            var hashedvalue =
              this.state.client_code +
              "|" +
              this.state.request_id +
              "|" +
              this.state.api_key +
              "|" +
              this.state.salt;
            this.setState({
              hash_value: sha256(hashedvalue).toString()
            });
          } else {
            this.setState({ openwarning_aadhar: true });
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            localStorage.clear();
            history.push("/auth/login");
          } else {
            this.setState({ openwarning_aadhar: true });
          }
        });
    }
    const parsed = queryString.parse(window.location.search);
    if (parsed.status) {
      if (parsed.status === "SUCCESS") {
        this.setState({
          hash: parsed.hash,
          status: parsed.status,
          uuid: parsed.uuid,
          showAadhar: true
        });
      } else {
        this.setState({
          uuid: "",
          showAadhar: true
        });
      }
    }
  }
  //onchange function for choosing the user country
  selectCountry(val) {
    if (val === "India") {
      this.setState({ showCountry: false, countryMessage: "" });
    } else {
      this.setState({
        countryMessage:
          "Sorry, this feature is not available right now for your country. We are working to get this integrated with as much country as possible.Currently available only for India."
      });
    }
    this.setState({ country: val });
  }
  //identity number onchange function
  aadhar_change = async event => {
    const { client } = this.props;
    if (event.target.value.length <= 14) {
      var value = event.target.value
        .replace(/\D/g, "")
        .split(/(?:([\d]{4}))/g)
        .filter(s => s.length > 0)
        .join("-");
      var aadhar = event.target.value.replace(/[^0-9 ]/g, "");
      this.setState({
        openwarning_aadhar: false,
        employee_aadhaar_number: value,
        aadhar_number: aadhar
      });
      if (aadhar.length === 12) {
        this.setState({ employee_aadhaar_numberState: "success" });
        try {
          await client
            .query({
              query: EmployeeAadharQuery,
              variables: {
                aadhar_number: aadhar
              }
            })
            .then(response => {
              if (response.data.getEmployeeAadhaar) {
                if (response.data.getEmployeeAadhaar.aadhar_exists === 0) {
                  localStorage.setItem("AadharNumber", aadhar);
                  this.setState({ aadhar_availability: true });
                } else {
                  this.setState({
                    titleSweetAlert: "Aadhar Number already exists",
                    message:
                      "You already registered with this aadhar number. Please login with that credentials"
                  });
                  this.setState({ openwarning_aadhar: true });
                }
              } else {
                this.setState({ openwarning_aadhar: true });
              }
            })
            .catch(err => {
              this.setState({ openwarning_aadhar: true });
            });
        } catch (e) {
          this.setState({ openwarning_aadhar: true });
        }
      } else {
        this.setState({ employee_aadhaar_numberState: "error" });
      }
    }
  };
  //phone number onchange function
  verifyphonenumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value) && value.length === 10) {
      return true;
    } else {
      return false;
    }
  }
  // onchange function for all input fields
  handlephonenumber_aadhar(event, stateName, type) {
    switch (type) {
      case "length":
        if (this.verifyphonenumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  enable_verify_button() {
    if (
      this.state.aadhar_number.length === 12 &&
      this.state.aadhar_availability &&
      this.state.employee_phonenumberState === "success"
    ) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const { classes } = this.props;
    const { country } = this.state;
    return localStorage.getItem("userlogged") &&
      localStorage.getItem("aadhar_verified") === "0" ? (
      //* this is to protect user to access this url directly
      <div>
        {this.state.showAadhar ? (
          <Aadhar
            hash={this.state.hash}
            uuid={this.state.uuid}
            status={this.state.status}
          />
        ) : (
          <div>
            <Card>
              <CardBody>
                <h1 className={classes.mainheading}>Verify Your Identity</h1>
                <div className={classes.maincontainer}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={5}>
                      <h3 className={classes.subTitle}>
                        This is to verify your identity number to access the
                        application.
                      </h3>
                      <div className={classes.maincontainerColumn}>
                        <GridContainer justify="flex-start">
                          <GridItem xs={12} sm={12} md={10}>
                            <Card>
                              <h4 className={classes.mainheading}>
                                National Identity Number Verification
                              </h4>
                              <CardBody>
                                {/* Select country */}
                                {this.state.showCountry ? (
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={10}>
                                      <label>Choose your country</label>
                                    </GridItem>
                                    <br />
                                    <br />
                                    <GridItem xs={12} sm={12} md={10}>
                                      <CountryDropdown
                                        value={country}
                                        style={{
                                          fontSize: "15px",
                                          border: "none",
                                          background: "#fff",
                                          borderBottom: "2px solid #b6c5c7",
                                          width: "100%"
                                        }}
                                        onChange={val =>
                                          this.selectCountry(val)
                                        }
                                      />
                                    </GridItem>
                                    <br />
                                    <br />
                                    <GridItem xs={12} sm={12} md={10}>
                                      <h4 className={classes.notApplicableMsg}>
                                        {this.state.countryMessage}
                                      </h4>
                                    </GridItem>
                                  </GridContainer>
                                ) : (
                                  <div>
                                    {/* Enter user identity number */}
                                    <CustomInput
                                      success={
                                        this.state
                                          .employee_aadhaar_numberState ===
                                        "success"
                                      }
                                      error={
                                        this.state
                                          .employee_aadhaar_numberState ===
                                        "error"
                                      }
                                      id="employee_aadhaar_number"
                                      name="employee_aadhaar_number"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        placeholder: "Aadhar Number",
                                        value: this.state
                                          .employee_aadhaar_number,
                                        onChange: event =>
                                          this.aadhar_change(
                                            event,
                                            "length",
                                            3
                                          ),
                                        startAdornment: (
                                          <InputAdornment
                                            position="start"
                                            className={classes.inputAdornment}
                                          >
                                            <Icon
                                              className={
                                                classes.inputAdornmentIcon
                                              }
                                            >
                                              <i className="far fa-id-card" />
                                            </Icon>
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                    <br />
                                    <br />
                                    <CustomInput
                                      success={
                                        this.state.employee_phonenumberState ===
                                        "success"
                                      }
                                      error={
                                        this.state.employee_phonenumberState ===
                                        "error"
                                      }
                                      id="employee_phonenumber"
                                      name="employee_phonenumber"
                                      formControlProps={{
                                        // style: { width: "300px" },
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        placeholder: "Phone Number",
                                        value: this.state.employee_phonenumber,
                                        onChange: event =>
                                          this.handlephonenumber_aadhar(
                                            event,
                                            "employee_phonenumber",
                                            "length"
                                          ),
                                        startAdornment: (
                                          <InputAdornment
                                            position="start"
                                            className={classes.inputAdornment}
                                          >
                                            <ContactPhoneOutlined />
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </div>
                                )}
                                {!this.state.showCountry ? (
                                  <div className={classes.countrySubmitButton}>
                                    <form
                                      method="post"
                                      action={this.state.aadhar_url}
                                    >
                                      <input
                                        type="hidden"
                                        name="client_code"
                                        value={this.state.client_code}
                                      />
                                      <input
                                        type="hidden"
                                        name="api_key"
                                        value={this.state.api_key}
                                      />
                                      <input
                                        type="hidden"
                                        name="redirect_url"
                                        value={
                                          window.location.protocol +
                                          "//" +
                                          window.location.host +
                                          "/identityverify"
                                        }
                                      />
                                      <input
                                        type="hidden"
                                        name="mobile"
                                        value={this.state.employee_phonenumber}
                                      />
                                      <input
                                        type="hidden"
                                        name="otp_required"
                                        value="Y"
                                      />
                                      <input
                                        type="hidden"
                                        name="request_id"
                                        value={this.state.request_id}
                                      />
                                      <input
                                        type="hidden"
                                        name="stan"
                                        value={this.state.request_id}
                                      />
                                      <input
                                        type="hidden"
                                        name="hash"
                                        value={this.state.hash_value}
                                      />
                                      <LoaderButton
                                        type="submit"
                                        disabled={this.enable_verify_button()}
                                        onClick={() =>
                                          this.setState({ isLoading: true })
                                        }
                                        isLoading={this.state.isLoading}
                                        text="Verify"
                                        loadingText="Verifying.."
                                      />
                                    </form>
                                  </div>
                                ) : null}
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={10} md={7}>
                      <div className={classes.maincontainerColumn}>
                        <h3 className={classes.subTitle}>
                          Let us help you, complete your verification process
                        </h3>
                        <br />
                        <div
                          className="video"
                          style={{
                            position: "relative",
                            paddingBottom: "56.25%" /* 16:9 */,
                            paddingTop: 25,
                            height: 0,
                            boxShadow:
                              "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                          }}
                        >
                          <iframe
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%"
                            }}
                            title={"Youtube"}
                            src="https://www.youtube.com/embed/aU7l93rhfdI"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
            </Card>
            {this.state.openwarning_aadhar ? (
              <WarningAlert
                confirmBtnText="Ok"
                errorMessage={this.state.message}
                title="Failed"
                aadharclose={true}
              />
            ) : null}
          </div>
        )}
      </div>
    ) : localStorage.getItem("userlogged") &&
      localStorage.getItem("aadhar_verified") === "1" ? (
      <div>
        <Redirect to="/dashboard" />
      </div>
    ) : (
      <div>
        <Redirect to="/auth" />
      </div>
    );
  }
}
export default withStyles(AadharVerifyStyle)(AadharVerifyPage);
