import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Check from "@material-ui/icons/Check";
//Registration steps form
import Step1 from "./RegistrationSteps/Step1.jsx";
import Step2 from "./RegistrationSteps/Step2.jsx";
import Step3 from "./RegistrationSteps/Step3.jsx";
import Step4 from "./RegistrationSteps/Step4.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
//customly edited components
import Wizard from "Custom-component/Wizard/Wizard.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
//styles
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const style = {
  ...sweetAlertStyle,
  ...registerPageStyle
};

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: "",
      registration_agree_tandc: false,
      registration_agree_tandcState: "",
      registration_showtandc: false,
      registration_showinstructions: true,
      registration_showwizardform: false,
      tc: false,
      // data: this.props.employee,
      registration_timer_value: "60",
      registration_otpresend_buttonenable: true,
      employee: {},
      alert: null
    };
    this.handleToggle = this.handleToggle.bind(this, 1);
    this.showterms = this.showterms.bind(this);
    this.showwizard = this.showwizard.bind(this);
    this.checkValidate = this.checkValidate.bind(this);
  }
  //display the terms and condition page
  showterms() {
    this.setState({
      registration_showinstructions: false,
      registration_showtandc: true
    });
  }
  //display the registration wizard
  showwizard() {
    this.setState({
      registration_showtandc: false,
      registration_showwizardform: true
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ employee: nextProps.employee });
  }
  //checkbox validation
  checkValidate = () => {
    if (this.state.registration_agree_tandc === false) {
      this.setState({ registration_agree_tandcState: "error" });
    } else {
      this.setState({
        registration_showtandc: false,
        registration_showwizardform: true
      });
    }
  };
  //checkbox onchange
  handleToggle(registration_timer_value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(registration_timer_value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(registration_timer_value);
      this.setState({
        registration_agree_tandc: true,
        registration_agree_tandcState: "default"
      });
    } else {
      newChecked.splice(currentIndex, 1);
      this.setState({
        registration_agree_tandc: false
      });
    }
    this.setState({
      checked: newChecked
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.alert}
        {// Show the instructions to the employee
        this.state.registration_showinstructions ? (
          <div style={{ marginTop: "-61px" }}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <Card className={classes.cardSignup}>
                  <h2 className={classes.cardTitle}>Register</h2>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <InfoArea
                          title="Immutable blockchain"
                          description=" Immutability has the potential to transform the recruiting process into a quick, efficient, and cost-effective procedure, and bring more trust and integrity to the data businesses use and share every day."
                          icon={Timeline}
                          iconColor="rose"
                        />
                        <InfoArea
                          title="Share your digital resume"
                          description="Online resume distribution services make it easy to create resumes online and issue them to employers. Control which data you want to share with a different employer and the duration in which they can view the record and track the activity. "
                          icon={Code}
                          iconColor="info"
                        />
                        <InfoArea
                          title="Check who views your resume"
                          description="With digital resume comes the tracking of the records and you can revoke the resume if it is not used for its intended purposes"
                          icon={Group}
                          iconColor="info"
                        />
                      </GridItem>
                    </GridContainer>
                    <div className={classes.center}>
                      <Button
                        round
                        color="info"
                        id="register_ongo_button"
                        onClick={this.showterms}
                      >
                        Let's GO
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        ) : null}
        {//show the terms and conditions
        this.state.registration_showtandc ? (
          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <Card className={classes.cardSignup}>
                  <h2 className={classes.cardTitle}>Terms and Conditions</h2>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <p style={{ textAlign: "justify", display: "flex" }}>
                          These terms and conditions outline the rules and
                          regulations for the use of VerifyMate's a[[location,
                          located at https://verifymate.app.By accessing this
                          app we assume you accept these terms and conditions.
                          Do not continue to use VerifyMate if you do not agree
                          to take all of the terms and conditions stated on this
                          page. Our Terms and Conditions were created with the
                          help of the Terms And Conditions Generator and the
                          Privacy Policy Template. The following terminology
                          applies to these Terms and Conditions, Privacy
                          Statement and Disclaimer Notice and all Agreements:
                          "Client", "You" and "Your" refers to you, the person
                          logs on this app and compliant to the Company’s terms
                          and conditions. "The Company", "Ourselves", "We",
                          "Our" and "Us", refers to our Company. "Party",
                          "Parties", or "Us", refers to both the Client and
                          ourselves. All terms refer to the offer, acceptance
                          and consideration of payment necessary to undertake
                          the process of our assistance to the Client in the
                          most appropriate manner for the express purpose of
                          meeting the Client’s needs in respect of provision of
                          the Company’s stated services, in accordance with and
                          subject to, prevailing law of Netherlands. Any use of
                          the above terminology or other words in the singular,
                          plural, capitalization and/or he/she or they, are
                          taken as interchangeable and therefore as referring to
                          same.
                        </p>
                        <FormControlLabel
                          classes={{
                            root: classes.checkboxLabelControl,
                            label:
                              this.state.registration_agree_tandcState ===
                              "error"
                                ? classes.labelError
                                : classes.checkboxLabel
                          }}
                          control={
                            <Checkbox
                              id="register_agreeterms"
                              name="register_agreeterms"
                              tabIndex={-1}
                              onClick={this.handleToggle}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked
                              }}
                            />
                          }
                          label={
                            <span>I agree to the terms and conditions.</span>
                          }
                        />
                        <div className={classes.center}>
                          <Button
                            round
                            color="info"
                            id="register_getstarted_button"
                            name="register_getstarted_button"
                            onClick={this.checkValidate}
                          >
                            Get started
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        ) : null}
        {//show the registration wizard form page
        this.state.registration_showwizardform ? (
          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} lg={12}>
                <Wizard
                  color="info"
                  validate
                  steps={[
                    {
                      stepName: "Personal Details",
                      stepComponent: Step1,
                      stepId: "personal"
                    },
                    {
                      stepName: "Contact Details",
                      stepComponent: Step2,
                      stepId: "contact"
                    },
                    {
                      stepName: "Account Details",
                      stepComponent: Step3,
                      stepId: "account"
                    },
                    {
                      stepName: "OTP Verifcation",
                      stepComponent: Step4,
                      stepId: "otp"
                    }
                  ]}
                  title="Complete Your registration"
                  subtitle="This information will shown in your profile page."
                  finishButtonText="Verify OTP"
                  submitButtonText="Submit"
                  history={this.props.history}
                />
              </GridItem>
            </GridContainer>
          </div>
        ) : null}
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

//connect this component to redux store
const mapStateToProps = state => {
  return {
    employee: state.employee
  };
};

const withstyleRegister = withStyles(style)(RegisterPage);

export default connect(mapStateToProps)(withstyleRegister);
