import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MUIDataTable from "mui-datatables";
import { graphql } from "react-apollo";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { MuiThemeProvider } from "@material-ui/core/styles";
// @material-ui icons
import AddAlert from "@material-ui/icons/AddAlert";
import LinkIcon from "@material-ui/icons/Link";
import Check from "@material-ui/icons/CheckCircle";
import Block from "@material-ui/icons/Block";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// custom component
import LoaderButton from "../../Custom-component/LoaderButton";
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
// styles
import profilerequestStyle from "./profilerequestStyle.jsx";
import Mytheme from "../Muitheme_Modified.jsx";
//queries
import profileverificationMutation from "../../graphql/profileverificationMutation.js";
import viewprofilerequestQuery from "../../graphql/viewprofileQuery.js"; //Retrieve Emloyer request query
//file imports
import WarningAlert from "../WarningAlert.jsx";
import validation from "../Validation/ProfileValidation.jsx";
import TimeCalculation from "../Validation/TimeCalculation.jsx";
import Errorpage from "../Pages/errorPage";

// Date formater
var dateFormat = require("dateformat");
//for calculation expiration time
let date = require("date-and-time");

const proreq_item_height = 48;
const proreq_item_padding_top = 8;
const proreq_menuprops = {
  PaperProps: {
    style: {
      maxHeight: proreq_item_height * 4.5 + proreq_item_padding_top,
      width: 250
    }
  }
};
// for select in validity
var proreq_validityoption = [];
// for select in file
var proreq_fileoption = [];

class Profileverification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      isLoading: false,
      successAlert_profreq: false,
      failalert: false,
      message: "",
      profreqList_Data: [],
      //variable to handle hide/show of pages
      show_profilerequest_list: true,
      showapprovemodal: false,
      click_employerrname: "",
      click_id: "",
      validity_time: "",
      file_hash: "",
      document: [],
      validity_display: "",
      number: "",
      openWarning_profilerequest: false,
      hashError: false
    };
  }

  // back button function
  handleBack_profreq = () => {
    this.setState({
      show_profilerequest_list: true,
      showapprovemodal: false,
      number: "",
      numberState: "",
      document: [],
      validity_time: ""
    });
  };

  //function to select the duration
  changeduration_profreq = event => {
    this.setState({
      validity_display: event.target.value,
      validity_time: event.target.value.value
    });
  };

  // function to select the file listed in dropdown
  changedocument = event => {
    this.setState({ document: event.target.value });
    var filehash_arr;
    for (var i = 0; i < event.target.value.length; i++) {
      filehash_arr =
        i === 0
          ? event.target.value[i].value
          : filehash_arr + "," + event.target.value[i].value;
    }
    this.setState({ file_hash: filehash_arr });
  };
  //function to unselect the file
  handleDelete_profreq = data => () => {
    const chipData = [...this.state.document];
    const chipToDelete = chipData.indexOf(data);
    chipData.splice(chipToDelete, 1);
    this.setState({ document: chipData });
  };
  // Function to validate duration
  validateDuration_profreq = async () => {
    if (!this.isValidated_profreq()) {
      this.setState({ isLoading: true });

      const { number, validity_time } = this.state;
      if (validity_time && number) {
        var validateduration = TimeCalculation.Calculateminutes(
          validity_time,
          number,
          "number"
        );
        if (validateduration["number"]) {
          this.setState({
            DurationErrormessage: validateduration["number"],
            numberState: "error",
            isLoading: false
          });
        } else {
          this.handleSubmit_profreq("approve", false);
        }
      }
    }
  };
  // enable/disable email submisssion button
  isValidated_profreq() {
    if (
      this.state.file_hash &&
      this.state.numberState !== "error" &&
      this.state.number !== "" &&
      this.state.validity_time !== ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  // onchange funtion for input fields
  profreq_change(event, stateName, type) {
    switch (type) {
      case "number":
        var verifyDuration = validation.verifyDuration(
          event.target.value,
          stateName
        );
        if (!verifyDuration[stateName]) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            DurationErrormessage: verifyDuration[stateName]
          });
        }
        break;
      default:
        break;
    }

    this.setState({ [stateName]: event.target.value });
  }

  //reject alert
  rejectAlert() {
    this.setState({
      alert: (
        <SweetAlert
          id="profileverification_emailsent_failed"
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Profile Request Rejection"
          onConfirm={() => this.handleSubmit_profreq("reject", false)}
          onCancel={() => this.successhideAlert_profreq()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.info
          }
          cancelBtnCssClass={this.props.classes.button}
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          showCancel
        >
          Are you sure want to reject the request?
        </SweetAlert>
      )
    });
  }

  //successAlert_profreq message
  successAlert_profreq = type => {
    this.setState({
      alert: (
        <SweetAlert
          id="profileverification_emailsent_success"
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={
            type === "approve"
              ? "Request has been accepted"
              : "Request has been rejected"
          }
          onConfirm={() => this.successhideAlert_profreq}
          showConfirm={false}
        >
          <h4>Ask your employer to check</h4>
        </SweetAlert>
      )
    });
    setTimeout(this.successhideAlert_profreq, 3000);
  };
  //success alert time duration
  successhideAlert_profreq = () => {
    this.setState({
      alert: null,
      number: "",
      numberState: "",
      document: [],
      validity_time: ""
    });
  };

  //submit function for approval operation
  handleSubmit_profreq = async (type, validator) => {
    if (!validator) {
      this.setState({ openWarning_profilerequest: false });
      var exp;
      var time = new Date();
      time.setMinutes(time.getMinutes() + 330);
      var number = parseInt(this.state.number, 10);
      var validity_time = this.state.validity_time;
      if (validity_time === "Minutes") {
        exp = date.addMinutes(time, number);
        exp = exp
          .toJSON()
          .substring(0, 19)
          .replace("T", " ");
        this.setState({
          exp_time: exp
        });
      } else if (validity_time === "Hours") {
        exp = date.addHours(time, number);
        exp = exp
          .toJSON()
          .substring(0, 19)
          .replace("T", " ");
        this.setState({
          exp_time: exp
        });
      } else if (validity_time === "Days") {
        exp = date.addDays(time, number);
        exp = exp
          .toJSON()
          .substring(0, 19)
          .replace("T", " ");
        this.setState({
          exp_time: exp
        });
      }
      try {
        await this.props
          .mutate({
            variables: {
              username: localStorage.getItem("username"),
              employer_username: this.state.click_employerrname,
              id: this.state.click_id,
              response: type === "approve" ? "Accepted" : "Rejected",
              file_hashes: type === "approve" ? this.state.file_hash : "",
              expire_time: type === "approve" ? exp : null,
              ip: localStorage.getItem("ip"),
              browser: localStorage.getItem("browserName"),
              version: localStorage.getItem("browserVersion"),
              device_id: localStorage.getItem("deviceId")
            },
            refetchQueries: [
              {
                query: viewprofilerequestQuery,
                variables: { username: localStorage.getItem("username") }
              }
            ]
          })
          .then(response => {
            this.setState({
              showapprovemodal: false,
              showrejectModal: false,
              isLoading: false
            });
            this.successAlert_profreq(type);
          })
          .catch(err => {
            this.setState({
              isLoading: false,
              openWarning_profilerequest: true
            });
          });
      } catch (e) {
        this.setState({ isLoading: false, openWarning_profilerequest: true });
      }
    }
  };

  //onclick function for accept/reject icon
  handleclickIcon = (type, employerName, id) => {
    this.setState({ click_employerrname: employerName, click_id: id });
    if (type === "Accepted") {
      this.setState({ showapprovemodal: true });
    } else {
      this.rejectAlert();
    }
  };

  //handling error in components
  componentDidCatch(error, info) {
    //if (greater than the total available page length) error occur we redirecting it to starting page
    if (
      error.toString().includes("greater than the total available page length")
    ) {
      this.setState({ page: 0 });
    } else {
      this.setState({ hashError: true });
    }
  }
  componentDidMount() {
    const { userdoc, docdetails, validity } = this.props;
    if (proreq_validityoption.length === 0) {
      validity.time.map((prop, key) => {
        return proreq_validityoption.push({
          value: prop.type,
          label: prop.type
        });
      });

      if (docdetails.Employment) {
        docdetails.Employment.map((prop, key) => {
          if (prop.verified_status === "Approved") {
            return proreq_fileoption.push({
              value: prop.file_hash,
              label: prop.document_name
            });
          }
          return null;
        });
      }
    }
    this.generateTable(userdoc);
  }

  generateTable = userdoc => {
    const { classes } = this.props;
    var profreqList_Data = [];
    var self = this;
    var profilerequest_list = userdoc.profileRequest;
    Object.keys(profilerequest_list).forEach(function(key) {
      profreqList_Data.push([
        profilerequest_list[key].employer_username,

        !profilerequest_list[key].document_count
          ? "-"
          : profilerequest_list[key].document_count,
        profilerequest_list[key].response,
        profilerequest_list[key].expire_time === null
          ? "-"
          : dateFormat(
              new Date(
                new Date(profilerequest_list[key].expire_time).getTime() +
                  new Date(
                    profilerequest_list[key].expire_time
                  ).getTimezoneOffset() *
                    60000
              ),
              "dd/mm/yyyy HH:MM:ss"
            ),
        <div>
          {profilerequest_list[key].response === "Accepted" ? (
            <div style={{ color: "green" }}>Accepted</div>
          ) : (
            <div>
              {profilerequest_list[key].response === "Rejected" ? (
                <div style={{ color: "red" }}>Rejected</div>
              ) : (
                <div style={{ color: "#ff9800" }}>Pending</div>
              )}
            </div>
          )}
        </div>,
        <div className="actions-right">
          <IconButton
            id="profileverification_view_iconbutton"
            className={classes.profileiconbuttonApprove}
            disabled={profilerequest_list[key].response !== "Pending"}
            onClick={() =>
              self.handleclickIcon(
                "Accepted",
                profilerequest_list[key].employer_username,
                profilerequest_list[key].id
              )
            }
          >
            <Tooltip id="tooltip-top" title="Approved" placement="bottom">
              <Check
                style={
                  profilerequest_list[key].response === "Pending"
                    ? { color: "green" }
                    : { color: "#b9b8b5" }
                }
              />
            </Tooltip>
          </IconButton>{" "}
          &nbsp; &nbsp;
          <IconButton
            id="profileverification_view_iconbutton"
            className={classes.profileiconbuttonReject}
            disabled={profilerequest_list[key].response !== "Pending"}
            onClick={() =>
              self.handleclickIcon(
                "Rejected",
                profilerequest_list[key].employer_username,
                profilerequest_list[key].id
              )
            }
          >
            <Tooltip id="tooltip-top" title="Reject" placement="bottom">
              <Block
                style={
                  profilerequest_list[key].response === "Pending"
                    ? { color: "red" }
                    : { color: "#b9b8b5" }
                }
              />
            </Tooltip>
          </IconButton>
        </div>
      ]);
    });

    self.setState({
      profreqList_Data: profreqList_Data
    });
  };

  componentDidUpdate(props, state) {
    if (state.profreqList_Data.length) {
      var updatedProfileRequest = props.userdoc.profileRequest;
      var changed = false;
      Object.keys(updatedProfileRequest).forEach(function(key) {
        if (
          updatedProfileRequest[key].response !== state.profreqList_Data[key][2]
        ) {
          changed = true;
        }
      });
      if (changed) {
        this.generateTable(props.userdoc);
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { hashError } = this.state;
    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      fixedHeader: true,
      selectableRows: false,
      page: this.state.page
    };
    // declaring the colum name for table
    const columns = [
      {
        name: "Requested By",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Document Count",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Document Status",
        options: {
          filter: true,
          sort: true,
          display: false
        }
      },
      {
        name: "Expiration",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Status",
        options: {
          filter: false,
          sort: true,
          download: false
        }
      },

      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          download: false
        }
      }
    ];
    if (hashError) {
      return (
        <div>
          <Errorpage />
        </div>
      );
    } else {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} lg={12}>
            <Snackbar
              place="bc"
              color="danger"
              icon={AddAlert}
              message={this.state.failalert ? <b>{this.state.message}</b> : ""}
              open={this.state.failalert}
              closeNotification={() => this.setState({ failalert: false })}
              close
            />
            <Snackbar
              place="bc"
              color="info"
              icon={AddAlert}
              message={
                this.state.successAlert_profreq ? (
                  <b>{this.state.message}</b>
                ) : (
                  ""
                )
              }
              open={this.state.successAlert_profreq}
              closeNotification={() =>
                this.setState({ successAlert_profreq: false })
              }
              close
            />
            {this.state.alert}
            {this.state.show_profilerequest_list ? (
              <div>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <LinkIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      Profile Request List
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <MuiThemeProvider theme={Mytheme}>
                      <MUIDataTable
                        title={""}
                        data={this.state.profreqList_Data}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </CardBody>
                </Card>
              </div>
            ) : null}
            {this.state.showapprovemodal ? (
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal
                }}
                style={{ minWidth: "25%" }}
                open={true}
                keepMounted
                onClose={() => this.handleBack_profreq()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
              >
                <DialogTitle
                  style={{ fontSize: "1.3em", textAlign: "center" }}
                  id="notice-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => this.handleBack_profreq()}
                  >
                    <Close className={classes.modalClose} />
                  </Button>

                  <h4 className={classes.modalTitle}>
                    Profile Request Approval
                  </h4>
                </DialogTitle>
                <DialogContent
                  id="notice-modal-slide-description"
                  className={classes.modalBody}
                >
                  <form className={classes.form}>
                    <MuiThemeProvider theme={Mytheme}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          FormLabelClasses={{
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }}
                        >
                          Select your file*
                        </InputLabel>
                        <Select
                          multiple
                          value={this.state.document}
                          onChange={this.changedocument}
                          input={
                            <Input
                              classes={{
                                underline: classes.underline
                              }}
                              id="select-multiple-chip"
                            />
                          }
                          renderValue={selected => (
                            <div className={classes.chips}>
                              {selected.map(selectedValue => (
                                <Chip
                                  key={selectedValue.value} // to avoid duplication
                                  label={selectedValue.label} // value displayed in chip
                                  className={classes.chip}
                                  onDelete={this.handleDelete_profreq(
                                    selectedValue
                                  )}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={proreq_menuprops}
                        >
                          <MenuItem value="" disabled={true}>
                            Employment
                          </MenuItem>
                          {proreq_fileoption.map(document => (
                            <MenuItem
                              key={document.value} // to avoid duplication
                              value={document} //pass key,value pair to display label
                            >
                              <Checkbox
                                checked={
                                  this.state.document.indexOf(document) > -1
                                } // check selected document and exists document are same to enable check box
                              />

                              {document.label}
                            </MenuItem> // values displayed in drop down
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <CustomInput
                          success={this.state.numberState === "success"}
                          error={this.state.numberState === "error"}
                          labelText="Duration*"
                          id="number"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          helpText={
                            this.state.numberState === "error"
                              ? this.state.DurationErrormessage
                              : null
                          }
                          inputProps={{
                            onChange: event =>
                              this.profreq_change(event, "number", "number")
                          }}
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          FormLabelClasses={{
                            root: classes.cssLabel,
                            focused: classes.cssFocused
                          }}
                        >
                          Validity*
                        </InputLabel>
                        <Select
                          MenuProps={proreq_menuprops}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.validity_display}
                          onChange={this.changeduration_profreq}
                          input={
                            <Input
                              classes={{
                                underline: classes.underline
                              }}
                              id="select-single-chip"
                            />
                          }
                        >
                          {proreq_validityoption.map(proreq_validityoption => (
                            <MenuItem
                              key={proreq_validityoption.value}
                              value={proreq_validityoption}
                            >
                              <div className={classes.labelalign}>
                                {proreq_validityoption.label}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MuiThemeProvider>
                  </form>
                </DialogContent>
                <DialogActions>
                  <GridContainer justify="center">
                    <div className={classes.center}>
                      <LoaderButton
                        style={{ marginTop: "22px" }}
                        id="generateurl_button"
                        color="info"
                        disabled={this.isValidated_profreq()}
                        onClick={this.validateDuration_profreq}
                        isLoading={this.state.isLoading}
                        text="Submit"
                        loadingText="Submitting..."
                      />
                    </div>
                  </GridContainer>
                </DialogActions>
              </Dialog>
            ) : null}
            {this.state.openWarning_profilerequest ? (
              <WarningAlert
                title="Failed!!"
                confirmBtnText="Try Again"
                errorMessage="Response Not Successful"
              />
            ) : null}
          </GridItem>
        </GridContainer>
      );
    }
  }
}
const profileverificationstyle = withStyles(profilerequestStyle)(
  Profileverification
);

export default graphql(profileverificationMutation)(profileverificationstyle);
