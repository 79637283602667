import gql from 'graphql-tag'

const Emailquery = gql`
  query EmailQuery(
    $username: String!
    $employer_mail: String!
    $url: String!
    $exp_time: String!
  ) {
    sendEmail(
      username: $username
      employer_mail: $employer_mail
      url: $url
      exp_time: $exp_time
    ) {
      message
      __typename
    }
    __typename
  }
`
export default Emailquery
