import gql from "graphql-tag";

const Viewquery = gql`
  query fileQuery($username: String!, $url: String!) {
    filesLinkedWithUrl(username: $username, url: $url) {
      file_hashes
      exp_time
      document_count
    }
    showEmployment(url: $url) {
      Employment {
        username
        document_name
        file_id
        file_hash
        document_extension
        document_upload_type
        employee_id
        employer_name
        employer_mail_id
        organization_name
        joining_date
        end_date
        experience
        designation
        department
        uploaded_by
        uploaded_at
        modified_at
        verified_status
        __typename
      }
    }

    employersWhoSawMyDocuments(username: $username, url: $url) {
      UnverifiedEmployer {
        name
        company_name
        device_id
        browser
        ip
        created_at
        __typename
      }
      __typename
    }
  }
`;
export default Viewquery;
