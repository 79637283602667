import React from "react";
import { Glyphicon } from "react-bootstrap";
// core components
import Button from "components/CustomButtons/Button.jsx";
// styles
import "./LoaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  icon,
  className = "",
  disabled = false,
  ...props
}) => (
  <Button
    color="info"
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading ? <Glyphicon glyph="refresh" className="spinning" /> : null}
    {!isLoading ? (icon ? text : text) : loadingText}
  </Button>
);
