const baseurl = "https://devapi.verifymate.app";
const aadharurl = "https://prod.aadhaarbridge.com/kyc-sdk/_init";
const s3Platform = "";
const profilePlatform = "";
const dev_env = "";
const prod_env = "/prod";
const release_env = "/release";
const platform = "/employee";

const dev = {
  aadhar: {
    compare_profile: baseurl + dev_env + platform + "/compareProfiledetails"
  },
  siteKey: "6LeCepsUAAAAAPJoAWAG97Nwosc_KukG-8syoZoj",
  userProfile: {
    update_profile: baseurl + dev_env + platform + "/updateAttributes",
    update_email: baseurl + dev_env + platform + "/updateEmail"
  },

  get_secret:
    baseurl + "/secretses/getSecret/ap-south-1/VmateAadharCredentials",
  s3: {
    s3Upload: baseurl + dev_env + platform + "/uploadFile",
    s3Replace: baseurl + dev_env + platform + "/replaceFile",
    s3Delete: baseurl + dev_env + platform + "/deleteFile",
    s3Download: baseurl + dev_env + platform + "/retrieveFile"
  },
  profileImage: {
    ProfileUpload: baseurl + dev_env + platform + "/uploadProfileImage",
    ProfileRetrieve: baseurl + dev_env + platform + "/retrieveProfileImage",
    ProfileRemove: baseurl + dev_env + platform + "/deleteProfileImage",
    ProfileChange: baseurl + dev_env + platform + "/replaceProfileImage"
  },
  graphqlendpoint: {
    URL: baseurl + dev_env + platform + "/graphql"
  },
  cognito: {
    signin_url: baseurl + dev_env + platform + "/signIn",
    signup_url: baseurl + dev_env + platform + "/signUp",
    confirmsignup_url: baseurl + dev_env + platform + "/confirmSignUp",
    resendcode_url: baseurl + dev_env + platform + "/resendConfirmationCode",
    changepassword_url: baseurl + dev_env + platform + "/changePassword",
    signout_url: baseurl + dev_env + platform + "/signOut",
    forgetpassword_url: baseurl + dev_env + platform + "/forgotPassword",
    confirmForgotPassword_url:
      baseurl + dev_env + platform + "/confirmForgotPassword",
    listUser: baseurl + dev_env + platform + "/usernameAvailability",
    changeEmail_url: baseurl + dev_env + platform + "/updateEmail",
    confirmEmail_url: baseurl + dev_env + platform + "/verifyEmail"
  }
};
const release = {
  aadhar: {
    aadhar_url: aadharurl,
    compare_profile: baseurl + release_env + platform + "/compareProfiledetails"
  },
  siteKey: "6LeCepsUAAAAAPJoAWAG97Nwosc_KukG-8syoZoj",
  userProfile: {
    update_profile:
      baseurl + release_env + platform + "/employeeUpdateAttributes",
    update_email: baseurl + release_env + platform + "/employeeUpdateEmail"
  },
  get_secret: baseurl + release_env + "/getsecretvalues",
  s3: {
    s3Upload: baseurl + release_env + s3Platform + "/uploadFiletoS3",
    s3Replace: baseurl + release_env + s3Platform + "/replaceFileInS3",
    s3Delete: baseurl + release_env + s3Platform + "/deleteFileFromS3",
    s3Download: baseurl + release_env + s3Platform + "/retrieveFileFromS3"
  },
  profileImage: {
    ProfileUpload: baseurl + release_env + profilePlatform + "/upload",
    ProfileRetrieve: baseurl + release_env + profilePlatform + "/retrieve",
    ProfileRemove: baseurl + release_env + profilePlatform + "/delete",
    ProfileChange: baseurl + release_env + profilePlatform + "/replace"
  },
  graphqlendpoint: {
    URL: baseurl + release_env + platform + "/graphql"
  },
  cognito: {
    signin_url: baseurl + release_env + platform + "/signIn",
    signup_url: baseurl + release_env + platform + "/signUp",
    confirmsignup_url: baseurl + release_env + platform + "/confirmSignUp",
    resendcode_url:
      baseurl + release_env + platform + "/resendConfirmationCode",
    changepassword_url: baseurl + release_env + platform + "/changePassword",
    signout_url: baseurl + release_env + platform + "/signOut",
    forgetpassword_url: baseurl + release_env + platform + "/forgotPassword",
    confirmForgotPassword_url:
      baseurl + release_env + platform + "/confirmForgotPassword",
    listUser: baseurl + release_env + platform + "/employeeListUsers",
    changeEmail_url: baseurl + release_env + platform + "/employeeUpdateEmail",
    confirmEmail_url: baseurl + release_env + platform + "/employeeVerifyEmail"
  }
};

const prod = {
  aadhar: {
    aadhar_url: aadharurl,
    compare_profile: baseurl + prod_env + platform + "/compareProfiledetails"
  },
  siteKey: "6LeCepsUAAAAAPJoAWAG97Nwosc_KukG-8syoZoj",
  userProfile: {
    update_profile: baseurl + prod_env + platform + "/employeeUpdateAttributes",
    update_email: baseurl + prod_env + platform + "/employeeUpdateEmail"
  },
  get_secret: baseurl + prod_env + "/getsecretvalues",
  s3: {
    s3Upload: baseurl + prod_env + s3Platform + "/uploadFiletoS3",
    s3Replace: baseurl + prod_env + s3Platform + "/replaceFileInS3",
    s3Delete: baseurl + prod_env + s3Platform + "/deleteFileFromS3",
    s3Download: baseurl + prod_env + s3Platform + "/retrieveFileFromS3"
  },
  profileImage: {
    ProfileUpload: baseurl + prod_env + profilePlatform + "/upload",
    ProfileRetrieve: baseurl + prod_env + profilePlatform + "/retrieve",
    ProfileRemove: baseurl + prod_env + profilePlatform + "/delete",
    ProfileChange: baseurl + prod_env + profilePlatform + "/replace"
  },
  graphqlendpoint: {
    URL: baseurl + prod_env + platform + "/graphql"
  },
  cognito: {
    signin_url: baseurl + prod_env + platform + "/signIn",
    signup_url: baseurl + prod_env + platform + "/signUp",
    confirmsignup_url: baseurl + prod_env + platform + "/confirmSignUp",
    resendcode_url: baseurl + prod_env + platform + "/resendConfirmationCode",
    changepassword_url: baseurl + prod_env + platform + "/changePassword",
    signout_url: baseurl + prod_env + platform + "/signOut",
    forgetpassword_url: baseurl + prod_env + platform + "/forgotPassword",
    confirmForgotPassword_url:
      baseurl + prod_env + platform + "/confirmForgotPassword",
    listUser: baseurl + prod_env + platform + "/employeeListUsers",
    changeEmail_url: baseurl + prod_env + platform + "/employeeUpdateEmail",
    confirmEmail_url: baseurl + prod_env + platform + "/employeeVerifyEmail"
  }
};
const configuration =
  process.env.REACT_APP_HOST_ENV === "prod"
    ? prod
    : process.env.REACT_APP_HOST_ENV === "release"
    ? release
    : dev;
export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configuration
};
