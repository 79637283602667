import gql from 'graphql-tag';

const viewprofilerequestQuery = gql`
  query profileValidationQuery($username: String!) {
    viewProfileRequest(username: $username) {
      profileRequest {
        id
        employer_username
        employee_username
        employer_name
        employer_email
        response
        file_hashes
        document_count
        requested_at
        expire_time
      }
      __typename
    }
    __typename
  }
`;

export default viewprofilerequestQuery;
