import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
// @material-ui core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
// components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Badge from "../../components/Badge/Badge.jsx";
// queries and mutation
import countQuery from "../../graphql/documentQuery.js";
import urlList from "../../graphql/urllistQuery.js";
// images
import upload from "assets/img/upload.png";
import verified from "assets/img/verified.png";
import digital from "assets/img/digital-resume.png";
// styles
import newuserStyle from "./newuserpagestyle";
// file import
import history from "../../history";
import ImageCard from "../../Custom-component/ImageCard/Imagecard.jsx";

//style for tab
const styles = {
  tabs: {
    background: "transparent",
    display: "none"
  }
};

class Newuserpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      // component status
      uploadStatus: "Incomplete",
      verifyStatus: "Incomplete",
      shareStatus: "Incomplete",
      // color
      verifyColorStatus: "gray",
      uploadColorStatus: "gray",
      shareColorStatus: "gray",
      // anable/disable status
      disableUploadButton: false,
      disableVerifyButton: true,
      disableShareButton: true
    };
  }
  // function to scroll the page
  handleChange(value) {
    this.setState({
      index: value
    });
  }
  // function to change the index of page
  handleChangeIndex = index => {
    this.setState({
      index
    });
  };

  componentDidMount() {
    // query to retrieve the uploaded,rejected and pending documents count
    try {
      this.props.client
        .query({
          query: countQuery,
          variables: {
            username: localStorage.getItem("username")
          }
        })
        .then(response => {
          if (response.data) {
            // query to retrieve the url
            this.props.client
              .query({
                query: urlList,
                variables: {
                  created_by: localStorage.getItem("username")
                }
              })
              .then(urlresponse => {
                if (urlresponse.data) {
                  if (
                    response.data.getTotalCount &&
                    response.data.getTotalCount.count > 0
                  ) {
                    this.setState({
                      uploadColorStatus: "success",
                      uploadStatus: "complete",
                      disableUploadButton: true
                    });
                  }
                  if (
                    response.data.getApprovedCount &&
                    response.data.getApprovedCount.count > 0
                  ) {
                    this.setState({
                      verifyStatus: "complete",
                      verifyColorStatus: "success",
                      disableVerifyButton: true,
                      disableShareButton: false
                    });
                  }
                  if (urlresponse.data.getMulUrl.Url) {
                    this.setState({
                      shareStatus: "complete",
                      shareColorStatus: "success",
                      disableShareButton: true
                    });
                  }
                }
              })
              .catch(err => {});
          }
        })
        .catch(err => {});
    } catch (e) {}
  }

  render() {
    const { classes } = this.props;
    const { index } = this.state;
    const onboard_card_list = [];
    for (var i = 0; i < 3; i++) {
      onboard_card_list.push(
        <GridItem xs={12} sm={12} md={4} key={i}>
          <ImageCard
            image={i === 0 ? upload : i === 1 ? verified : digital}
            heading={
              i === 0
                ? "Upload document"
                : i === 1
                ? "Verify Document"
                : "Digital Resume"
            }
            discription={
              i === 0
                ? "Upload Your Employment Document"
                : i === 1
                ? "Verify Your Document by sending it to your Authority"
                : "Share your Approved Document to other Authority"
            }
            badge_content={
              <div>
                <Badge
                  color={
                    i === 0
                      ? this.state.uploadColorStatus
                      : i === 1
                      ? this.state.verifyColorStatus
                      : this.state.shareColorStatus
                  }
                >
                  {i === 0
                    ? this.state.uploadStatus
                    : i === 1
                    ? this.state.verifyStatus
                    : this.state.shareStatus}
                </Badge>
              </div>
            }
            footer_content={
              <div>
                {i === 0 ? (
                  <Button
                    color="info"
                    disabled={this.state.disableUploadButton}
                    onClick={() => history.push("/document")}
                  >
                    Upload
                  </Button>
                ) : i === 1 ? (
                  <Button
                    onClick={() => history.push("/url")}
                    disabled={this.state.disableVerifyButton}
                    color="info"
                  >
                    Verify
                  </Button>
                ) : (
                  <Button
                    onClick={() => history.push("/url")}
                    disabled={this.state.disableShareButton}
                    color="info"
                  >
                    Share
                  </Button>
                )}
              </div>
            }
            forward_arrow={
              i === 0 || i === 1 ? (
                <IconButton
                  aria-label="next"
                  className={classes.iconbutton}
                  onClick={() => this.handleChange(index + 1)}
                >
                  <Icon>arrow_forward_ios</Icon>
                </IconButton>
              ) : null
            }
            backward_arrow={
              i === 1 || i === 2 ? (
                <IconButton
                  aria-label="previous"
                  className={classes.iconbutton}
                  onClick={() => this.handleChange(index - 1)}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              ) : null
            }
          />
        </GridItem>
      );
    }
    return (
      <div className={classes.container} style={{ marginTop: "3%" }}>
        <Hidden smDown>
          <GridContainer justify="center">
            <h3>WELCOME !!</h3>
          </GridContainer>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={11}>
              <GridContainer justify="center">
                {onboard_card_list}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Hidden>
        <Hidden mdUp>
          <GridContainer justify="center">
            <h3>WELCOME !!</h3>
          </GridContainer>
          <br />
          <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
            {onboard_card_list}
          </SwipeableViews>
          <Tabs
            value={index}
            variant="fullWidth"
            onChange={this.handleChange}
            style={styles.tabs}
          >
            <Tab label="tab n°1" />
            <Tab label="tab n°2" />
            <Tab label="tab n°3" />
          </Tabs>
        </Hidden>
      </div>
    );
  }
}

Newuserpage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(newuserStyle)(Newuserpage);
