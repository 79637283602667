import gql from 'graphql-tag';
const EmployeeAadharQuery = gql`
  query EmployeeQuery($aadhar_number: String!) {
    getEmployeeAadhaar(aadhar_number: $aadhar_number) {
      message
      aadhar_exists
      __typename
    }
    __typename
  }
`;
export default EmployeeAadharQuery;
