import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import { Redirect } from "react-router-dom";

// file imports
import Employmentdetails from "./EmploymentDetails.jsx";
import Errorpage from "../Pages/errorPage";
import LoadingScreenPage from "../LoadingScreenPage.jsx";

//queries
import EmployeeQuery from "../../graphql/employeequery.js";

// retrieve all documents uploaded by employee - Document query
const Mydocumentquery = ({
  username = localStorage.getItem("username"),
  client,
  docstatus,
  history
}) => (
  <Query query={EmployeeQuery} variables={{ username }}>
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingScreenPage />;
      }
      if (error) {
        return (
          <div>
            <Errorpage />
          </div>
        );
      }
      return localStorage.getItem("aadhar_verified") === "0" ? ( // redirect to new user page when the user is new
        <Redirect to="/identityverify" />
      ) : (
        // redirect to documentlist page
        <div>
          <Employmentdetails
            key={data.getMulEmployment.username}
            user={data.getMulEmployment}
            docStatus={docstatus}
            client={client}
            history={history}
          />
        </div>
      );
    }}
  </Query>
);

class MyDocument extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <Mydocumentquery
            history={this.props.history}
            client={client}
            docstatus={this.props.location.state}
          />
        )}
      </ApolloConsumer>
    );
  }
}

export default MyDocument;
