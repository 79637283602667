import { combineReducers } from 'redux';
import employeeReducer from './employee-reducers';
import userReducer from './User-reducers';

const allReducers = combineReducers({
  employee: employeeReducer,
  user: userReducer
});

export default allReducers;
